.main {
    background-color: #f7f7f5;
    width: 100%;
    height: 100%;
    padding: 2%;
  }
.avatarDiv{
    display: flex;
    width: 100%;
    background-color: white;
    margin: 1% 0;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.avatarContainer {
    max-width: 960px;
    margin: 30px auto;
    padding: 20px;
}
.avatar{
    margin-right: 3%;
}
.container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-bottom: 2%;
}
.stepperButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: xx-large;
}
.formDiv {
  background-color: white;
  padding-bottom: 2%;
  height: 80%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.row1 {
  display: flex;
  padding: 2% 0;
  flex-direction: row;
  justify-content: space-evenly;
}

.row2 {
  width: calc(51vw - 80px);
  display: flex;
  padding: 2% 0;
  flex-direction: row;
  justify-content: space-evenly;
}

.lastFormElement {
  font-size: 25px;
  font-weight: 400;
  color: #707070;
  margin-top: 5%;
  margin-bottom: 5%;
}

.checked {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dot {
  height: 25px;
  text-align: center;
  background-color: red;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 60%;
}
.review{
  display: flex;
  padding: 2% 0;
  flex-direction: row;
  letter-spacing: 0px;
color: #707070;
opacity: 1;
font-size:16px
  /* justify-content: space-around; */
}
.ul{
  list-style: none;
  display: table;
}

.li {
  display: table-row;
  line-height: 28px;
}

.b {
  display: table-cell;
  padding-right: 3em;
}





@media (min-width:320px) and (max-width:375px) {
  .label {
      font-size: xx-small;
  }
  .lastFormElement {
      padding-left: 20%;
  }
}

@media (min-width:376px) and (max-width:768px) {
  .label {
      font-size: x-small;
  }
  .lastFormElement {
      padding-left: 10%;
  }
}