.main {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
}

.title {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #43425d;
}

.tabsDiv {
  margin-left: 0.5%;
  width: 100%;
  margin-top: 2%;
}

@media screen and (max-width: 550px) {
  .main {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
  }
  .tabsDiv {
    margin-left: 0.5%;
    width: 100%;
    margin-top: 2%;
  }
}
