left {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left_main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.signUp_text {
  font-size: xx-large;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 5%;
}

.signUp_desc {
  font-size: large;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #64686D;
  font-weight: lighter;
  padding-bottom: 3%;
}

.row1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.row2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.termsCondition {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.buttonDiv {
  display: flex;
  padding: 2% 0;
  justify-content: center;
  align-items: center;
}

.signInDiv {
  padding: 3% 0;
}

.button {
  width: 420px;
  height: 50px;
}

.logo {
  display: flex;
  padding: 5%;
  align-items: center;
  justify-content: center;
}

.parent {
  width: 100vw;
  height: 100vh;
}

.image1 {
  position: relative;
  padding-left: 5%;
  top: 0;
  left: 0;
  height: 100%;
  width: 102.5%;
  margin: 0;
  object-fit: fill;
  overflow: hidden;
}

.image2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: fill;
  overflow-x: hidden;
}

.links {
  position: absolute;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  padding-left: 1em;
  padding-right: 1em;
}

.innerLinks {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.modalContainer {
  position: fixed;
  top: 15%;
  left: 5%;
  width: 90%;
  height: 100%;
  background-color: white;
  padding: 2%;
  display: flex;
  flex-direction: column;
  position: relative;
    flex: 1 1 auto;
    padding: 1rem;

}

.logoDiv {
  display: flex;
  padding: 5%;
  margin-top: 5%;
  justify-content: center;
}

.div1text {
  text-align: center;
  padding: 5%;
  font-size: x-large;
  color: "black";
}

.description {
  font-size: smaller;
  text-align: center;
  padding-bottom: 5%;
  color: #64686D;
}

.textField {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-bottom: 10%;
  margin-top: 5%;
}
.resSend{
  display: flex;
  text-align: center;
font-size: "Regular 14px/22px SF Pro Text";
letter-spacing: 0px;
color: #43425D;
opacity: 1;
}
.sendDisable{
  font-size: 13px;
    color: #bfb4b4;
    font-weight: bold;
    margin-left: 3%;
    cursor: pointer;
}
.send{
  width: 100%;
  margin-right: 21%;
  font-size: 13px;
  color: red;
  font-weight: bold;
  margin-left: 3%;
  cursor: pointer;
  text-align: right
}
.ex{
  font-size: 13px;
    color: #43425D;
    margin-left: 21%;
}
.mBottom{
  margin-bottom:43px !important
}

@media (min-width: 426px) and (max-width: 767px) {
  .parent {
      visibility: hidden;
  }
  .left_main {
      padding-left: 80%;
  }
  .signUp_text {
      font-size: larger;
  }
  .signUp_desc {
      font-size: medium;
  }
  .termsCondition {
      justify-content: flex-start;
  }
  .buttonDiv {
      width: 280px;
  }
  .signInDiv {
      padding-right: 25%;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .parent {
      visibility: hidden;
  }
  .left_main {
      padding-left: 80%;
  }
  .signUp_text {
      font-size: medium;
  }
  .signUp_desc {
      font-size: small;
  }
  .buttonDiv {
      width: 283.45px;
      height: 50px;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .parent {
      visibility: hidden;
  }
  .left_main {
      padding-left: 80%;
  }
  .signUp_text {
      font-size: medium;
  }
  .signUp_desc {
      font-size: small;
  }
  .buttonDiv {
      width: 283.45px;
      height: 50px;
  }
  .signInDiv {
      padding-right: 25%;
  }
}
.verify{
  color: #F2134F;
  text-transform:none;
  font-weight: "bold";
  cursor: "pointer",
}
.verified{
  color:green;
  text-transform:none;
  font-weight: "bold";
  cursor: "pointer",
}
.verifyDisabled{
  pointer-events: none;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .image1 {
      width: 105%;
  }
  .buttonDiv {
      width: 283.45px;
      height: 50px;
  }
  .termsCondition {
      display: flex;
      justify-content: flex-start;
  }
  .linksDiv1 {
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
  }
  .linksDiv2 {
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
  }
  .instaImage {
      width: 60px;
      height: 60px;
  }
  .links {
      padding-left: 0.6em;
  }
  .innerLinks {
      padding-left: 0em;
      justify-content: space-evenly;
  }
}


@media (min-width: 1024px) and (max-width: 1439px) {
  .image1 {
      width: 104%;
  }
  .buttonDiv {
      width: 283.45px;
      height: 50px;
  }
  .termsCondition {
      display: flex;
      justify-content: flex-start;
  }
  .linksDiv1 {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
  }
  .linksDiv2 {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
  }
  .instaImage {
      width: 66px;
      height: 66px;
  }
  .links {
      padding-left: 0.3em;
  }
  .innerLinks {
      padding-left: 0.5em;
      justify-content: space-evenly;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 2559px) {
  .image1 {
      width: 102.5%;
  }
  .buttonDiv {
      width: 283.45px;
      height: 50px;
  }
  .termsCondition {
      display: flex;
      justify-content: flex-start;
  }
  .linksDiv1 {
      width: 58px;
      height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
  }
  .linksDiv2 {
      width: 58px;
      height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
      padding-top: 5%;
  }
  .instaImage {
      width: 72px;
      height: 72px;
  }
  .links {
      padding-left: 0.6em;
  }
  .innerLinks {
      padding-left: 0.5em;
      justify-content: space-evenly;
  }
}