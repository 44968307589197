.main {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.title {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font: Source Sans Pro;
  font-size: 1.3rem;
  font-weight: 500;
}

.tableDiv {
  background-color: white;
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  width: 100%;
  /* height: 70%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.searchBar {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.searchText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropDownDiv {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.searchBarDiv {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.table {
  height: 80%;
}

.searchAndDrop {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  width: 40%;
}

.buttonAndFilter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.paginationDiv {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 550px) {
  .main {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }
  .tableDiv {
    margin-left: 0%;
    margin-top: 1.5%;
    padding: 0 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .dropDownDiv {
    margin-top: 2%;
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .searchBarDiv {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .searchBar {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .searchAndDrop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    margin-bottom: 2%;
  }

  .buttonAndFilter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
