.title {
    margin-top: 3%;
    margin-left: 5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    font: Regular Source Sans Pro;
    color: #43425D;
}

.grid {
    display: flex;
    justify-content: flex-start;
    align-content: space-between;
    align-items: center;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    margin-left: 3%;
}