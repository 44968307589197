.main {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
  flex-direction: column;
}

.title {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  padding-bottom: 2%;
}

.row1 {
  display: flex;
  padding: 2% 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.row2 {
  display: flex;
  padding: 2% 0;
  justify-content: space-around;
}

.row3 {
  display: flex;
  padding: 2% 0;
  flex-direction: row;
  justify-content: flex-end;
}

.checkers {
  display: flex;
  padding: 2% 0;
  flex-direction: row;
}
.date {
  margin-left: 2%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-evenly;
  margin-top: 13px;
  margin-bottom: 31px;
}

.label {
  color: #43425d;
  font-size: medium;
  text-align: center;
  white-space: nowrap;
}
.label1 {
  line-height: 2.5em;
  margin-right: 36px;
  color: #43425d;
  font-size: medium;
  text-align: center;
  white-space: nowrap;
}
.seaarchSelect {
  color: #616161;
  min-width: 51vw;
  background: white;
  padding-top: 10px;
  border-color: #d7dae2;
  border-style: solid;
  border-width: 2px;
  padding-left: 5px;
  border-radius: 4px;
  padding-bottom: 10px;
}
.seaarchSelect svg {
  color: #9e9e9e;
}
.seaarchSelect div {
  color: black;
  /* min-width: 51vw; */
  background: white;
  border: none !important;
}
.seaarchSelect span {
  background-color: white;
}

@media screen and (max-width: 550px) {
  .date {
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-evenly;
    margin-top: 13px;
    margin-bottom: 31px;
  }
}
