.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  box-shadow: 0px 2px 6px #0000000a;
  border-radius: 4px;
}

.detailsDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-size: x-large;
  font-weight: bold;
  color: #4d4f5c;
  margin-left: 2%;
  font: Semibold 22px/28px Source Sans Pro;
  margin-top: 0px;
}
