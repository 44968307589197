.main {
  width: 100%;
  height: 100%;
  background-color: #f7f7f5;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.title {
  font-size: xx-large;
}

.formDiv {
  margin: 2% 0;
  padding: 1%;
  background-color: white;
  width: 70%;
  display: flex;
  flex-direction: column;
}

.innerDiv {
  margin: 2% 0;
  background-color: #f0f2f8;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 2% 0;
  padding: 2%;
  justify-content: space-around;
  margin-left: 7%;
}

.row1 {
  display: flex;
  margin: 2% 0;
  padding: 1%;
  justify-content: space-around;
}

.row2 {
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 5%;
  margin: 2% 0;
}

.buttonDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 15%;
}

.buttons {
  margin: 2%;
  padding: 3%;
}

@media screen and (max-width: 550px) {
  .main {
    width: 100vw;
    height: 100%;
    background-color: #f7f7f5;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .title {
    font-size: medium;
  }
  .formDiv {
    margin: 0%;
    padding: 2%;
    background-color: white;
    width: 100%;
  }

  .innerDiv {
    margin: 0%;
    background-color: #f0f2f8;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0%;
    padding: 2%;
    justify-content: space-around;
    margin-left: 0%;
    align-items: center;
  }
}
