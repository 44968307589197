.main {
    width: 100%;
    height: 100%;
    background-color: #f7f7f5;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.title {
    font-size: x-large;
}

.formDiv {
    margin: 2% 0;
    padding: 1%;
    background-color: white;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.row1 {
    display: flex;
    margin: 2% 0;
    padding: 1%;
    justify-content: space-around;
}

.row2 {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    margin: 3% 0;
}

.buttonDiv {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: -1%;
}

.buttons {
    margin: 2%;
    padding: 3%;
}