.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: baseline; */
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
}

.dropdownMain {
  display: flex;
  margin-right: 2%;
  padding-bottom: 2%;
  align-items: flex-end;
  margin-top: 2%;
  justify-content: flex-end;
  width: 20%;
}

.title {
  margin-left: 3%;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
  margin-top: 2%;
}

.header2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 5%;
  width: 98%;
  margin-left: 3%;
  justify-content: first baseline;
}

.subTitle {
  font-size: 1.25rem;
  color: #43425d;
  font-weight: bold;
  border-bottom: 0.1rem solid #43425d;
  padding-bottom: 1%;
  margin-right: 2%;
}

.dropdown2 {
  margin-left: 34%;
}

.date {
  margin-left: 2%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

.label {
  color: #43425d;
  font-size: medium;
  text-align: center;
}

.date_picker {
  border-color: #f5f6fa;
  border-radius: 4px;
  width: 31%;
  margin-left: 2%;
  margin-right: 2%;
}

.cards {
  width: 70%;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 5%;
  margin-left: 3%;
}

.card {
  max-width: 100%;
  min-width: 25%;
}

.final {
  margin-left: 85%;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 550px) {
  .main {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .dropdownMain {
    display: flex;
    margin-right: 3%;
    padding-bottom: 2%;
    align-items: flex-start;
    margin-top: 2%;
    justify-content: flex-end;
    width: 95%;
  }
  .title {
    margin-left: 3%;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: x-large;
    margin-top: 2%;
  }

  .header2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3%;
    width: 100%;
    margin-left: 3%;
    justify-content: first baseline;
  }

  .subTitle {
    font-size: 1.25rem;
    color: #43425d;
    font-weight: bold;
    border-bottom: 0.1rem solid #43425d;
    padding-bottom: 1%;
    margin-right: 2%;
  }

  .dropdown2 {
    margin-left: 1%;
    margin-top: 5%;
  }

  .date {
    margin-left: 0;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-between;
  }

  .label {
    color: #43425d;
    font-size: smaller;
    text-align: center;
  }

  .date_picker {
    border-color: #f5f6fa;
    border-radius: 4px;
    width: 28%;
    margin-left: 0;
    margin-right: 0;
  }

  .cards {
    width: 100%;
    margin-top: 5%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-left: 0;
  }

  .card {
    max-width: 45%;
    min-width: 25%;
    margin-top: 3%;
  }

  .final {
    margin-left: 70%;
    margin-top: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
  }
}
