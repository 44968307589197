.main {
    background-color: #F9F9F9;
    width: 100%;
    height: 100%;
    padding: 2%;
}

.title {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: xx-large;
}

.tableDiv {
    background-color: white;
    margin-left: 2%;
    margin-top: 1.5%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.searchBar {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.searchText {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropDownDiv {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.searchBarDiv {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.searchAndDrop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.buttonAndFilter {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .searchAndDrop {
        width: 40%;
    }
    .dropDownDiv {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .searchAndDrop {
        width: 50%;
    }
    .dropDownDiv {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .searchAndDrop {
        width: 70%;
    }
    .dropDownDiv {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .searchAndDrop {
        width: 70%;
    }
    .dropDownDiv {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .searchAndDrop {
        width: 70%;
    }
    .dropDownDiv {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .searchAndDrop {
        width: 70%;
    }
    .dropDownDiv {
        width: 80%;
    }
}