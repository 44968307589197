.main {
    background-color: #F4FFE4;
    width: 100%;
    height: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-bottom: 2rem;
}

.title {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
    font-weight: 500;
    font-weight: 600;
    color: #43425d;
}

.privacy{
    margin-top: 2rem;
}

.policycard{
    margin: 5px;
    background-color: #ffffff;
    border: 2px solid black;
    border-radius: 7px;
    padding: 1rem 2rem;
    box-shadow: 0 6px 6px 3px black;
}