.main {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  margin: 1%;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.modalBody {
  padding: 0px !important;
}
.cancel {
  color: white;
  position: absolute;
  top: -9px;
  right: -9px;
  height: 7%;
  width: 10%;
  cursor: pointer;
  z-index: 90000;
}
.carouselControl {
  position: absolute;
  top: 50%; /* pushes the icon in the middle of the height */
  z-index: 5;
  display: inline-block;
  cursor: pointer;
  background: transparent;
  box-shadow: none;
  border: none;
}
.disabled {
  cursor: not-allowed !important;
}

.left {
  left: 0px;
}

.right {
  right: 0px;
}

.title {
  margin-bottom: 2%;
  display: flex;
  justify-content: flex-start;
  font-size: x-large;
  white-space: nowrap;
}
.arrow {
  color: white;
  font-size: 36px !important;
  cursor: pointer;
}

.tableDiv {
  margin-left: 1%;
  margin-top: 1.5%;
  margin: 2%;
  padding: 0 1%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.header2 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 60%;
  align-items: center;
}

@media screen and (max-width: 550px) {
  .main {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    margin: 0%;
  }
  .tableDiv {
    margin-left: 0;
    margin-top: 1.5%;
    margin: 0%;
    padding: 0 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .header2 {
    display: flex;
    margin-top: 2%;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
  }
}
