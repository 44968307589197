@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap);

body {
  font-family: "Poppins", sans-serif !important;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; 
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family: "Poppins", sans-serif !important;
}
.MuiPaginationItem-outlinedPrimary.Mui-selected {
  color: white;
  border: 1px solid rgba(63, 81, 181, 0.5);
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}
.CreateJob_main__3--4F {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
  flex-direction: column;
}

.CreateJob_title__3coUl {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
}

.CreateJob_container__1SmJv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  padding-bottom: 2%;
}

.CreateJob_row1__1Fc0D {
  display: flex;
  padding: 2% 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.CreateJob_row2__1gIcc {
  display: flex;
  padding: 2% 0;
  justify-content: space-around;
}

.CreateJob_row3__2DxIB {
  display: flex;
  padding: 2% 0;
  flex-direction: row;
  justify-content: flex-end;
}

.CreateJob_checkers__nQCsU {
  display: flex;
  padding: 2% 0;
  flex-direction: row;
}
.CreateJob_date__2SvTt {
  margin-left: 2%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-evenly;
  margin-top: 13px;
  margin-bottom: 31px;
}

.CreateJob_label__3m2fy {
  color: #43425d;
  font-size: medium;
  text-align: center;
  white-space: nowrap;
}
.CreateJob_label1__3JS00 {
  line-height: 2.5em;
  margin-right: 36px;
  color: #43425d;
  font-size: medium;
  text-align: center;
  white-space: nowrap;
}
.CreateJob_seaarchSelect__nAu_9 {
  color: #616161;
  min-width: 51vw;
  background: white;
  padding-top: 10px;
  border-color: #d7dae2;
  border-style: solid;
  border-width: 2px;
  padding-left: 5px;
  border-radius: 4px;
  padding-bottom: 10px;
}
.CreateJob_seaarchSelect__nAu_9 svg {
  color: #9e9e9e;
}
.CreateJob_seaarchSelect__nAu_9 div {
  color: black;
  /* min-width: 51vw; */
  background: white;
  border: none !important;
}
.CreateJob_seaarchSelect__nAu_9 span {
  background-color: white;
}

@media screen and (max-width: 550px) {
  .CreateJob_date__2SvTt {
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-evenly;
    margin-top: 13px;
    margin-bottom: 31px;
  }
}

.JobDetails_main__38ClG {
    background-color: #f7f7f5;
    width: 100%;
    height: 100%;
    padding: 2%;
}

.JobDetails_width__3BFGf {
    width: 0px;
}

.JobDetails_title__3JsM4 {
    margin-left: 2%;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425D;
}

.JobDetails_details__3OqtQ {
    margin-left: 2%;
    display: flex;
    font-size: 13px;
    justify-content: flex-start;
    align-items: center;
    color: #43425D;
    font-weight: normal;
}

.JobDetails_tableDiv__1UOqb {
    background-color: white;
    margin-top: 2%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.JobDetails_searchBar__3wIqD {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.JobDetails_searchText__35YKH {
    display: flex;
    justify-content: center;
    align-items: center;
}

.JobDetails_dropDownDiv__iP4wt {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.JobDetails_searchBarDiv__1dcv3 {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.JobDetails_searchAndDrop__30tOY {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.JobDetails_buttonContainer__1VtK9 {
    width: 26%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .JobDetails_searchAndDrop__30tOY {
        width: 40%;
    }

    .JobDetails_dropDownDiv__iP4wt {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .JobDetails_searchAndDrop__30tOY {
        width: 50%;
    }

    .JobDetails_dropDownDiv__iP4wt {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .JobDetails_searchAndDrop__30tOY {
        width: 70%;
    }

    .JobDetails_dropDownDiv__iP4wt {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .JobDetails_searchAndDrop__30tOY {
        width: 70%;
    }

    .JobDetails_dropDownDiv__iP4wt {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .JobDetails_searchAndDrop__30tOY {
        width: 70%;
    }

    .JobDetails_dropDownDiv__iP4wt {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .JobDetails_searchAndDrop__30tOY {
        width: 70%;
    }

    .JobDetails_dropDownDiv__iP4wt {
        width: 80%;
    }
}
.JobDetailsInitQc_main__3l3bw {
    background-color: #f7f7f5;
    width: 100%;
    height: 100%;
    padding: 2%;
}

.JobDetailsInitQc_width__2Ktq2 {
    width: 0px;
}

.JobDetailsInitQc_title__5CUSC {
    margin-left: 2%;
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425D;
}

.JobDetailsInitQc_details__379wb {
    margin-left: 2%;
    display: flex;
    font-size: 13px;
    justify-content: flex-start;
    align-items: center;
    color: #43425D;
    font-weight: normal;
}

.JobDetailsInitQc_tableDiv__vCZXo {
    background-color: white;
    margin-top: 2%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.JobDetailsInitQc_searchBar__1R4Ao {
    display: flex;
    flex-direction: row;
    /* align-items: flex-end; */
}

.JobDetailsInitQc_searchText__15e92 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.JobDetailsInitQc_dropDownDiv__1Lk02 {
    width: 25%;
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    align-items: flex-end;
}

.JobDetailsInitQc_searchBarDiv__1ED7f {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.JobDetailsInitQc_searchAndDrop__MOBQc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.JobDetailsInitQc_buttonContainer__G3954 {
    width: 26%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .JobDetailsInitQc_searchAndDrop__MOBQc {
        width: 40%;
    }

    .JobDetailsInitQc_dropDownDiv__1Lk02 {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .JobDetailsInitQc_searchAndDrop__MOBQc {
        width: 50%;
    }

    .JobDetailsInitQc_dropDownDiv__1Lk02 {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .JobDetailsInitQc_searchAndDrop__MOBQc {
        width: 70%;
    }

    .JobDetailsInitQc_dropDownDiv__1Lk02 {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .JobDetailsInitQc_searchAndDrop__MOBQc {
        width: 70%;
    }

    .JobDetailsInitQc_dropDownDiv__1Lk02 {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .JobDetailsInitQc_searchAndDrop__MOBQc {
        width: 70%;
    }

    .JobDetailsInitQc_dropDownDiv__1Lk02 {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .JobDetailsInitQc_searchAndDrop__MOBQc {
        width: 70%;
    }

    .JobDetailsInitQc_dropDownDiv__1Lk02 {
        width: 80%;
    }
}
.HisotoryAnyTime_main__2bBT5 {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
}

.HisotoryAnyTime_title__2ZMsl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #43425d;
  font-size: 1.3rem;
  font-weight: 500;
  /* margin-left: 2%; */
}

.HisotoryAnyTime_tableDiv__1EiPW {
  background-color: white;
  margin-top: 2%;
  /* padding: 0 1.5%;
  margin-left: 2%; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.HisotoryAnyTime_searchBar__BQVCB {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.HisotoryAnyTime_dropDownDiv__zuQVD {
  width: 25%;
  display: flex;
  flex-direction: row;
  margin-left: -7%;
  align-items: flex-end;
}
.HisotoryAnyTime_M_L__d7y8R {
  margin-left: -1%;
}

.HisotoryAnyTime_searchBarDiv__3Ecbp {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.HisotoryAnyTime_searchBarDiv1__oRLRd {
  padding: 1% 0;
  /* display: flex; */
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.HisotoryAnyTime_searchAndDrop__3NQyG {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.HisotoryAnyTime_buttonAndFilter__3cV8e {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.HisotoryAnyTime_paginationDiv__1Ptrb {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.HisotoryAnyTime_seaarchSelect__1XClP {
  color: #616161;
  font-size: 13px;
  min-width: 16.5vw;
  background: white;
  padding-top: 0px;
  border-color: #d7dae2;
  border-style: solid;
  border-width: 2px;
  padding-left: 0px;
  border-radius: 4px;
  padding-bottom: 0px;
}
.HisotoryAnyTime_seaarchSelect__1XClP svg {
  color: #9e9e9e;
}
.HisotoryAnyTime_seaarchSelect__1XClP div {
  color: black;
  /* min-width: 51vw; */
  background: white;
  border: none !important;
  min-height: 16px;
}
.HisotoryAnyTime_seaarchSelect__1XClP span {
  background-color: white;
}

@media screen and (max-width: 550px) {
  .HisotoryAnyTime_tableDiv__1EiPW {
    background-color: white;
    margin-top: 2%;
    padding: 0 0;
    margin-left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .HisotoryAnyTime_dropDownDiv__zuQVD {
    width: 25%;
    display: flex;
    flex-direction: row;
    margin-left: -7%;
    align-items: flex-end;
  }
  .HisotoryAnyTime_M_L__d7y8R {
    margin-left: -1%;
  }

  .HisotoryAnyTime_searchBarDiv__3Ecbp {
    padding: 0 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
  .HisotoryAnyTime_searchBarDiv1__oRLRd {
    padding: 1% 0;
    /* display: flex; */
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .HisotoryAnyTime_searchAndDrop__3NQyG {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
  }

  .HisotoryAnyTime_buttonAndFilter__3cV8e {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .HisotoryAnyTime_seaarchSelect__1XClP {
    color: #616161;
    font-size: 13px;
    min-width: 40vw;
    background: white;
    padding-top: 0px;
    border-color: #d7dae2;
    border-style: solid;
    border-width: 2px;
    padding-left: 0px;
    border-radius: 4px;
    padding-bottom: 0px;
  }
}

.Login_left__G3B98 {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login_left_main__1fPgo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Login_button__32FuS {
  width: 320px;
  height: 50px;
}

.Login_forgotPassword__Foyek {
  color: #f2134f;
}

.Login_logo__2AB6S {
  display: flex;
  padding: 5%;
  align-items: center;
}

.Login_login_text__1BUtd {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 5%;
  font-size: xx-large;
}

.Login_login_desc__2oayQ {
  text-align: center;
  padding-bottom: 5%;
  font-size: medium;
  color: #64686d;
}

.Login_parent__3x5v8 {
  width: 100vw;
  height: 100vh;
}

.Login_image1__f2MAB {
  position: relative;
  padding-left: 5%;
  top: 0;
  left: 0;
  height: 100%;
  width: 102.5%;
  margin: 0;
  object-fit: fill;
  overflow: hidden;
}

.Login_image2__aPAvs {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: fill;
  overflow-x: hidden;
}

.Login_links__2H6wC {
  position: absolute;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  padding-left: 1em;
  padding-right: 1em;
}

.Login_innerLinks__3ikYS {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.Login_textDanger__3_i7I {
  color: red;
  float: left;
  width: 91%;
}
.Login_showBlock__3ML0g {
  display: block;
}
.Login_hideBlock__2woMT {
  display: none;
}
@media only screen and (min-width: 2560px) {
  .Login_image1__f2MAB {
    width: 102.5%;
    height: 101%;
  }
  .Login_image2__aPAvs {
    width: 101%;
    height: 101%;
  }
  .Login_links__2H6wC {
    position: absolute;
    height: 100vh;
    width: 12%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    top: 0;
    left: 0;
    padding-left: 3em;
    padding-right: 3em;
  }
  .Login_innerLinks__3ikYS {
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 320px) {
  .Login_left_main__1fPgo {
    padding-left: 80%;
  }
  .Login_button__32FuS {
    width: 240px;
    height: 50px;
  }
  .Login_parent__3x5v8 {
    visibility: hidden;
  }
  .Login_login_text__1BUtd {
    width: 320px;
    font-size: medium;
  }
  .Login_signUpText__1a1P2 {
    font-size: small;
  }
  .Login_forgotPassword__Foyek {
    font-size: small;
    font-weight: 500;
  }
  .Login_login_desc__2oayQ {
    font-size: small;
  }
  .Login_usernamePassowrdLabel__rg9BN {
    font-size: smaller;
  }
  .Login_checkBoxLabel__1q-Ch {
    font-size: smaller;
    font-weight: 500;
  }
  .Login_checkBox__cC185 {
    display: flex;
  }
  .Login_forgotPwdDiv__1x_eB {
    display: flex;
    width: 100%;
    padding: 0 5%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .Login_button__32FuS {
    width: 320px;
    height: 50px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  .Login_button__32FuS {
    width: 288px;
    height: 50px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 767px) {
  .Login_left_main__1fPgo {
    padding-left: 75%;
  }
  .Login_parent__3x5v8 {
    visibility: hidden;
  }
  .Login_forgotPassword__Foyek {
    font-size: small;
    font-weight: 500;
  }
  .Login_checkBoxLabel__1q-Ch {
    font-size: smaller;
    font-weight: 500;
  }
  .Login_forgotPwdDiv__1x_eB {
    display: flex;
    width: 100%;
    padding: 0 5%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .Login_login_text__1BUtd {
    width: 320px;
    font-size: large;
  }
  .Login_signUpText__1a1P2 {
    font-size: small;
  }
  .Login_forgotPassword__Foyek {
    font-size: medium;
  }
  .Login_login_desc__2oayQ {
    font-size: small;
  }
  .Login_usernamePassowrdLabel__rg9BN {
    font-size: smaller;
  }
  .Login_checkBoxLabel__1q-Ch {
    font-size: small;
  }
  .Login_forgotPwdDiv__1x_eB {
    display: flex;
    width: 100%;
    padding: 0 5%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .Login_checkBox__cC185 {
    display: flex;
  }
}
avatarName {
  color: #646777 !important;
  margin-left: 10px;
  display: block;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  margin-top: auto;
  margin-bottom: auto;
}
.Login_avatar__1LXpY svg {
  margin: auto;
  height: 18px;
  width: 18px;
  fill: #b1c3c8;
}
.Login_topBarBack__3I9mM {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;
}
.Login_topBarMenuWrap__PgCkh {
  right: 0px;
  z-index: 101;
  position: absolute;
  width: 100%;
  /* min-width: 210px; */
}
.Login_topBarMenu__11mz7 {
  left: 0px !important;
  background: white;
  width: 100%;
  border-radius: 0;
  border: none;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;
}
.Login_hr__1pI6- {
  margin: auto;
  margin-left: 6px;
  border-right: 2px solid rgb(239 239 239);
  display: block;
  /* width: 100%; */
  height: 20px;
  margin-right: -2px;
}
.Login_tobBarLinik__3_hax {
  color: #707070;
  display: flex;
  padding: 9px 4px;
  transition: all 0.3s;
  height: 32px;
  width: 100%;
  position: relative;
  cursor: pointer;
  /* text-decoration: none; */
}
.Login_tobBarLinik__3_hax:hover {
  background-color: #fafbfe;
  color: #707070;
}
.Login_tobBarLinik__3_hax:before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  background: #4ce1b6;
  opacity: 0;
  transition: all 0.3s;
}
.Login_noHover__3IB1M:before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 0px !important;
  background: #fafbfe !important;
  opacity: 0;
  transition: all 0.3s;
}
button:focus {
  outline: none !important;
}
.Login_tobBarLinik__3_hax:hover:before {
  opacity: 1;
}
.Login_tobBarLinkTitle__1Xk7n {
  color: #646777;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
}
.Login_tobBarLinkIcon__Kx68p {
  color: #dddddd;
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;
}
.Login_topBarCollapse__1byx5 {
  position: relative;
}
.Login_topBarCollapseLng__fD-pT {
  min-width: 70px;
  display: block;
}
.Login_topBarCollapseLng__fD-pT {
  color: #646777;
}
.Login_tobBarButton__28oyv {
  font-size: 18px;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;
  padding: 0 4px;
  width: 100%;
}
.Login_tobBarButton__28oyv {
  padding: 0 4px;
  width: 100%;
}
.Login_topBarLngBtnTitle__1rJLv:not(:last-child) {
  margin-right: 5px;
}
.Login_topBarLngBtnTitle__1rJLv:not(:last-child) {
  margin-right: 5px;
  color: #646777;
  display: flex;
  font-size: 11px;
  align-items: center;
  margin: auto 0;
}
.Login_topBarLngBtnTitle__1rJLv img {
  margin-right: 4px;
  height: 11px;
  width: 16px;
}
.Login_tobBarButton__28oyv svg {
  margin: auto;
  height: 18px;
  width: 18px;
  fill: #b1c3c8;
  margin-left: 8px;
}

.Login_tobBarContentCollapse__13tKZ {
  background: white;
  right: 0px;
}
.Login_tobBarContentCollapse__13tKZ {
  /* width: 270px; */
  position: absolute;
  bottom: 20px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);
  z-index: 101;
}
.Login_tobBarClsContentLgn__3lsY- {
  width: 72px;
  padding: 10px 0;
  bottom: 0;
  /* width: 100%; */
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.Login_topBarLngBtn__3w6K9 {
  text-align: left;
  padding: 4px 15px;
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  transition: 0.3s;
  font-family: sans-serif;
  font-size: 100%;
}

@media only screen and (min-width: 768px) {
  .Login_image1__f2MAB {
    width: 105.5%;
    height: 100vh;
  }
  .Login_image2__aPAvs {
    height: 100vh;
  }
  .Login_forgotPwdDiv__1x_eB {
    display: flex;
    width: 100%;
    padding: 0 5%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (min-width: 1024px) {
  .Login_image1__f2MAB {
    width: 104%;
    height: 100vh;
  }
  .Login_image2__aPAvs {
    height: 100vh;
  }
  .Login_forgotPwdDiv__1x_eB {
    display: flex;
    width: 100%;
    padding: 0 5%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (min-width: 1440px) {
  .Login_image1__f2MAB {
    width: 103%;
    height: 100vh;
  }
  .Login_image2__aPAvs {
    height: 100vh;
  }
  .Login_forgotPwdDiv__1x_eB {
    display: flex;
    width: 100%;
    padding: 0 5%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .Login_linksDiv1__2JKWd {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
  }
  .Login_linksDiv2__1dT9Q {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
  }
  .Login_instaImage__1l27f {
    width: 60px;
    height: 60px;
  }
  .Login_links__2H6wC {
    padding-left: 0.6em;
  }
  .Login_innerLinks__3ikYS {
    padding-left: 0em;
    justify-content: space-evenly;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .Login_linksDiv1__2JKWd {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
  }
  .Login_linksDiv2__1dT9Q {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
  }
  .Login_instaImage__1l27f {
    width: 66px;
    height: 66px;
  }
  .Login_links__2H6wC {
    padding-left: 0.3em;
  }
  .Login_innerLinks__3ikYS {
    padding-left: 0.5em;
    justify-content: space-evenly;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 2559px) {
  .Login_linksDiv1__2JKWd {
    width: 58px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
  }
  .Login_linksDiv2__1dT9Q {
    width: 58px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
    padding-top: 5%;
  }
  .Login_instaImage__1l27f {
    width: 72px;
    height: 72px;
  }
  .Login_links__2H6wC {
    padding-left: 0.6em;
  }
  .Login_innerLinks__3ikYS {
    padding-left: 0.5em;
    justify-content: space-evenly;
  }
}

.SignUp_left__2n3yU {
  width: 100%;
  height: 100vh;
  display: flex;
  /* justify-content: center;
  align-items: center; */
}

.SignUp_left_main__OsScx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
}
.SignUp_tc__2ILWp {
  text-align: center;
  padding: 5%;
  font-size: small;
  color: "black";
  justify-content: center;
  text-align: revert;
}
.SignUp_signUp_text__3j5EQ {
  font-size: xx-large;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 5%;
}

.SignUp_signUp_desc__yEHaU {
  font-size: large;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #64686d;
  font-weight: lighter;
  padding-bottom: 3%;
}

.SignUp_row1__2djVf {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.SignUp_row2__2yzjb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.SignUp_termsCondition__FaJ4U {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.SignUp_buttonDiv__36uAm {
  display: flex;
  padding: 2% 0;
  justify-content: center;
  align-items: center;
}

.SignUp_signInDiv__2ynMu {
  padding: 3% 0;
}

.SignUp_button__2-ijF {
  width: 420px;
  height: 50px;
}

.SignUp_logo__253Rw {
  display: flex;
  padding: 5%;
  align-items: center;
  justify-content: center;
}

.SignUp_parent__Ss3HE {
  width: 100vw;
  height: 100vh;
}

.SignUp_image1__2Yx5o {
  position: relative;
  padding-left: 5%;
  top: 0;
  left: 0;
  height: 100%;
  width: 102.5%;
  margin: 0;
  object-fit: fill;
  overflow: hidden;
}
.SignUp_image1Flip__3XPab {
  position: relative;
  padding-left: 5%;
  top: 0;
  left: 0;
  height: 100%;
  width: 102.5%;
  margin: 0;
  object-fit: fill;
  overflow: hidden;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.SignUp_image2__13iI_ {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: fill;
  overflow-x: hidden;
}

.SignUp_image2Flip__1Azl2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: fill;
  overflow-x: hidden;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.SignUp_links__1tw4v {
  position: absolute;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  padding-left: 1em;
  padding-right: 1em;
}

.SignUp_innerLinks__no8VJ {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.SignUp_modalContainer__2SOmY {
  position: fixed;
  top: 15%;
  left: 5%;
  width: 90%;
  height: 100%;
  background-color: white;
  padding: 2%;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.SignUp_logoDiv__2k5pW {
  display: flex;
  padding: 5%;
  margin-top: 5%;
  justify-content: center;
}

.SignUp_div1text__1zZ8f {
  text-align: center;
  padding: 5%;
  font-size: x-large;
  color: "black";
}

.SignUp_description__2ipPa {
  font-size: smaller;
  text-align: center;
  padding-bottom: 5%;
  color: #64686d;
}

.SignUp_textField__35Uqf {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-bottom: 10%;
  margin-top: 5%;
}
.SignUp_resSend__1tiOV {
  display: flex;
  text-align: center;
  font-size: "Regular 14px/22px SF Pro Text";
  letter-spacing: 0px;
  color: #43425d;
  opacity: 1;
}
.SignUp_sendDisable__2HWrp {
  font-size: 13px;
  color: #bfb4b4;
  font-weight: bold;
  margin-left: 3%;
  cursor: pointer;
}
.SignUp_send__3Tp_r {
  width: 100%;
  margin-right: 21%;
  font-size: 13px;
  color: red;
  font-weight: bold;
  margin-left: 3%;
  cursor: pointer;
  text-align: right;
}
.SignUp_ex__ymHCA {
  font-size: 13px;
  color: #43425d;
  margin-left: 21%;
}
.SignUp_mBottom__17NbF {
  margin-bottom: 43px !important;
}

@media (min-width: 375px) and (max-width: 667px) {
  .SignUp_parent__Ss3HE {
    display: none;
  }

  .SignUp_left__2n3yU {
    max-width: 100%;
  }
}

@media (min-width: 426px) and (max-width: 767px) {
  .SignUp_parent__Ss3HE {
    display: none;
  }

  .SignUp_left__2n3yU {
    max-width: 100%;
  }
}
/* .left_main {
      padding-left: 80%;
  }
  .signUp_text {
      font-size: larger;
  }
  .signUp_desc {
      font-size: medium;
  }
  .termsCondition {
      justify-content: flex-start;
  }
  .buttonDiv {
      width: 280px;
  }
  .signInDiv {
      padding-right: 25%;
  }
}*/

@media (min-width: 320px) and (max-width: 375px) {
  .SignUp_parent__Ss3HE {
    display: none;
  }
  .SignUp_left__2n3yU {
    max-width: 100%;
  }
}

/*
  .left_main {
      padding-left: 80%;
  }
  .signUp_text {
      font-size: medium;
  }
  .signUp_desc {
      font-size: small;
  }
  .buttonDiv {
      width: 283.45px;
      height: 50px;
  }
}
*/

@media (min-width: 376px) and (max-width: 425px) {
  .SignUp_parent__Ss3HE {
    display: none;
  }
  .SignUp_left__2n3yU {
    max-width: unset;
  }
}
/*
  .left_main {
      padding-left: 80%;
  }
  .signUp_text {
      font-size: medium;
  }
  .signUp_desc {
      font-size: small;
  }
  .buttonDiv {
      width: 283.45px;
      height: 50px;
  }
  .signInDiv {
      padding-right: 25%;
  }
}
.verify{
  color: #F2134F;
  text-transform:none;
  font-weight: "bold";
  cursor: "pointer",
}
.verified{
  color:green;
  text-transform:none;
  font-weight: "bold";
  cursor: "pointer",
}
.verifyDisabled{
  pointer-events: none;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .image1 {
      width: 105%;
  }
  .buttonDiv {
      width: 283.45px;
      height: 50px;
  }
  .termsCondition {
      display: flex;
      justify-content: flex-start;
  }
  .linksDiv1 {
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
  }
  .linksDiv2 {
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
  }
  .instaImage {
      width: 60px;
      height: 60px;
  }
  .links {
      padding-left: 0.6em;
  }
  .innerLinks {
      padding-left: 0em;
      justify-content: space-evenly;
  }
}


@media (min-width: 1024px) and (max-width: 1439px) {
  .image1 {
      width: 104%;
  }
  .buttonDiv {
      width: 283.45px;
      height: 50px;
  }
  .termsCondition {
      display: flex;
      justify-content: flex-start;
  }
  .linksDiv1 {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
  }
  .linksDiv2 {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
  }
  .instaImage {
      width: 66px;
      height: 66px;
  }
  .links {
      padding-left: 0.3em;
  }
  .innerLinks {
      padding-left: 0.5em;
      justify-content: space-evenly;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 2559px) {
  .image1 {
      width: 102.5%;
  }
  .buttonDiv {
      width: 283.45px;
      height: 50px;
  }
  .termsCondition {
      display: flex;
      justify-content: flex-start;
  }
  .linksDiv1 {
      width: 58px;
      height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
  }
  .linksDiv2 {
      width: 58px;
      height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
      padding-top: 5%;
  }
  .instaImage {
      width: 72px;
      height: 72px;
  }
  .links {
      padding-left: 0.6em;
  }
  .innerLinks {
      padding-left: 0.5em;
      justify-content: space-evenly;
  }
} */

@media screen and (max-width: 550px) {
  .SignUp_left__2n3yU {
    width: 100vw;
    height: 100vh;
    display: flex;
    /* justify-content: center;
    align-items: center; */
  }
  .SignUp_left_main__OsScx {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100vw;
  }
  .SignUp_modalContainer__2SOmY {
    position: fixed;
    top: 15%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 2%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 1rem;
  }
}

left {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UpdateUser_left_main__3mnbg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.UpdateUser_signUp_text__1V498 {
  font-size: xx-large;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 5%;
}

.UpdateUser_signUp_desc__3V0Of {
  font-size: large;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: #64686D;
  font-weight: lighter;
  padding-bottom: 3%;
}

.UpdateUser_row1__2V1bp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.UpdateUser_row2__2QpRx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.UpdateUser_termsCondition__1sb9l {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.UpdateUser_buttonDiv__3G3JF {
  display: flex;
  padding: 2% 0;
  justify-content: center;
  align-items: center;
}

.UpdateUser_signInDiv__imoKr {
  padding: 3% 0;
}

.UpdateUser_button__3iN6- {
  width: 420px;
  height: 50px;
}

.UpdateUser_logo__2JN1I {
  display: flex;
  padding: 5%;
  align-items: center;
  justify-content: center;
}

.UpdateUser_parent__1vzO0 {
  width: 100vw;
  height: 100vh;
}

.UpdateUser_image1__2ZBYN {
  position: relative;
  padding-left: 5%;
  top: 0;
  left: 0;
  height: 100%;
  width: 102.5%;
  margin: 0;
  object-fit: fill;
  overflow: hidden;
}

.UpdateUser_image2__2a7Nx {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: fill;
  overflow-x: hidden;
}

.UpdateUser_links__1lSzR {
  position: absolute;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  padding-left: 1em;
  padding-right: 1em;
}

.UpdateUser_innerLinks__33i2I {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.UpdateUser_modalContainer__2rFP7 {
  position: fixed;
  top: 15%;
  left: 5%;
  width: 90%;
  height: 100%;
  background-color: white;
  padding: 2%;
  display: flex;
  flex-direction: column;
  position: relative;
    flex: 1 1 auto;
    padding: 1rem;

}

.UpdateUser_logoDiv__Syyzz {
  display: flex;
  padding: 5%;
  margin-top: 5%;
  justify-content: center;
}

.UpdateUser_div1text__2Qyae {
  text-align: center;
  padding: 5%;
  font-size: x-large;
  color: "black";
}

.UpdateUser_description__Uoai5 {
  font-size: smaller;
  text-align: center;
  padding-bottom: 5%;
  color: #64686D;
}

.UpdateUser_textField__xBVRi {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-bottom: 10%;
  margin-top: 5%;
}
.UpdateUser_resSend__2xs_8{
  display: flex;
  text-align: center;
font-size: "Regular 14px/22px SF Pro Text";
letter-spacing: 0px;
color: #43425D;
opacity: 1;
}
.UpdateUser_sendDisable__2eqQy{
  font-size: 13px;
    color: #bfb4b4;
    font-weight: bold;
    margin-left: 3%;
    cursor: pointer;
}
.UpdateUser_send__1JG0D{
  width: 100%;
  margin-right: 21%;
  font-size: 13px;
  color: red;
  font-weight: bold;
  margin-left: 3%;
  cursor: pointer;
  text-align: right
}
.UpdateUser_ex___SIWX{
  font-size: 13px;
    color: #43425D;
    margin-left: 21%;
}
.UpdateUser_mBottom__HOYv0{
  margin-bottom:43px !important
}

@media (min-width: 426px) and (max-width: 767px) {
  .UpdateUser_parent__1vzO0 {
      visibility: hidden;
  }
  .UpdateUser_left_main__3mnbg {
      padding-left: 80%;
  }
  .UpdateUser_signUp_text__1V498 {
      font-size: larger;
  }
  .UpdateUser_signUp_desc__3V0Of {
      font-size: medium;
  }
  .UpdateUser_termsCondition__1sb9l {
      justify-content: flex-start;
  }
  .UpdateUser_buttonDiv__3G3JF {
      width: 280px;
  }
  .UpdateUser_signInDiv__imoKr {
      padding-right: 25%;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .UpdateUser_parent__1vzO0 {
      visibility: hidden;
  }
  .UpdateUser_left_main__3mnbg {
      padding-left: 80%;
  }
  .UpdateUser_signUp_text__1V498 {
      font-size: medium;
  }
  .UpdateUser_signUp_desc__3V0Of {
      font-size: small;
  }
  .UpdateUser_buttonDiv__3G3JF {
      width: 283.45px;
      height: 50px;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .UpdateUser_parent__1vzO0 {
      visibility: hidden;
  }
  .UpdateUser_left_main__3mnbg {
      padding-left: 80%;
  }
  .UpdateUser_signUp_text__1V498 {
      font-size: medium;
  }
  .UpdateUser_signUp_desc__3V0Of {
      font-size: small;
  }
  .UpdateUser_buttonDiv__3G3JF {
      width: 283.45px;
      height: 50px;
  }
  .UpdateUser_signInDiv__imoKr {
      padding-right: 25%;
  }
}
.UpdateUser_verify__36jWD{
  color: #F2134F;
  text-transform:none;
  font-weight: "bold";
  cursor: "pointer",
}
.UpdateUser_verified__wSRBY{
  color:green;
  text-transform:none;
  font-weight: "bold";
  cursor: "pointer",
}
.UpdateUser_verifyDisabled__26dYm{
  pointer-events: none;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .UpdateUser_image1__2ZBYN {
      width: 105%;
  }
  .UpdateUser_buttonDiv__3G3JF {
      width: 283.45px;
      height: 50px;
  }
  .UpdateUser_termsCondition__1sb9l {
      display: flex;
      justify-content: flex-start;
  }
  .UpdateUser_linksDiv1__4GJUi {
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
  }
  .UpdateUser_linksDiv2__1ff2o {
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
  }
  .UpdateUser_instaImage__1iozU {
      width: 60px;
      height: 60px;
  }
  .UpdateUser_links__1lSzR {
      padding-left: 0.6em;
  }
  .UpdateUser_innerLinks__33i2I {
      padding-left: 0em;
      justify-content: space-evenly;
  }
}


@media (min-width: 1024px) and (max-width: 1439px) {
  .UpdateUser_image1__2ZBYN {
      width: 104%;
  }
  .UpdateUser_buttonDiv__3G3JF {
      width: 283.45px;
      height: 50px;
  }
  .UpdateUser_termsCondition__1sb9l {
      display: flex;
      justify-content: flex-start;
  }
  .UpdateUser_linksDiv1__4GJUi {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
  }
  .UpdateUser_linksDiv2__1ff2o {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
  }
  .UpdateUser_instaImage__1iozU {
      width: 66px;
      height: 66px;
  }
  .UpdateUser_links__1lSzR {
      padding-left: 0.3em;
  }
  .UpdateUser_innerLinks__33i2I {
      padding-left: 0.5em;
      justify-content: space-evenly;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 2559px) {
  .UpdateUser_image1__2ZBYN {
      width: 102.5%;
  }
  .UpdateUser_buttonDiv__3G3JF {
      width: 283.45px;
      height: 50px;
  }
  .UpdateUser_termsCondition__1sb9l {
      display: flex;
      justify-content: flex-start;
  }
  .UpdateUser_linksDiv1__4GJUi {
      width: 58px;
      height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
  }
  .UpdateUser_linksDiv2__1ff2o {
      width: 58px;
      height: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      background-color: white;
      padding-top: 5%;
  }
  .UpdateUser_instaImage__1iozU {
      width: 72px;
      height: 72px;
  }
  .UpdateUser_links__1lSzR {
      padding-left: 0.6em;
  }
  .UpdateUser_innerLinks__33i2I {
      padding-left: 0.5em;
      justify-content: space-evenly;
  }
}
body {
  font-family: "Poppins", sans-serif !important;
}
.CustomDrawer_subMenuItems__UT5Nz {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  padding-top: 8px;
  padding-right: 10%;
  padding-bottom: 8px;
  word-wrap: inherit;
}

.CustomDrawer_link__1wCHh {
  text-decoration: none;
  color: #43425d;
}

.CustomDrawer_topbarRightIcon__1U7xM {
  margin-top: 20px;
  right: 0px;
  margin-right: 15px;
  position: absolute;
  display: flex;
  height: 100%;
}

.CustomDrawer_topbarRight__2gKGt {
  right: 0px;
  margin-right: 15px;
  position: absolute;
  display: flex;
  height: 100%;
}

/* ::-webkit-scrollbar {
    display: none;
} */
.CustomDrawer_subMenuItems__UT5Nz {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  padding-top: 8px;
  padding-right: 10%;
  padding-bottom: 8px;
  word-wrap: inherit;
}
.CustomDrawer_profile__CJEXr {
  margin-left: 20px;
  margin-bottom: 0;
  position: relative;
}
.CustomDrawer_avatar__8_XBY {
  height: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0 4px;
  background-color: transparent;
}
Avatar {
  margin: auto;
}
.CustomDrawer_avatarName__2ZNtq {
  color: #646777 !important;
  margin-left: 10px;
  display: block;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  margin-top: auto;
  margin-bottom: auto;
}
.CustomDrawer_avatar__8_XBY svg {
  margin: auto;
  height: 18px;
  width: 18px;
  fill: #b1c3c8;
}
.CustomDrawer_topBarBack__2Qq41 {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  border: none;
}
.CustomDrawer_topBarMenuWrap__27Bow {
  right: 0px;
  z-index: 101;
  position: absolute;
  width: 100%;
  /* min-width: 210px; */
}
.CustomDrawer_topBarMenu__2ovSi {
  left: 0px !important;
  background: white;
  width: 100%;
  border-radius: 0;
  border: none;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;
}
.CustomDrawer_hr__ueFKb {
  margin: auto;
  margin-left: 6px;
  border-right: 2px solid rgb(239 239 239);
  display: block;
  /* width: 100%; */
  height: 20px;
  margin-right: -2px;
}
.CustomDrawer_tobBarLinik__3aquL {
  color: #707070;
  display: flex;
  padding: 9px 4px;
  transition: all 0.3s;
  height: 32px;
  width: 100%;
  position: relative;
  cursor: pointer;
  /* text-decoration: none; */
}
.CustomDrawer_tobBarLinik__3aquL:hover {
  background-color: #fafbfe;
  color: #707070;
}
.CustomDrawer_tobBarLinik__3aquL:before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  background: #4ce1b6;
  opacity: 0;
  transition: all 0.3s;
}
.CustomDrawer_noHover__2L4rP:before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 0px !important;
  background: #fafbfe !important;
  opacity: 0;
  transition: all 0.3s;
}
button:focus {
  outline: none !important;
}
.CustomDrawer_tobBarLinik__3aquL:hover:before {
  opacity: 1;
}
.CustomDrawer_tobBarLinkTitle__3xmQB {
  color: #646777;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
}
.CustomDrawer_tobBarLinkIcon__3SxFu {
  color: #dddddd;
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;
}
.CustomDrawer_topBarCollapse__3lCoh {
  position: relative;
}
.CustomDrawer_topBarCollapseLng__3EIwj {
  min-width: 70px;
  display: block;
}
.CustomDrawer_topBarCollapseLng__3EIwj {
  color: #646777;
}
.CustomDrawer_tobBarButton__2N9ci {
  font-size: 18px;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;
  padding: 0 4px;
  width: 100%;
}
.CustomDrawer_tobBarButton__2N9ci {
  padding: 0 4px;
  width: 100%;
}
.CustomDrawer_topBarLngBtnTitle__bdm_8:not(:last-child) {
  margin-right: 5px;
}
.CustomDrawer_topBarLngBtnTitle__bdm_8:not(:last-child) {
  margin-right: 5px;
  color: #646777;
  display: flex;
  font-size: 11px;
  align-items: center;
  margin: auto 0;
}
.CustomDrawer_topBarLngBtnTitle__bdm_8 img {
  margin-right: 4px;
  height: 11px;
  width: 16px;
}
.CustomDrawer_tobBarButton__2N9ci svg {
  margin: auto;
  height: 18px;
  width: 18px;
  fill: #b1c3c8;
  margin-left: 8px;
}

.CustomDrawer_tobBarContentCollapse__2rj6D {
  background: white;
  right: 0px;
  overflow: unset;
}
.CustomDrawer_tobBarContentCollapse__2rj6D {
  /* width: 270px; */
  position: absolute;
  bottom: 20px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);
  z-index: 101;
}
.CustomDrawer_tobBarClsContentLgn__3d-Kn {
  width: 72px;
  padding: 10px 0;
  bottom: 0;
  /* width: 100%; */
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  display: flex;
  flex-direction: column;
  z-index: unset;
}
.CustomDrawer_topBarLngBtn__3iq61 {
  text-align: left;
  padding: 4px 15px;
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  transition: 0.3s;
  font-family: "Poppins", sans-serif !important;
  font-size: 100%;
}
.CustomDrawer_Dblock__2nYW_ {
  display: none;
}
.CustomDrawer_link__1wCHh {
  text-decoration: none;
  color: #43425d;
}
.CustomDrawer_active__2CEDe {
  border-left: 3px solid #f2134f !important;
  color: #f2134f !important;
}
.CustomDrawer_activeIcon__25nmj {
  color: #f2134f !important;
}
.CustomDrawer_inactive__1lVgI {
  color: rgba(0, 0, 0, 0.54);
}
.CustomDrawer_tagLine__3PwiQ {
  display: inline-grid;
}

.CustomDrawer_rightMenu__2kdQ6 {
  background-color: #f2134f;
}

.DashBoard_title__3ltlt {
    margin-top: 3%;
    margin-left: 5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    font: Regular Source Sans Pro;
    color: #43425D;
}

.DashBoard_grid__3LXko {
    display: flex;
    justify-content: flex-start;
    align-content: space-between;
    align-items: center;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 1;
    margin-left: 3%;
}
.Card_card__3kr4- {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Card_title__3aB0o {
  font-weight: 400;
  color: #0c0b20;
  font-size: 1.5rem;
  /* opacity: 50%; */
}

.Card_details__OVotv {
  height: 0.5;
  display: flex;
  padding-top: 10%;
  flex-direction: row;
  justify-content: space-between;
}

.Card_stats__2-HxU {
  font-size: x-large;
  font-weight: bolder;
  color: #4d4f5c;
  opacity: 1;
  font: Bold Source Sans Pro;
}

.Card_image__5GIfa {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.ResetPassword_div1__2-em9{
    position: fixed;
    top:15%;
    left: 30%;
    width: 40%;
    height: 70%;
    background-color:white;
    padding: 2%;
}
.ResetPassword_logoDiv__2TAOL{
    display: flex;
    padding: 5%;
    justify-content: center;
}
.ResetPassword_div1text__2218T{
    text-align: center;
    padding: 2%;
    font-size: x-large;
}
.ResetPassword_description__-1Zdp{
    font-size: smaller;
    text-align: center;
    padding-bottom: 5%;
}
.ResetPassword_textField__1mdTn{
    display: flex;
    justify-content: center;
    align-content: center;
}
.ResetPassword_cancelIcon__B3k5x{
    position: absolute;
    top: -25px;
    left: 96%;
}
.SuccessfulPasswordReset_div1__cNGN_ {
    position: fixed;
    top: 15%;
    left: 30%;
    width: 40%;
    height: 70%;
    background-color: white;
    padding: 2%;
}

.SuccessfulPasswordReset_logoDiv__r_MJR {
    display: flex;
    padding: 5%;
    justify-content: center;
}

.SuccessfulPasswordReset_div1text__2EVp_ {
    text-align: center;
    padding: 5%;
    font-size: xx-large;
    font-weight: bolder;
}

.SuccessfulPasswordReset_description__3R9N9 {
    text-align: center;
    padding: 5%;
    color: #64686D;
}

.SuccessfulPasswordReset_textField__2QslZ {
    display: flex;
    justify-content: center;
    align-content: center;
}

.SuccessfulPasswordReset_cancelIcon__1ugOI {
    position: absolute;
    top: -25px;
    left: 96%;
}
.State_main__3sMe7 {
  background-color: #F9F9F9;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.State_title__2O8kA {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: xx-large;
}

.State_tableDiv__1SZ9l {

    background-color: white;
    margin-left: 2%;
    margin-top: 1.5%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.State_searchBar__JD3GP {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.State_searchText__3OJyR {
  display: flex;
  justify-content: center;
  align-items: center;
}

.State_dropDownDiv__KZ6Aa {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.State_searchBarDiv__xFaug {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.State_searchAndDrop__3NLLM {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.State_buttonAndFilter__3tcQX {

    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;

}

@media (min-width: 1441px) and (max-width: 2560px) {
  .State_searchAndDrop__3NLLM {
    width: 40%;
  }
  .State_dropDownDiv__KZ6Aa {
    width: 60%;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .State_searchAndDrop__3NLLM {
    width: 50%;
  }
  .State_dropDownDiv__KZ6Aa {
    width: 50%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .State_searchAndDrop__3NLLM {
    width: 70%;
  }
  .State_dropDownDiv__KZ6Aa {
    width: 60%;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .State_searchAndDrop__3NLLM {
    width: 70%;
  }
  .State_dropDownDiv__KZ6Aa {
    width: 60%;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .State_searchAndDrop__3NLLM {
    width: 70%;
  }
  .State_dropDownDiv__KZ6Aa {
    width: 80%;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .State_searchAndDrop__3NLLM {
    width: 70%;
  }
  .State_dropDownDiv__KZ6Aa {
    width: 80%;
  }
}

.Role_main__PQwAf {
    background-color: #F9F9F9;
    width: 100%;
    height: 100%;
    padding: 2%;
}

.Role_title__iQ2Lq {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: xx-large;
}

.Role_tableDiv__1-YcI {
    background-color: white;
    margin-left: 2%;
    margin-top: 1.5%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Role_searchBar__1ZwdX {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.Role_searchText__1QdFR {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Role_dropDownDiv__1DoUt {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.Role_searchBarDiv__5O8dL {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.Role_searchAndDrop__29E0w {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.Role_buttonAndFilter__3Oar3 {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .Role_searchAndDrop__29E0w {
        width: 40%;
    }
    .Role_dropDownDiv__1DoUt {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .Role_searchAndDrop__29E0w {
        width: 50%;
    }
    .Role_dropDownDiv__1DoUt {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .Role_searchAndDrop__29E0w {
        width: 70%;
    }
    .Role_dropDownDiv__1DoUt {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .Role_searchAndDrop__29E0w {
        width: 70%;
    }
    .Role_dropDownDiv__1DoUt {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .Role_searchAndDrop__29E0w {
        width: 70%;
    }
    .Role_dropDownDiv__1DoUt {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .Role_searchAndDrop__29E0w {
        width: 70%;
    }
    .Role_dropDownDiv__1DoUt {
        width: 80%;
    }
}
.Role_modalContainer__2MwRO {
    max-height: 20vw;
}
.Team_main__2PAIj {
    background-color: #F9F9F9;
    width: 100%;
    height: 100%;
    padding: 2%;
}

.Team_title__3rmmq {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: xx-large;
}

.Team_tableDiv__2n6Oq {
    background-color: white;
    margin-left: 2%;
    margin-top: 1.5%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Team_searchBar__nlQeR {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.Team_searchText__HKChX {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Team_dropDownDiv__1i2p6 {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.Team_searchBarDiv__2UF4Z {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.Team_searchAndDrop__3vUtP {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.Team_buttonAndFilter__1NXuO {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .Team_searchAndDrop__3vUtP {
        width: 40%;
    }
    .Team_dropDownDiv__1i2p6 {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .Team_searchAndDrop__3vUtP {
        width: 50%;
    }
    .Team_dropDownDiv__1i2p6 {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .Team_searchAndDrop__3vUtP {
        width: 70%;
    }
    .Team_dropDownDiv__1i2p6 {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .Team_searchAndDrop__3vUtP {
        width: 70%;
    }
    .Team_dropDownDiv__1i2p6 {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .Team_searchAndDrop__3vUtP {
        width: 70%;
    }
    .Team_dropDownDiv__1i2p6 {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .Team_searchAndDrop__3vUtP {
        width: 70%;
    }
    .Team_dropDownDiv__1i2p6 {
        width: 80%;
    }
}
.Country_main__1isDV {
    background-color: #F9F9F9;
    width: 100%;
    height: 100%;
    padding: 2%;
}

.Country_title__1G_ws {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: xx-large;
}

.Country_tableDiv__3-iCW {
    background-color: white;
    margin-left: 2%;
    margin-top: 1.5%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Country_searchBar__1dREl {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.Country_searchText__38ogM {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Country_dropDownDiv__3Etej {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.Country_searchBarDiv__3Dlgw {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.Country_searchAndDrop__2N66K {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.Country_buttonAndFilter__3OSSr {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .Country_searchAndDrop__2N66K {
        width: 40%;
    }
    .Country_dropDownDiv__3Etej {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .Country_searchAndDrop__2N66K {
        width: 50%;
    }
    .Country_dropDownDiv__3Etej {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .Country_searchAndDrop__2N66K {
        width: 70%;
    }
    .Country_dropDownDiv__3Etej {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .Country_searchAndDrop__2N66K {
        width: 70%;
    }
    .Country_dropDownDiv__3Etej {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .Country_searchAndDrop__2N66K {
        width: 70%;
    }
    .Country_dropDownDiv__3Etej {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .Country_searchAndDrop__2N66K {
        width: 70%;
    }
    .Country_dropDownDiv__3Etej {
        width: 80%;
    }
}
.Blood_main__3RsXG {
  background-color: #F9F9F9;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.Blood_title__1nHAF {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: xx-large;
}

.Blood_tableDiv__2QoKL {

    background-color: white;
    margin-left: 2%;
    margin-top: 1.5%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.Blood_searchBar__2myix {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.Blood_searchText__2VDUC {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Blood_dropDownDiv__3v3s4 {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.Blood_searchBarDiv__2916k {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.Blood_searchAndDrop__Qegt2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.Blood_buttonAndFilter__3C5rp {

    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;

}

@media (min-width: 1441px) and (max-width: 2560px) {
  .Blood_searchAndDrop__Qegt2 {
    width: 40%;
  }
  .Blood_dropDownDiv__3v3s4 {
    width: 60%;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .Blood_searchAndDrop__Qegt2 {
    width: 50%;
  }
  .Blood_dropDownDiv__3v3s4 {
    width: 50%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .Blood_searchAndDrop__Qegt2 {
    width: 70%;
  }
  .Blood_dropDownDiv__3v3s4 {
    width: 60%;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .Blood_searchAndDrop__Qegt2 {
    width: 70%;
  }
  .Blood_dropDownDiv__3v3s4 {
    width: 60%;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .Blood_searchAndDrop__Qegt2 {
    width: 70%;
  }
  .Blood_dropDownDiv__3v3s4 {
    width: 80%;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .Blood_searchAndDrop__Qegt2 {
    width: 70%;
  }
  .Blood_dropDownDiv__3v3s4 {
    width: 80%;
  }
}

.City_main__3kTgl {
    background-color: #F9F9F9;
    width: 100%;
    height: 100%;
    padding: 2%;
}

.City_title__SYe8P {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: xx-large;
}

.City_tableDiv__2MbII {
    background-color: white;
    margin-left: 2%;
    margin-top: 1.5%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.City_searchBar__1qrOB {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.City_searchText__3JakY {
    display: flex;
    justify-content: center;
    align-items: center;
}

.City_dropDownDiv__2WK0k {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.City_searchBarDiv__2j8Ip {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.City_searchAndDrop__3ahAa {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.City_buttonAndFilter__1AcZk {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
}
.City_modalContainer__gbGtm {
    max-height: 23vw;
}
@media (min-width: 1441px) and (max-width: 2560px) {
    .City_searchAndDrop__3ahAa {
        width: 40%;
    }
    .City_dropDownDiv__2WK0k {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .City_searchAndDrop__3ahAa {
        width: 50%;
    }
    .City_dropDownDiv__2WK0k {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .City_searchAndDrop__3ahAa {
        width: 70%;
    }
    .City_dropDownDiv__2WK0k {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .City_searchAndDrop__3ahAa {
        width: 70%;
    }
    .City_dropDownDiv__2WK0k {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .City_searchAndDrop__3ahAa {
        width: 70%;
    }
    .City_dropDownDiv__2WK0k {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .City_searchAndDrop__3ahAa {
        width: 70%;
    }
    .City_dropDownDiv__2WK0k {
        width: 80%;
    }
}
.District_main__2ppFv {
    background-color: #F9F9F9;
    width: 100%;
    height: 100%;
    padding: 2%;
}

.District_title__2duoc {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: xx-large;
}

.District_tableDiv__lJ4lW {
    background-color: white;
    margin-left: 2%;
    margin-top: 1.5%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.District_searchBar__3y9YX {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.District_searchText__2fYOj {
    display: flex;
    justify-content: center;
    align-items: center;
}

.District_dropDownDiv__2muSu {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.District_searchBarDiv__1BBt7 {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.District_searchAndDrop__2w0n1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.District_buttonAndFilter__Z7Hx3 {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .District_searchAndDrop__2w0n1 {
        width: 40%;
    }
    .District_dropDownDiv__2muSu {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .District_searchAndDrop__2w0n1 {
        width: 50%;
    }
    .District_dropDownDiv__2muSu {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .District_searchAndDrop__2w0n1 {
        width: 70%;
    }
    .District_dropDownDiv__2muSu {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .District_searchAndDrop__2w0n1 {
        width: 70%;
    }
    .District_dropDownDiv__2muSu {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .District_searchAndDrop__2w0n1 {
        width: 70%;
    }
    .District_dropDownDiv__2muSu {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .District_searchAndDrop__2w0n1 {
        width: 70%;
    }
    .District_dropDownDiv__2muSu {
        width: 80%;
    }
}
.Language_main__1BboU {
    background-color: #F9F9F9;
    width: 100%;
    height: 100%;
    padding: 2%;
}

.Language_title__9fR1z {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: xx-large;
}

.Language_tableDiv__3NPNN {
    background-color: white;
    margin-left: 2%;
    margin-top: 1.5%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Language_searchBar__1lABk {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.Language_searchText__37oxL {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Language_dropDownDiv__1TXr9 {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.Language_searchBarDiv__177ma {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.Language_searchAndDrop__1Tx-t {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.Language_buttonAndFilter__3uWjN {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .Language_searchAndDrop__1Tx-t {
        width: 40%;
    }
    .Language_dropDownDiv__1TXr9 {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .Language_searchAndDrop__1Tx-t {
        width: 50%;
    }
    .Language_dropDownDiv__1TXr9 {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .Language_searchAndDrop__1Tx-t {
        width: 70%;
    }
    .Language_dropDownDiv__1TXr9 {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .Language_searchAndDrop__1Tx-t {
        width: 70%;
    }
    .Language_dropDownDiv__1TXr9 {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .Language_searchAndDrop__1Tx-t {
        width: 70%;
    }
    .Language_dropDownDiv__1TXr9 {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .Language_searchAndDrop__1Tx-t {
        width: 70%;
    }
    .Language_dropDownDiv__1TXr9 {
        width: 80%;
    }
}
.Module_main__j3S6G {
    background-color: #F9F9F9;
    width: 100%;
    height: 100%;
    padding: 2%;
}

.Module_title__g_-TX {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: xx-large;
}

.Module_tableDiv__E3VgS {
    background-color: white;
    margin-left: 2%;
    margin-top: 1.5%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Module_searchBar__xPsyN {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.Module_searchText__2RCWE {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Module_dropDownDiv__1IMb1 {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.Module_searchBarDiv__BczN1 {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.Module_searchAndDrop__1wLya {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.Module_buttonAndFilter__TCmIx {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .Module_searchAndDrop__1wLya {
        width: 40%;
    }
    .Module_dropDownDiv__1IMb1 {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .Module_searchAndDrop__1wLya {
        width: 50%;
    }
    .Module_dropDownDiv__1IMb1 {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .Module_searchAndDrop__1wLya {
        width: 70%;
    }
    .Module_dropDownDiv__1IMb1 {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .Module_searchAndDrop__1wLya {
        width: 70%;
    }
    .Module_dropDownDiv__1IMb1 {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .Module_searchAndDrop__1wLya {
        width: 70%;
    }
    .Module_dropDownDiv__1IMb1 {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .Module_searchAndDrop__1wLya {
        width: 70%;
    }
    .Module_dropDownDiv__1IMb1 {
        width: 80%;
    }
}
.BlankSamadhanID_main__3GlLn {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.BlankSamadhanID_title__2FFQL {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 1%;
}

.BlankSamadhanID_tableDiv__2Erqo {
  background-color: white;
  /* margin-left: 1%; */
  margin-top: 1.5%;
  padding: 0 1.5%;
  /* height: 70%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.BlankSamadhanID_searchBar__1oxMe {
  /* display: flex; */
  flex-direction: row;
  align-items: flex-end;
  font-size: 14px;
}

.BlankSamadhanID_searchText__9PaIs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.BlankSamadhanID_searchAndDrop__1-091 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.BlankSamadhanID_dropDownDiv__1Mz2p {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.BlankSamadhanID_searchBarDiv__vcPCc {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.BlankSamadhanID_buttonDiv__Wkn4l {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-evenly;
}

@media (min-width: 1441px) and (max-width: 2560px) {
  .BlankSamadhanID_searchAndDrop__1-091 {
    width: 40%;
  }
  .BlankSamadhanID_dropDownDiv__1Mz2p {
    width: 60%;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .BlankSamadhanID_searchAndDrop__1-091 {
    width: 45%;
  }
  .BlankSamadhanID_dropDownDiv__1Mz2p {
    width: 50%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .BlankSamadhanID_searchAndDrop__1-091 {
    width: 65%;
  }
  .BlankSamadhanID_dropDownDiv__1Mz2p {
    width: 60%;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .BlankSamadhanID_searchAndDrop__1-091 {
    width: 70%;
  }
  .BlankSamadhanID_dropDownDiv__1Mz2p {
    width: 60%;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .BlankSamadhanID_searchAndDrop__1-091 {
    width: 70%;
  }
  .BlankSamadhanID_dropDownDiv__1Mz2p {
    width: 80%;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .BlankSamadhanID_searchAndDrop__1-091 {
    width: 70%;
  }
  .BlankSamadhanID_dropDownDiv__1Mz2p {
    width: 80%;
  }
}

.Organisation_main__3hTLF {
    background-color: #F9F9F9;
    width: 100%;
    height: 90vh;
    padding: 2%;
}

.Organisation_title__1xaNm {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: xx-large;
}
.Organisation_buttonAndFilter__16ygj {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
}


.Organisation_tableDiv__1iaBT {
    background-color: white;
    margin-top: 5%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Organisation_searchBar__2pcQw {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    align-content: flex-end;
}

.Organisation_searchText__29skZ {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Organisation_dropDownDiv__20r5a {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
    border-color: #D7DAE2;
}

.Organisation_searchBarDiv__8On2Z {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.Organisation_searchAndDrop__2y68l {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .Organisation_searchAndDrop__2y68l {
        width: 40%;
    }
    .Organisation_dropDownDiv__20r5a {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .Organisation_searchAndDrop__2y68l {
        width: 50%;
    }
    .Organisation_dropDownDiv__20r5a {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .Organisation_searchAndDrop__2y68l {
        width: 70%;
    }
    .Organisation_dropDownDiv__20r5a {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .Organisation_searchAndDrop__2y68l {
        width: 70%;
    }
    .Organisation_dropDownDiv__20r5a {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .Organisation_searchAndDrop__2y68l {
        width: 70%;
    }
    .Organisation_dropDownDiv__20r5a {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .Organisation_searchAndDrop__2y68l {
        width: 70%;
    }
    .Organisation_dropDownDiv__20r5a {
        width: 80%;
    }
}
.OrganisationMain_main__2bJY5 {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.OrganisationMain_title__3gCJC {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #43425d;
}

.OrganisationMain_tabsDiv__3Zzq2 {
  margin-left: 2%;
  margin-top: 2%;
}

/*Organisation */

.OrganisationMain_organisationDiv__2tKf8 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  /* margin-left: -1%; */
}

.OrganisationMain_organisationFormDiv__1ZSDB {
  display: flex;
  flex-direction: column;
  /* align-self: center; */
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 12px #00000014;
}

.OrganisationMain_formRowDiv__1-a87 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 3%;
  margin-bottom: 4%;
}
.OrganisationMain_collapsing__1BNVy {
  right: 0px !important;
}

.OrganisationMain_organisationProfileDiv__2y0pM {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.OrganisationMain_buttonAndFilterRole__2ISK8 {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-evenly;
}

.OrganisationMain_container__3d4bN {
  width: 250px;
  margin-top: 5%;
}

.OrganisationMain_circle__N3Rkj {
  position: relative;
  width: 100%;
  height: 0;
  padding: 100% 0 0;
  border-radius: 50%;
  overflow: hidden;
  border: 7px solid white;
  box-shadow: 0px 3px 6px #00000029;
}

.OrganisationMain_circle__N3Rkj img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.OrganisationMain_peopleDiv__VPZXf {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

/* Inventory */

.OrganisationMain_tableDiv__1hQCJ {
  background-color: white;
  margin-left: -1%;
  margin-top: 1.5%;
  padding: 1.5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.OrganisationMain_searchBar__36aI- {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
}

.OrganisationMain_searchText__H5E1O {
  display: flex;
  justify-content: center;
  align-items: center;
}

.OrganisationMain_dropDownDiv__1S30f {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  width: 40%;
}

.OrganisationMain_searchBarDiv__K63OR {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.OrganisationMain_searchAndDrop__2aYSF {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.OrganisationMain_buttonAndFilter__1lHlv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media (min-width: 1441px) and (max-width: 2560px) {
  .OrganisationMain_searchAndDrop__2aYSF {
    width: 50%;
  }
  .OrganisationMain_dropDownDiv__1S30f {
    width: 60%;
  }
  .OrganisationMain_buttonAndFilter__1lHlv {
    width: 30%;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .OrganisationMain_searchAndDrop__2aYSF {
    width: 50%;
  }
  .OrganisationMain_dropDownDiv__1S30f {
    width: 50%;
  }
  .OrganisationMain_buttonAndFilter__1lHlv {
    width: 30%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .OrganisationMain_searchAndDrop__2aYSF {
    width: 70%;
  }
  .OrganisationMain_dropDownDiv__1S30f {
    width: 60%;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .OrganisationMain_searchAndDrop__2aYSF {
    width: 50%;
  }
  .OrganisationMain_dropDownDiv__1S30f {
    width: 50%;
  }
  .OrganisationMain_buttonAndFilter__1lHlv {
    width: 65%;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .OrganisationMain_searchAndDrop__2aYSF {
    width: 70%;
  }
  .OrganisationMain_dropDownDiv__1S30f {
    width: 80%;
  }
  .OrganisationMain_buttonAndFilter__1lHlv {
    width: 65%;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .OrganisationMain_searchAndDrop__2aYSF {
    width: 70%;
  }
  .OrganisationMain_dropDownDiv__1S30f {
    width: 80%;
  }
  .OrganisationMain_buttonAndFilter__1lHlv {
    width: 85%;
  }
}

/* Billing */

.OrganisationMain_billingFormDiv__RIs5o {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 12px #00000014;
  margin-left: -1%;
}

/*Account*/

.OrganisationMain_paginationDiv__2VcUP {
  margin-top: 2%;
  /* display: flex; */
  align-items: flex-end;
  justify-content: flex-end;
}
.OrganisationMain_paginationDiv__2VcUP {
  margin-top: 2%;
  /* display: flex; */
  align-items: flex-end;
  justify-content: flex-end;
}

/*Account2 */

.OrganisationMain_acc2mainDiv__3uJye {
  background-color: white;
  width: 100%;
  height: 55vh;
  display: flex;
  justify-content: center;
  margin-left: -1%;
}

.OrganisationMain_spanDiv__1Cscl {
  text-align: center;
  font: normal normal normal 25px/33px Segoe UI;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  margin-top: 10%;
}

/*Settings*/

.OrganisationMain_settingsFormDiv__3FDFQ {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 12px #00000014;
}

/* Modules */

.OrganisationMain_moduleDiv__26cXz {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: -2%;
}

.OrganisationMain_table1div__2x4mT {
  width: 22%;
  display: flex;
  flex-direction: column;
}

.OrganisationMain_table2div__3GJZ9 {
  width: 100%;
}

/* Teams */

.OrganisationMain_teamDiv__17w8C {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  margin-top: 2%;
}

.OrganisationMain_cardDiv__HmOHr {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.OrganisationMain_circleCard__2Etai {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.OrganisationMain_containerDiv__2-_gP {
  width: 100px;
  margin-top: 5%;
}

.OrganisationMain_circleDiv__go_KN {
  height: auto;
  padding-top: 30%;
  width: 30%;
  border-radius: 50%;
  text-align: center;
  color: white;
  background: #3b86ff;
  position: absolute;
  margin-top: -10%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.OrganisationMain_teamTableDiv__1Ikh- {
  width: 100%;
  margin-top: 4%;
}

.OrganisationMain_teamButtonDiv__1WbPL {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 7%;
  width: 100%;
}

/* VerifyDocument */

.OrganisationMain_vdtable2div__kMYsb {
  width: 73%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.OrganisationMain_vdsearchBar__2x7BF {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.OrganisationMain_vdsearchText__1Ldwg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.OrganisationMain_vddropDownDiv__15afk {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.OrganisationMain_vdsearchBarDiv__3kXXA {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.OrganisationMain_vdsearchAndDrop__2fAnj {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.OrganisationMain_vdbuttonAndFilter__LHg-L {
  display: flex;
  width: 30%;
  flex-direction: row;
  justify-content: flex-end;
}

.OrganisationMain_vdpaginationDiv__3Bjq2 {
  margin-top: 2%;
  /* display: flex; */
  align-items: flex-end;
  justify-content: flex-end;
}
.OrganisationMain_modalContainer__20rTj {
  max-height: 70vw;
  overflow-x: hidden;
}
.OrganisationMain_selected__pMeMW {
  font-weight: bold !important;
}
.OrganisationMain_paginationDiv__2VcUP {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* margin-right: 6%; */
}

.OrganisationMain_vdinnerDiv__32MxI {
  margin: 2% 0;
  background-color: #f0f2f8;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.OrganisationMain_vdmodalContainer__3VuYM {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 26vw;
  overflow-x: hidden;
}
.OrganisationMain_profile__1koWE {
  margin-left: 20px;
  margin-bottom: 0;
  position: relative;
  margin-bottom: 0;
  position: relative;
  background: black;
  /* padding: 8px 18px; */
  border-radius: 2px;
  width: 92px;
  height: 34px;
}
.OrganisationMain_avatar__1mkdW {
  height: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0 4px;
  background-color: transparent;
}
Avatar {
  margin: auto;
}
.OrganisationMain_avatarName__11xhM {
  color: #646777 !important;
  margin-left: 10px;
  display: block;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  margin-top: auto;
  margin-bottom: auto;
}
.OrganisationMain_avatar__1mkdW svg {
  margin: auto;
  height: 33px;
  width: 27px;
  fill: #b1c3c8;
  margin-left: 14px;
}
.OrganisationMain_topBarBack__38sTx {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;
}
.OrganisationMain_topBarMenuWrap__3mzwJ {
  right: 0px;
  z-index: 101;
  position: absolute;
  width: 100%;
  width: 217px;
  box-shadow: 1px 1px 2px 0px black;
  /* min-width: 210px; */
}
.OrganisationMain_topBarMenu__YGiGJ {
  left: 0px !important;
  background: white;
  width: 100%;
  border-radius: 0;
  border: none;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;
  padding: 8px 0;
}
.OrganisationMain_hr__1C7SN {
  margin: auto;
  margin-left: 6px;
  border-right: 2px solid rgb(239 239 239);
  display: block;
  /* width: 100%; */
  height: 20px;
  margin-right: -2px;
}
.OrganisationMain_tobBarLinik__rkUYm {
  color: #707070;
  display: flex;
  padding: 9px 4px;
  transition: all 0.3s;
  height: 32px;
  width: 100%;
  position: relative;
  cursor: pointer;
  /* text-decoration: none; */
}
.OrganisationMain_tobBarLinik__rkUYm:hover {
  background-color: #fafbfe;
  color: #707070;
}
.OrganisationMain_tobBarLinik__rkUYm:before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  background: #4ce1b6;
  opacity: 0;
  transition: all 0.3s;
}
.OrganisationMain_noHover__2AE9y:before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 0px !important;
  background: #fafbfe !important;
  opacity: 0;
  transition: all 0.3s;
}
button:focus {
  outline: none !important;
}
.OrganisationMain_tobBarLinik__rkUYm:hover:before {
  opacity: 1;
}
.OrganisationMain_tobBarLinkTitle__iYA61 {
  color: #646777;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
}
.OrganisationMain_tobBarLinkIcon__2jBV1 {
  color: #dddddd;
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;
}
.OrganisationMain_topBarCollapse__1q-0X {
  position: relative;
}
.OrganisationMain_topBarCollapseLng__WcpbV {
  min-width: 70px;
  display: block;
}
.OrganisationMain_topBarCollapseLng__WcpbV {
  color: #646777;
}
.OrganisationMain_tobBarButton__1FivC {
  font-size: 18px;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;
  padding: 0 4px;
  width: 100%;
}
.OrganisationMain_tobBarButton__1FivC {
  padding: 0 4px;
  width: 100%;
}
.OrganisationMain_topBarLngBtnTitle__3WnF7:not(:last-child) {
  margin-right: 5px;
}
.OrganisationMain_topBarLngBtnTitle__3WnF7:not(:last-child) {
  margin-right: 5px;
  color: #646777;
  display: flex;
  font-size: 11px;
  align-items: center;
  margin: auto 0;
}
.OrganisationMain_topBarLngBtnTitle__3WnF7 img {
  margin-right: 4px;
  height: 11px;
  width: 16px;
}
.OrganisationMain_tobBarButton__1FivC svg {
  margin: auto;
  height: 18px;
  width: 18px;
  fill: #b1c3c8;
  margin-left: 8px;
}

.OrganisationMain_tobBarContentCollapse__1XB3D {
  background: white;
  right: 0px;
}
.OrganisationMain_tobBarContentCollapse__1XB3D {
  /* width: 270px; */
  position: absolute;
  bottom: 20px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);
  z-index: 101;
}
.OrganisationMain_tobBarClsContentLgn__20Mse {
  width: 72px;
  padding: 10px 0;
  bottom: 0;
  /* width: 100%; */
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.OrganisationMain_topBarLngBtn__1EO2d {
  text-align: left;
  padding: 4px 15px;
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  transition: 0.3s;
  font-family: sans-serif;
  font-size: 100%;
}
.OrganisationMain_link__19r5c {
  text-decoration: none;
  color: #43425d;
}
@media (min-width: 1441px) and (max-width: 2560px) {
  .OrganisationMain_vdsearchAndDrop__2fAnj {
    width: 55%;
  }
  .OrganisationMain_vddropDownDiv__15afk {
    width: 60%;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .OrganisationMain_vdsearchAndDrop__2fAnj {
    width: 100%;
  }
  .OrganisationMain_vddropDownDiv__15afk {
    width: 50%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .OrganisationMain_vdsearchAndDrop__2fAnj {
    width: 70%;
  }
  .OrganisationMain_vddropDownDiv__15afk {
    width: 60%;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .OrganisationMain_vdsearchAndDrop__2fAnj {
    width: 70%;
  }
  .OrganisationMain_vddropDownDiv__15afk {
    width: 60%;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .OrganisationMain_vdsearchAndDrop__2fAnj {
    width: 70%;
  }
  .OrganisationMain_vddropDownDiv__15afk {
    width: 80%;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .OrganisationMain_vdsearchAndDrop__2fAnj {
    width: 70%;
  }
  .OrganisationMain_vddropDownDiv__15afk {
    width: 80%;
  }
}

/* Security */

.OrganisationMain_secMainDiv__HTd_j {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: -1%;
  padding: 0.5%;
}

.OrganisationMain_secHeader__3IOsb {
  width: 100%;
  height: 10%;
  margin-bottom: 3%;
  margin-top: 2%;
}

.OrganisationMain_secTitle__2qRcf {
  margin-left: 3%;
  color: #707070;
  font-size: 22px;
  font: Source Sans Pro;
}

.OrganisationMain_secHeader2__1aI_o {
  background-color: #f5f6fa;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.OrganisationMain_secTitle2__17J7q {
  color: #43425d;
  font-size: 22px;
  font: Semibold 22px/25px Source Sans Pro;
  font-weight: 500;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}

.OrganisationMain_cellDiv__Gv0EW {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 6%;
  border-bottom: 0.5px solid #bbbbbb;
}

.OrganisationMain_innerCellDiv__3OT4- {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0%;
  margin-left: 5%;
}

.OrganisationMain_secFinalDiv__3YiU5 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5%;
  margin-top: 2%;
  margin-bottom: 10%;
}

@media screen and (max-width: 550px) {
  .OrganisationMain_main__2bJY5 {
    background-color: #f9f9f9;
    width: 100vw;
    height: 100%;
    padding: 1%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .OrganisationMain_organisationDiv__2tKf8 {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: space-between;
    /* margin-left: -1%; */
  }

  .OrganisationMain_teamDiv__17w8C {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 2%;
    width: 75%;
  }

  .OrganisationMain_teamTableDiv__1Ikh- {
    width: 75%;
    margin-top: 4%;
  }

  .OrganisationMain_teamButtonDiv__1WbPL {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 7%;
    width: 50%;
  }
}

.Payments_main__233zz {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Payments_title__29Fj4 {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #43425d;
}

.Payments_tabsDiv__2STdm {
  margin-left: 2%;
  margin-top: 2%;
}

.Payments_details-boxes__IHrdC {
  display: flex;
}
/*Organisation */

.Payments_organisationDiv__256th {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: -1%;
}

.Payments_organisationFormDiv__1lJeS {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 12px #00000014;
}

.Payments_formRowDiv__2OfbC {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 3%;
  margin-bottom: 4%;
}
.Payments_collapsing__2Dvuh {
  right: 0px !important;
}

.Payments_organisationProfileDiv__15j1i {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Payments_buttonAndFilterRole__2XtKU {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-evenly;
}

.Payments_container__2paFF {
  width: 250px;
  margin-top: 5%;
}

.Payments_circle__3QBkv {
  position: relative;
  width: 100%;
  height: 0;
  padding: 100% 0 0;
  border-radius: 50%;
  overflow: hidden;
  border: 7px solid white;
  box-shadow: 0px 3px 6px #00000029;
}

.Payments_circle__3QBkv img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Payments_peopleDiv__29j4i {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

/* Inventory */

.Payments_tableDiv__2jyMy {
  background-color: white;
  margin-left: -1%;
  margin-top: 1.5%;
  padding: 1.5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Payments_searchBar__3YXrx {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
}

.Payments_searchText__oECF4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Payments_dropDownDiv__1LYmC {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 40%;
}

.Payments_searchBarDiv__3KRUz {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.Payments_searchAndDrop__2VvYj {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Payments_buttonAndFilter__11C5J {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media (min-width: 1441px) and (max-width: 2560px) {
  .Payments_searchAndDrop__2VvYj {
    width: 50%;
  }
  .Payments_dropDownDiv__1LYmC {
    width: 60%;
  }
  .Payments_buttonAndFilter__11C5J {
    width: 30%;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .Payments_searchAndDrop__2VvYj {
    width: 50%;
  }
  .Payments_dropDownDiv__1LYmC {
    width: 50%;
  }
  .Payments_buttonAndFilter__11C5J {
    width: 30%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .Payments_searchAndDrop__2VvYj {
    width: 70%;
  }
  .Payments_dropDownDiv__1LYmC {
    width: 60%;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .Payments_searchAndDrop__2VvYj {
    width: 50%;
  }
  .Payments_dropDownDiv__1LYmC {
    width: 50%;
  }
  .Payments_buttonAndFilter__11C5J {
    width: 65%;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .Payments_searchAndDrop__2VvYj {
    width: 70%;
  }
  .Payments_dropDownDiv__1LYmC {
    width: 80%;
  }
  .Payments_buttonAndFilter__11C5J {
    width: 65%;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .Payments_searchAndDrop__2VvYj {
    width: 70%;
  }
  .Payments_dropDownDiv__1LYmC {
    width: 80%;
  }
  .Payments_buttonAndFilter__11C5J {
    width: 85%;
  }
}

/* Billing */

.Payments_billingFormDiv__2VhhF {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 12px #00000014;
  margin-left: -1%;
  margin-right: 2%;
  margin-top: 20px;
}

/*Account*/

.Payments_paginationDiv__EdNsi {
  margin-top: 2%;
  /* display: flex; */
  align-items: flex-end;
  justify-content: flex-end;
}
.Payments_paginationDiv__EdNsi {
  margin-top: 2%;
  /* display: flex; */
  align-items: flex-end;
  justify-content: flex-end;
}

/*Account2 */

.Payments_acc2mainDiv__2ECPP {
  background-color: white;
  width: 100%;
  height: 55vh;
  display: flex;
  justify-content: center;
  margin-left: -1%;
}

.Payments_spanDiv__1a4zp {
  text-align: center;
  font: normal normal normal 25px/33px Segoe UI;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  margin-top: 10%;
}

/*Settings*/

.Payments_settingsFormDiv__2Kxfa {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 12px #00000014;
}

/* Modules */

.Payments_moduleDiv__O5maw {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: -2%;
}

.Payments_table1div__27KPt {
  width: 22%;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.Payments_table2div__3vp-u {
  width: 73%;
}

/* Teams */

.Payments_teamDiv__2KyHn {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: 2%;
}

.Payments_cardDiv__3akEy {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.Payments_circleCard__3ro0R {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Payments_containerDiv__1vQq1 {
  width: 100px;
  margin-top: 5%;
}

.Payments_circleDiv__3MQM5 {
  height: auto;
  padding-top: 30%;
  width: 30%;
  border-radius: 50%;
  text-align: center;
  color: white;
  background: #3b86ff;
  position: absolute;
  margin-top: -10%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.Payments_teamTableDiv__AF6Lb {
  width: 100%;
  margin-top: 4%;
}

.Payments_teamButtonDiv__3PSln {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 7%;
  width: 100%;
}

/* VerifyDocument */

.Payments_vdtable2div__mxfv6 {
  width: 73%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Payments_vdsearchBar__3pq3F {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.Payments_vdsearchText__cXBCw {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Payments_vddropDownDiv__2LMx7 {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.Payments_vdsearchBarDiv__1qCui {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.Payments_vdsearchAndDrop__2Hh0b {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.Payments_vdbuttonAndFilter__39SIX {
  display: flex;
  width: 30%;
  flex-direction: row;
  justify-content: flex-end;
}

.Payments_vdpaginationDiv__rZq_d {
  margin-top: 2%;
  /* display: flex; */
  align-items: flex-end;
  justify-content: flex-end;
}
.Payments_modalContainer__2o63g {
  max-height: 26vw;
  overflow-x: hidden;
}
.Payments_selected__36oB8 {
  font-weight: bold !important;
}
.Payments_paginationDiv__EdNsi {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* margin-right: 6%; */
}

.Payments_vdinnerDiv__3jr5t {
  margin: 2% 0;
  background-color: #f0f2f8;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.Payments_vdmodalContainer__3Mlz9 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 26vw;
  overflow-x: hidden;
}
.Payments_profile__1ypEk {
  margin-left: 20px;
  margin-bottom: 0;
  position: relative;
  margin-bottom: 0;
  position: relative;
  background: black;
  /* padding: 8px 18px; */
  border-radius: 2px;
  width: 92px;
  height: 34px;
}
.Payments_avatar__1oKzn {
  height: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0 4px;
  background-color: transparent;
}
Avatar {
  margin: auto;
}
.Payments_avatarName__so15S {
  color: #646777 !important;
  margin-left: 10px;
  display: block;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  margin-top: auto;
  margin-bottom: auto;
}
.Payments_avatar__1oKzn svg {
  margin: auto;
  height: 33px;
  width: 27px;
  fill: #b1c3c8;
  margin-left: 14px;
}
.Payments_topBarBack__2gWPg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;
}
.Payments_topBarMenuWrap__1WS0Z {
  right: 0px;
  z-index: 101;
  position: absolute;
  width: 100%;
  width: 217px;
  box-shadow: 1px 1px 2px 0px black;
  /* min-width: 210px; */
}
.Payments_topBarMenu__Wlnzo {
  left: 0px !important;
  background: white;
  width: 100%;
  border-radius: 0;
  border: none;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;
  padding: 8px 0;
}
.Payments_hr__8czzW {
  margin: auto;
  margin-left: 6px;
  border-right: 2px solid rgb(239 239 239);
  display: block;
  /* width: 100%; */
  height: 20px;
  margin-right: -2px;
}
.Payments_tobBarLinik__16rmW {
  color: #707070;
  display: flex;
  padding: 9px 4px;
  transition: all 0.3s;
  height: 32px;
  width: 100%;
  position: relative;
  cursor: pointer;
  /* text-decoration: none; */
}
.Payments_tobBarLinik__16rmW:hover {
  background-color: #fafbfe;
  color: #707070;
}
.Payments_tobBarLinik__16rmW:before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  background: #4ce1b6;
  opacity: 0;
  transition: all 0.3s;
}
.Payments_noHover__vp2fP:before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 0px !important;
  background: #fafbfe !important;
  opacity: 0;
  transition: all 0.3s;
}
button:focus {
  outline: none !important;
}
.Payments_tobBarLinik__16rmW:hover:before {
  opacity: 1;
}
.Payments_tobBarLinkTitle__2eSeG {
  color: #646777;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
}
.Payments_tobBarLinkIcon__18wjg {
  color: #dddddd;
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;
}
.Payments_topBarCollapse__3X40w {
  position: relative;
}
.Payments_topBarCollapseLng__1xENn {
  min-width: 70px;
  display: block;
}
.Payments_topBarCollapseLng__1xENn {
  color: #646777;
}
.Payments_tobBarButton__1eGkf {
  font-size: 18px;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;
  padding: 0 4px;
  width: 100%;
}
.Payments_tobBarButton__1eGkf {
  padding: 0 4px;
  width: 100%;
}
.Payments_topBarLngBtnTitle__3e0yD:not(:last-child) {
  margin-right: 5px;
}
.Payments_topBarLngBtnTitle__3e0yD:not(:last-child) {
  margin-right: 5px;
  color: #646777;
  display: flex;
  font-size: 11px;
  align-items: center;
  margin: auto 0;
}
.Payments_topBarLngBtnTitle__3e0yD img {
  margin-right: 4px;
  height: 11px;
  width: 16px;
}
.Payments_tobBarButton__1eGkf svg {
  margin: auto;
  height: 18px;
  width: 18px;
  fill: #b1c3c8;
  margin-left: 8px;
}

.Payments_tobBarContentCollapse__1Od-u {
  background: white;
  right: 0px;
}
.Payments_tobBarContentCollapse__1Od-u {
  /* width: 270px; */
  position: absolute;
  bottom: 20px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);
  z-index: 101;
}
.Payments_tobBarClsContentLgn__35wYn {
  width: 72px;
  padding: 10px 0;
  bottom: 0;
  /* width: 100%; */
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.Payments_topBarLngBtn__1-yMN {
  text-align: left;
  padding: 4px 15px;
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  transition: 0.3s;
  font-family: sans-serif;
  font-size: 100%;
}
.Payments_link__3Buay {
  text-decoration: none;
  color: #43425d;
}
@media (min-width: 1441px) and (max-width: 2560px) {
  .Payments_vdsearchAndDrop__2Hh0b {
    width: 55%;
  }
  .Payments_vddropDownDiv__2LMx7 {
    width: 60%;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .Payments_vdsearchAndDrop__2Hh0b {
    width: 100%;
  }
  .Payments_vddropDownDiv__2LMx7 {
    width: 50%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .Payments_vdsearchAndDrop__2Hh0b {
    width: 70%;
  }
  .Payments_vddropDownDiv__2LMx7 {
    width: 60%;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .Payments_vdsearchAndDrop__2Hh0b {
    width: 70%;
  }
  .Payments_vddropDownDiv__2LMx7 {
    width: 60%;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .Payments_vdsearchAndDrop__2Hh0b {
    width: 70%;
  }
  .Payments_vddropDownDiv__2LMx7 {
    width: 80%;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .Payments_vdsearchAndDrop__2Hh0b {
    width: 70%;
  }
  .Payments_vddropDownDiv__2LMx7 {
    width: 80%;
  }
}

/* Security */

.Payments_secMainDiv__cnyEr {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: -1%;
  padding: 0.5%;
}

.Payments_secHeader__3SK4y {
  width: 100%;
  height: 10%;
  margin-bottom: 3%;
  margin-top: 2%;
}

.Payments_secTitle__1DQtM {
  margin-left: 3%;
  color: #707070;
  font-size: 22px;
  font: Source Sans Pro;
}

.Payments_secHeader2__3dtzs {
  background-color: #f5f6fa;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.Payments_secTitle2__axu8f {
  color: #43425d;
  font-size: 22px;
  font: Semibold 22px/25px Source Sans Pro;
  font-weight: 500;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}

.Payments_cellDiv__3XGe9 {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 6%;
  border-bottom: 0.5px solid #bbbbbb;
}

.Payments_innerCellDiv__1pjVU {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0%;
  margin-left: 5%;
}

.Visualise_main__1s90G {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Visualise_title__3w-H6 {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #43425d;
}

.Visualise_tabsDiv__1T57Z {
  margin-left: 2%;
  margin-top: 2%;
}

.Visualise_appbar__3gcJ5 {
  width: 100%;
  background-color: #4457c2;
  color: #fff;
 
}

.Visualise_cards__30AO2{
  width: 50%;
  margin: 5px
}

@media only screen and (max-width:800px){
  .Visualise_appbar__3gcJ5{
    max-width: 300px;
  }

  .Visualise_cards__30AO2{
    width: 100%;
  }
}

/*Organisation */

.Visualise_organisationDiv__1YNCt {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: -1%;
}

.Visualise_organisationFormDiv__33Jcg {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 12px #00000014;
}

.Visualise_formRowDiv__3oAJ4 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 3%;
  margin-bottom: 4%;
}
.Visualise_collapsing__2XwyN {
  right: 0px !important;
}

.Visualise_organisationProfileDiv__E0lWs {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Visualise_buttonAndFilterRole__2QtDc {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-evenly;
}

.Visualise_container__3r8fe {
  width: 250px;
  margin-top: 5%;
}

.Visualise_circle__3JiZj {
  position: relative;
  width: 100%;
  height: 0;
  padding: 100% 0 0;
  border-radius: 50%;
  overflow: hidden;
  border: 7px solid white;
  box-shadow: 0px 3px 6px #00000029;
}

.Visualise_circle__3JiZj img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Visualise_peopleDiv__2IyUV {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

/* Inventory */

.Visualise_tableDiv__1zDY4 {
  background-color: white;
  margin-left: -1%;
  margin-top: 1.5%;
  padding: 1.5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Visualise_searchBar__PuIpZ {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
}

.Visualise_searchText__1jlGp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Visualise_dropDownDiv__2LlDA {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 40%;
}

.Visualise_searchBarDiv__1ikU2 {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.Visualise_searchAndDrop__3B7f- {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.Visualise_buttonAndFilter__1g-bV {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media (min-width: 1441px) and (max-width: 2560px) {
  .Visualise_searchAndDrop__3B7f- {
    width: 50%;
  }
  .Visualise_dropDownDiv__2LlDA {
    width: 60%;
  }
  .Visualise_buttonAndFilter__1g-bV {
    width: 30%;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .Visualise_searchAndDrop__3B7f- {
    width: 50%;
  }
  .Visualise_dropDownDiv__2LlDA {
    width: 50%;
  }
  .Visualise_buttonAndFilter__1g-bV {
    width: 30%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .Visualise_searchAndDrop__3B7f- {
    width: 70%;
  }
  .Visualise_dropDownDiv__2LlDA {
    width: 60%;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .Visualise_searchAndDrop__3B7f- {
    width: 50%;
  }
  .Visualise_dropDownDiv__2LlDA {
    width: 50%;
  }
  .Visualise_buttonAndFilter__1g-bV {
    width: 65%;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .Visualise_searchAndDrop__3B7f- {
    width: 70%;
  }
  .Visualise_dropDownDiv__2LlDA {
    width: 80%;
  }
  .Visualise_buttonAndFilter__1g-bV {
    width: 65%;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .Visualise_searchAndDrop__3B7f- {
    width: 70%;
  }
  .Visualise_dropDownDiv__2LlDA {
    width: 80%;
  }
  .Visualise_buttonAndFilter__1g-bV {
    width: 85%;
  }
}

/* Billing */

.Visualise_billingFormDiv__3CtQN {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 12px #00000014;
  margin-left: -1%;
}

/*Account*/

.Visualise_paginationDiv__2YL0M {
  margin-top: 2%;
  /* display: flex; */
  align-items: flex-end;
  justify-content: flex-end;
}
.Visualise_paginationDiv__2YL0M {
  margin-top: 2%;
  /* display: flex; */
  align-items: flex-end;
  justify-content: flex-end;
}

/*Account2 */

.Visualise_acc2mainDiv__ASgDk {
  background-color: white;
  width: 100%;
  height: 55vh;
  display: flex;
  justify-content: center;
  margin-left: -1%;
}

.Visualise_spanDiv__1_g1V {
  text-align: center;
  font: normal normal normal 25px/33px Segoe UI;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  margin-top: 10%;
}

/*Settings*/

.Visualise_settingsFormDiv__hRDq6 {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 3px 12px #00000014;
}

/* Modules */

.Visualise_moduleDiv__1bIGo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: -2%;
}

.Visualise_table1div__2wtpi {
  width: 22%;
  display: flex;
  flex-direction: column;
}

.Visualise_table2div__3Ut_q {
  width: 73%;
}

/* Teams */

.Visualise_teamDiv__hX1yM {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: 2%;
}

.Visualise_cardDiv__3MjhK {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.Visualise_circleCard__73Gge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Visualise_containerDiv__17hvA {
  width: 100px;
  margin-top: 5%;
}

.Visualise_circleDiv__3_qqk {
  height: auto;
  padding-top: 30%;
  width: 30%;
  border-radius: 50%;
  text-align: center;
  color: white;
  background: #3b86ff;
  position: absolute;
  margin-top: -10%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.Visualise_teamTableDiv__1wvNx {
  width: 100%;
  margin-top: 4%;
}

.Visualise_teamButtonDiv__2p5VO {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-top: 7%;
  width: 100%;
}

/* VerifyDocument */

.Visualise_vdtable2div__3wPrj {
  width: 73%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Visualise_vdsearchBar__3AvZF {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.Visualise_vdsearchText__36a39 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Visualise_vddropDownDiv__3hCmC {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.Visualise_vdsearchBarDiv__3NRVR {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.Visualise_vdsearchAndDrop__1Q_Ft {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.Visualise_vdbuttonAndFilter__YsA5S {
  display: flex;
  width: 30%;
  flex-direction: row;
  justify-content: flex-end;
}

.Visualise_vdpaginationDiv__18EPQ {
  margin-top: 2%;
  /* display: flex; */
  align-items: flex-end;
  justify-content: flex-end;
}
.Visualise_modalContainer__28WAg {
  max-height: 26vw;
  overflow-x: hidden;
}
.Visualise_selected__3E-WM {
  font-weight: bold !important;
}
.Visualise_paginationDiv__2YL0M {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  /* margin-right: 6%; */
}

.Visualise_vdinnerDiv__VQ-kA {
  margin: 2% 0;
  background-color: #f0f2f8;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.Visualise_vdmodalContainer__1aZcf {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-height: 26vw;
  overflow-x: hidden;
}
.Visualise_profile__3lzbo {
  margin-left: 20px;
  margin-bottom: 0;
  position: relative;
  margin-bottom: 0;
  position: relative;
  background: black;
  /* padding: 8px 18px; */
  border-radius: 2px;
  width: 92px;
  height: 34px;
}
.Visualise_avatar__1EEN4 {
  height: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  border-radius: 0;
  border: none;
  transition: all 0.3s;
  box-shadow: none;
  padding: 0 4px;
  background-color: transparent;
}
Avatar {
  margin: auto;
}
.Visualise_avatarName__3hXmw {
  color: #646777 !important;
  margin-left: 10px;
  display: block;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  margin-top: auto;
  margin-bottom: auto;
}
.Visualise_avatar__1EEN4 svg {
  margin: auto;
  height: 33px;
  width: 27px;
  fill: #b1c3c8;
  margin-left: 14px;
}
.Visualise_topBarBack__1r_r_ {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;
}
.Visualise_topBarMenuWrap__1VvUt {
  right: 0px;
  z-index: 101;
  position: absolute;
  width: 100%;
  width: 217px;
  box-shadow: 1px 1px 2px 0px black;
  /* min-width: 210px; */
}
.Visualise_topBarMenu__1reGo {
  left: 0px !important;
  background: white;
  width: 100%;
  border-radius: 0;
  border: none;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;
  padding: 8px 0;
}
.Visualise_hr__27vzD {
  margin: auto;
  margin-left: 6px;
  border-right: 2px solid rgb(239 239 239);
  display: block;
  /* width: 100%; */
  height: 20px;
  margin-right: -2px;
}
.Visualise_tobBarLinik__2u2Sp {
  color: #707070;
  display: flex;
  padding: 9px 4px;
  transition: all 0.3s;
  height: 32px;
  width: 100%;
  position: relative;
  cursor: pointer;
  /* text-decoration: none; */
}
.Visualise_tobBarLinik__2u2Sp:hover {
  background-color: #fafbfe;
  color: #707070;
}
.Visualise_tobBarLinik__2u2Sp:before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  background: #4ce1b6;
  opacity: 0;
  transition: all 0.3s;
}
.Visualise_noHover__2XEiw:before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 0px !important;
  background: #fafbfe !important;
  opacity: 0;
  transition: all 0.3s;
}
button:focus {
  outline: none !important;
}
.Visualise_tobBarLinik__2u2Sp:hover:before {
  opacity: 1;
}
.Visualise_tobBarLinkTitle__1lzmr {
  color: #646777;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
}
.Visualise_tobBarLinkIcon__16vD7 {
  color: #dddddd;
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;
}
.Visualise_topBarCollapse__2x-NJ {
  position: relative;
}
.Visualise_topBarCollapseLng__3q_CJ {
  min-width: 70px;
  display: block;
}
.Visualise_topBarCollapseLng__3q_CJ {
  color: #646777;
}
.Visualise_tobBarButton__3HHhg {
  font-size: 18px;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;
  padding: 0 4px;
  width: 100%;
}
.Visualise_tobBarButton__3HHhg {
  padding: 0 4px;
  width: 100%;
}
.Visualise_topBarLngBtnTitle__1aCsW:not(:last-child) {
  margin-right: 5px;
}
.Visualise_topBarLngBtnTitle__1aCsW:not(:last-child) {
  margin-right: 5px;
  color: #646777;
  display: flex;
  font-size: 11px;
  align-items: center;
  margin: auto 0;
}
.Visualise_topBarLngBtnTitle__1aCsW img {
  margin-right: 4px;
  height: 11px;
  width: 16px;
}
.Visualise_tobBarButton__3HHhg svg {
  margin: auto;
  height: 18px;
  width: 18px;
  fill: #b1c3c8;
  margin-left: 8px;
}

.Visualise_tobBarContentCollapse__1vYdu {
  background: white;
  right: 0px;
}
.Visualise_tobBarContentCollapse__1vYdu {
  /* width: 270px; */
  position: absolute;
  bottom: 20px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);
  z-index: 101;
}
.Visualise_tobBarClsContentLgn__2Xde1 {
  width: 72px;
  padding: 10px 0;
  bottom: 0;
  /* width: 100%; */
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.Visualise_topBarLngBtn__INknL {
  text-align: left;
  padding: 4px 15px;
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  transition: 0.3s;
  font-family: sans-serif;
  font-size: 100%;
}
.Visualise_link__PlR__ {
  text-decoration: none;
  color: #43425d;
}
@media (min-width: 1441px) and (max-width: 2560px) {
  .Visualise_vdsearchAndDrop__1Q_Ft {
    width: 55%;
  }
  .Visualise_vddropDownDiv__3hCmC {
    width: 60%;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .Visualise_vdsearchAndDrop__1Q_Ft {
    width: 100%;
  }
  .Visualise_vddropDownDiv__3hCmC {
    width: 50%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .Visualise_vdsearchAndDrop__1Q_Ft {
    width: 70%;
  }
  .Visualise_vddropDownDiv__3hCmC {
    width: 60%;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .Visualise_vdsearchAndDrop__1Q_Ft {
    width: 70%;
  }
  .Visualise_vddropDownDiv__3hCmC {
    width: 60%;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .Visualise_vdsearchAndDrop__1Q_Ft {
    width: 70%;
  }
  .Visualise_vddropDownDiv__3hCmC {
    width: 80%;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .Visualise_vdsearchAndDrop__1Q_Ft {
    width: 70%;
  }
  .Visualise_vddropDownDiv__3hCmC {
    width: 80%;
  }
}

/* Security */

.Visualise_secMainDiv__gbkUs {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: -1%;
  padding: 0.5%;
}

.Visualise_secHeader__zeoZ0 {
  width: 100%;
  height: 10%;
  margin-bottom: 3%;
  margin-top: 2%;
}

.Visualise_secTitle__2cm6L {
  margin-left: 3%;
  color: #707070;
  font-size: 22px;
  font: Source Sans Pro;
}

.Visualise_secHeader2__3hU9A {
  background-color: #f5f6fa;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.Visualise_secTitle2__OxYIS {
  color: #43425d;
  font-size: 22px;
  font: Semibold 22px/25px Source Sans Pro;
  font-weight: 500;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}

.Visualise_cellDiv__2GA-O {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: 6%;
  border-bottom: 0.5px solid #bbbbbb;
}

.Visualise_innerCellDiv__a7OaY {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0%;
  margin-left: 5%;
}

@media screen and (max-width: 550px) {
  .Visualise_main__1s90G {
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
    padding: 0%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.payments_main__1TTn5 {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 1%;
}
.payments_title__KGViC {
  margin-top: 3%;
  margin-left: 5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  font: Regular Source Sans Pro;
  color: #43425d;
}

.payments_grid__2AZZK {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1;
  margin-left: 3%;
}

@media screen and (max-width: 550px) {
  .payments_main__1TTn5 {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 0;
  }
  .payments_title__KGViC {
    margin-top: 1%;
    margin-left: 1%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    font: Regular Source Sans Pro;
    color: #43425d;
  }

  .payments_grid__2AZZK {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 1;
    margin-left: 0;
  }
}

.JobCreationCard_card__1rkNK {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
    box-shadow: 0px 2px 6px #0000000A;
    border-radius: 4px;
    margin-right: 2%;
}

.JobCreationCard_detailsDiv__dCfXi {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    font-weight: bold;
    color: #4D4F5C;
    margin-left: 2%;
    font: Semibold 22px/28px Source Sans Pro;
    margin-top: 0px;
    flex-wrap: nowrap;
}
.Card_card__w3qeZ {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Card_title__2xNoo {
  font-weight: 100;
  color: #9695b5;
  /* opacity: 50%; */
}

.Card_details__16bdo {
  height: 0.5;
  display: flex;
  padding-top: 10%;
  flex-direction: row;
  justify-content: space-between;
}

.Card_stats__MRf-R {
  font-size: x-large;
  font-weight: bolder;
  color: #4d4f5c;
  opacity: 1;
  font: Bold Source Sans Pro;
}

.Card_image__3YEgo {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.taxation_main__3odVp {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 1%;
}
.taxation_title__37ZXj {
  margin-top: 3%;
  margin-left: 5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  font: Regular Source Sans Pro;
  color: #43425d;
}

.taxation_grid__1pGZW {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1;
  margin-left: 3%;
}

@media screen and (max-width: 550px) {
  .taxation_main__3odVp {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 0;
  }
  .taxation_title__37ZXj {
    margin-top: 1%;
    margin-left: 1%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    font: Regular Source Sans Pro;
    color: #43425d;
  }

  .taxation_grid__1pGZW {
    width: 88%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex: 1 1;
    margin-left: 0;
  }
}

.Survey_main__3_qdh {
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Survey_title__1apD5 {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425d;
}

.Survey_tabsDiv__3bSfg {
    margin-left: 2%;
    margin-top: 2%;
}


/*Organisation */

.Survey_organisationDiv__1g4-r {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: -1%;
}

.Survey_organisationFormDiv__3poBA {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 3px 12px #00000014;
}

.Survey_formRowDiv__2muo7 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 3%;
    margin-bottom: 4%;
}
.Survey_collapsing__VYGkp{
    right: 0px !important;
}

.Survey_organisationProfileDiv__2sg7- {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Survey_buttonAndFilterRole__uNl2U {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
}


.Survey_container__1PjaP {
    width: 250px;
    margin-top: 5%;
}

.Survey_circle__tVyWB {
    position: relative;
    width: 100%;
    height: 0;
    padding: 100% 0 0;
    border-radius: 50%;
    overflow: hidden;
    border: 7px solid white;
    box-shadow: 0px 3px 6px #00000029;
}

.Survey_circle__tVyWB img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Survey_peopleDiv__3FPJo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}


/* Inventory */

.Survey_tableDiv__3Bxvi {
    background-color: white;
    margin-left: -1%;
    margin-top: 1.5%;
    padding: 1.5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Survey_searchBar__3N2_H {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
}

.Survey_searchText__2Z7ea {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Survey_dropDownDiv__15STU {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 40%;
}

.Survey_searchBarDiv__1fJh9 {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.Survey_searchAndDrop__3Xzbt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.Survey_buttonAndFilter__3aBI3 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .Survey_searchAndDrop__3Xzbt {
        width: 50%;
    }
    .Survey_dropDownDiv__15STU {
        width: 60%;
    }
    .Survey_buttonAndFilter__3aBI3 {
        width: 30%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .Survey_searchAndDrop__3Xzbt {
        width: 50%;
    }
    .Survey_dropDownDiv__15STU {
        width: 50%;
    }
    .Survey_buttonAndFilter__3aBI3 {
        width: 30%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .Survey_searchAndDrop__3Xzbt {
        width: 70%;
    }
    .Survey_dropDownDiv__15STU {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .Survey_searchAndDrop__3Xzbt {
        width: 50%;
    }
    .Survey_dropDownDiv__15STU {
        width: 50%;
    }
    .Survey_buttonAndFilter__3aBI3 {
        width: 65%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .Survey_searchAndDrop__3Xzbt {
        width: 70%;
    }
    .Survey_dropDownDiv__15STU {
        width: 80%;
    }
    .Survey_buttonAndFilter__3aBI3 {
        width: 65%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .Survey_searchAndDrop__3Xzbt {
        width: 70%;
    }
    .Survey_dropDownDiv__15STU {
        width: 80%;
    }
    .Survey_buttonAndFilter__3aBI3 {
        width: 85%;
    }
}


/* Billing */

.Survey_billingFormDiv__1hgk- {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 3px 12px #00000014;
    margin-left: -1%;
}


/*Account*/

.Survey_paginationDiv__B4-LM {
    margin-top: 2%;
    /* display: flex; */
    align-items: flex-end;
    justify-content: flex-end;
}
.Survey_paginationDiv__B4-LM {
    margin-top: 2%;
    /* display: flex; */
    align-items: flex-end;
    justify-content: flex-end;
}

/*Account2 */

.Survey_acc2mainDiv__c6CxB {
    background-color: white;
    width: 100%;
    height: 55vh;
    display: flex;
    justify-content: center;
    margin-left: -1%;
}

.Survey_spanDiv__1bouS {
    text-align: center;
    font: normal normal normal 25px/33px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-top: 10%;
}


/*Settings*/

.Survey_settingsFormDiv__17G5O {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 3px 12px #00000014;
}


/* Modules */

.Survey_moduleDiv__3kV8z {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: -2%;
}

.Survey_table1div__14n7r {
    width: 22%;
    display: flex;
    flex-direction: column;
}

.Survey_table2div__2G5EZ {
    width: 73%;
}


/* Teams */

.Survey_teamDiv__1gOZ4 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 2%;
}

.Survey_cardDiv__2FLok {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.Survey_circleCard__11fLZ {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Survey_containerDiv__2Mrd2 {
    width: 100px;
    margin-top: 5%;
}

.Survey_circleDiv__1PsF9 {
    height: auto;
    padding-top: 30%;
    width: 30%;
    border-radius: 50%;
    text-align: center;
    color: white;
    background: #3b86ff;
    position: absolute;
    margin-top: -10%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.Survey_teamTableDiv__AvfZ8 {
    width: 100%;
    margin-top: 4%;
}

.Survey_teamButtonDiv__1mILc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 7%;
    width: 100%;
}


/* VerifyDocument */

.Survey_vdtable2div__1Ge_p {
    width: 73%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Survey_vdsearchBar__xUKwC {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.Survey_vdsearchText__36JFJ {
    display: flex;
    justify-content: center;
    align-items: center;
}

.Survey_vddropDownDiv__1xsud {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.Survey_vdsearchBarDiv__3l8Lo {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.Survey_vdsearchAndDrop__1_KsU {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.Survey_vdbuttonAndFilter__18wfW {
    display: flex;
    width: 30%;
    flex-direction: row;
    justify-content: flex-end;
}

.Survey_vdpaginationDiv__3tMPa {
    margin-top: 2%;
    /* display: flex; */
    align-items: flex-end;
    justify-content: flex-end;
}
.Survey_modalContainer__3yL3Y {
    max-height: 26vw;
    overflow-x: hidden;
}
.Survey_selected__bpjng{
    font-weight: bold !important;
}
.Survey_paginationDiv__B4-LM {
    margin-top: 2%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* margin-right: 6%; */
}

.Survey_vdinnerDiv__2-Lyx {
    margin: 2% 0;
    background-color: #F0F2F8;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.Survey_vdmodalContainer__2Bm2D {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-height: 26vw;
    overflow-x: hidden;
}
.Survey_profile__29yaf{
    margin-left: 20px;
    margin-bottom: 0;
    position: relative;
    margin-bottom: 0;
    position: relative;
    background: black;
    /* padding: 8px 18px; */
    border-radius: 2px;
    width: 92px;
    height: 34px;
}
.Survey_avatar__886eY{
    height: 100%;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 0;
    border: none;
    transition: all 0.3s;
    box-shadow: none;
    padding: 0 4px;
    background-color: transparent;
}
Avatar{
    margin: auto;
}
.Survey_avatarName__27mNl{
    color: #646777 !important;
    margin-left: 10px;
    display: block;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    margin-top: auto;
    margin-bottom: auto;
}
.Survey_avatar__886eY svg{
    margin: auto;
    height: 33px;
    width: 27px;
    fill: #b1c3c8;
    margin-left: 14px;
}
.Survey_topBarBack__3CQRq{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: transparent;
    border: none;
}
.Survey_topBarMenuWrap__2KDfG{
    right: 0px;
    z-index: 101;
    position: absolute;
  width: 100%;
  width: 217px;
  box-shadow: 1px 1px 2px 0px black;
    /* min-width: 210px; */
}
.Survey_topBarMenu__zaye5{
    left: 0px !important;
    background: white;
    width: 100%;
    border-radius: 0;
    border: none;
    padding: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
    padding: 8px 0;
}
.Survey_hr__1rih2{
    margin: auto;
    margin-left: 6px;
    border-right: 2px solid rgb(239 239 239);
    display: block;
    /* width: 100%; */
    height: 20px;
    margin-right: -2px;
}
.Survey_tobBarLinik__3EQyN{
    color: #707070;
    display: flex;
    padding: 9px 4px;
    transition: all 0.3s;
    height: 32px;
    width: 100%;
    position: relative;
    cursor: pointer;
    /* text-decoration: none; */
}
.Survey_tobBarLinik__3EQyN:hover{
    background-color: #fafbfe;
    color: #707070;
}
.Survey_tobBarLinik__3EQyN:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
    background: #4ce1b6;
    opacity: 0;
    transition: all 0.3s;
}
.Survey_noHover__3hCqj:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 0px !important;
    background:#fafbfe !important;
    opacity: 0;
    transition: all 0.3s;
}
button:focus{
    outline: none !important;
}
.Survey_tobBarLinik__3EQyN:hover:before {
    opacity: 1;
}
.Survey_tobBarLinkTitle__2ZQ3O{
    color: #646777;
    margin: 0;
    font-size: 14px;
    line-height: 16px;
}
.Survey_tobBarLinkIcon__29xn4{
    color: #dddddd;
    margin-right: 10px;
    font-size: 13px;
    line-height: 13px;
}
.Survey_topBarCollapse__LYPNE{
    position: relative;
}
.Survey_topBarCollapseLng__1692B{
    min-width: 70px;
    display: block;

}
.Survey_topBarCollapseLng__1692B{
   
    color: #646777;
}
.Survey_tobBarButton__2wFqU{
    font-size: 18px;
    height: 100%;
    padding: 0 10px;
    cursor: pointer;
    position: relative;
    display: flex;
    border: none;
    background: transparent;
    transition: all 0.3s;
    padding: 0 4px;
    width: 100%;

}
.Survey_tobBarButton__2wFqU{
    padding: 0 4px;
    width: 100%;
}
.Survey_topBarLngBtnTitle__1bzGV:not(:last-child) {
    margin-right: 5px;
}
.Survey_topBarLngBtnTitle__1bzGV:not(:last-child) {
    margin-right: 5px;
    color: #646777;
    display: flex;
    font-size: 11px;
    align-items: center;
    margin: auto 0;
}
.Survey_topBarLngBtnTitle__1bzGV img {
    margin-right: 4px;
    height: 11px;
    width: 16px
}
.Survey_tobBarButton__2wFqU svg {
    margin: auto;
    height: 18px;
    width: 18px;
    fill: #b1c3c8;
    margin-left: 8px;
}

.Survey_tobBarContentCollapse__3nBoi{
    background: white;
    right: 0px;
}
.Survey_tobBarContentCollapse__3nBoi{
    /* width: 270px; */
    position: absolute;
    bottom: 20px;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);
    z-index: 101;
}
.Survey_tobBarClsContentLgn__Stw2W{
    width: 72px;
    padding: 10px 0;
    bottom: 0;
    /* width: 100%; */
    -webkit-transform: translateY(100%);
            transform: translateY(100%);

}
.Survey_topBarLngBtn__2_ZL2{
    text-align: left;
    padding: 4px 15px;
    width: 100%;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 13px;
    line-height: 16px;
    transition: 0.3s;
    font-family: sans-serif;
    font-size: 100%;
}
.Survey_link__3BnLM {
    text-decoration: none;
    color: #43425D;
}
@media (min-width: 1441px) and (max-width: 2560px) {
    .Survey_vdsearchAndDrop__1_KsU {
        width: 55%;
    }
    .Survey_vddropDownDiv__1xsud {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .Survey_vdsearchAndDrop__1_KsU {
        width: 100%;
    }
    .Survey_vddropDownDiv__1xsud {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .Survey_vdsearchAndDrop__1_KsU {
        width: 70%;
    }
    .Survey_vddropDownDiv__1xsud {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .Survey_vdsearchAndDrop__1_KsU {
        width: 70%;
    }
    .Survey_vddropDownDiv__1xsud {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .Survey_vdsearchAndDrop__1_KsU {
        width: 70%;
    }
    .Survey_vddropDownDiv__1xsud {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .Survey_vdsearchAndDrop__1_KsU {
        width: 70%;
    }
    .Survey_vddropDownDiv__1xsud {
        width: 80%;
    }
}


/* Security */

.Survey_secMainDiv__26ODI {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-left: -1%;
    padding: 0.5%;
}

.Survey_secHeader__jICab {
    width: 100%;
    height: 10%;
    margin-bottom: 3%;
    margin-top: 2%;
}

.Survey_secTitle__3GvhA {
    margin-left: 3%;
    color: #707070;
    font-size: 22px;
    font: Source Sans Pro;
}

.Survey_secHeader2__2Fyqf {
    background-color: #F5F6FA;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.Survey_secTitle2__Uua83 {
    color: #43425D;
    font-size: 22px;
    font: Semibold 22px/25px Source Sans Pro;
    font-weight: 500;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
}

.Survey_cellDiv__Omoo8 {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 6%;
    border-bottom: 0.5px solid #BBBBBB;
}

.Survey_innerCellDiv__1Sm2G {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0%;
    margin-left: 5%;
}


.SurveyReport_main__3CjM8 {
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.SurveyReport_title__3iMeO {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425d;
}

.SurveyReport_tabsDiv__1Bl9J {
    margin-left: 2%;
    margin-top: 2%;
}


/*Organisation */

.SurveyReport_organisationDiv__sxCrA {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: -1%;
}

.SurveyReport_organisationFormDiv__3tS2h {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 3px 12px #00000014;
}

.SurveyReport_formRowDiv__rYLAL {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 3%;
    margin-bottom: 4%;
}
.SurveyReport_collapsing__21_sh{
    right: 0px !important;
}

.SurveyReport_organisationProfileDiv__ZhA8Q {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.SurveyReport_buttonAndFilterRole__c8VwQ {
    display: flex;
    width: 50%;
    flex-direction: row;
    justify-content: space-evenly;
}


.SurveyReport_container___XXM1 {
    width: 250px;
    margin-top: 5%;
}

.SurveyReport_circle__2o19O {
    position: relative;
    width: 100%;
    height: 0;
    padding: 100% 0 0;
    border-radius: 50%;
    overflow: hidden;
    border: 7px solid white;
    box-shadow: 0px 3px 6px #00000029;
}

.SurveyReport_circle__2o19O img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.SurveyReport_peopleDiv__1JA89 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}


/* Inventory */

.SurveyReport_tableDiv__1pr7- {
    background-color: white;
    margin-left: -1%;
    margin-top: 1.5%;
    padding: 1.5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.SurveyReport_searchBar__3dN2o {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
}

.SurveyReport_searchText__3TkH7 {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SurveyReport_dropDownDiv__2yY1d {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 40%;
}

.SurveyReport_searchBarDiv__Ap0pJ {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.SurveyReport_searchAndDrop__3CDhH {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.SurveyReport_buttonAndFilter__XJTi4 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .SurveyReport_searchAndDrop__3CDhH {
        width: 50%;
    }
    .SurveyReport_dropDownDiv__2yY1d {
        width: 60%;
    }
    .SurveyReport_buttonAndFilter__XJTi4 {
        width: 30%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .SurveyReport_searchAndDrop__3CDhH {
        width: 50%;
    }
    .SurveyReport_dropDownDiv__2yY1d {
        width: 50%;
    }
    .SurveyReport_buttonAndFilter__XJTi4 {
        width: 30%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .SurveyReport_searchAndDrop__3CDhH {
        width: 70%;
    }
    .SurveyReport_dropDownDiv__2yY1d {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .SurveyReport_searchAndDrop__3CDhH {
        width: 50%;
    }
    .SurveyReport_dropDownDiv__2yY1d {
        width: 50%;
    }
    .SurveyReport_buttonAndFilter__XJTi4 {
        width: 65%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .SurveyReport_searchAndDrop__3CDhH {
        width: 70%;
    }
    .SurveyReport_dropDownDiv__2yY1d {
        width: 80%;
    }
    .SurveyReport_buttonAndFilter__XJTi4 {
        width: 65%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .SurveyReport_searchAndDrop__3CDhH {
        width: 70%;
    }
    .SurveyReport_dropDownDiv__2yY1d {
        width: 80%;
    }
    .SurveyReport_buttonAndFilter__XJTi4 {
        width: 85%;
    }
}


/* Billing */

.SurveyReport_billingFormDiv__3vbMv {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 3px 12px #00000014;
    margin-left: -1%;
}


/*Account*/

.SurveyReport_paginationDiv__2RxZY {
    margin-top: 2%;
    /* display: flex; */
    align-items: flex-end;
    justify-content: flex-end;
}
.SurveyReport_paginationDiv__2RxZY {
    margin-top: 2%;
    /* display: flex; */
    align-items: flex-end;
    justify-content: flex-end;
}

/*Account2 */

.SurveyReport_acc2mainDiv__5TeSt {
    background-color: white;
    width: 100%;
    height: 55vh;
    display: flex;
    justify-content: center;
    margin-left: -1%;
}

.SurveyReport_spanDiv__2ODeM {
    text-align: center;
    font: normal normal normal 25px/33px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin-top: 10%;
}


/*Settings*/

.SurveyReport_settingsFormDiv__11ps4 {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    background-color: #ffffff;
    box-shadow: 0px 3px 12px #00000014;
}


/* Modules */

.SurveyReport_moduleDiv__2OA1Y {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: -2%;
}

.SurveyReport_table1div__16cf8 {
    width: 22%;
    display: flex;
    flex-direction: column;
}

.SurveyReport_table2div__2R-my {
    width: 73%;
}


/* Teams */

.SurveyReport_teamDiv__3hjgR {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-top: 2%;
}

.SurveyReport_cardDiv__ywoTN {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.SurveyReport_circleCard__3J_r0 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SurveyReport_containerDiv__3Fe-E {
    width: 100px;
    margin-top: 5%;
}

.SurveyReport_circleDiv__3W-mb {
    height: auto;
    padding-top: 30%;
    width: 30%;
    border-radius: 50%;
    text-align: center;
    color: white;
    background: #3b86ff;
    position: absolute;
    margin-top: -10%;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.SurveyReport_teamTableDiv__3gbv2 {
    width: 100%;
    margin-top: 4%;
}

.SurveyReport_teamButtonDiv__1IlLU {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 7%;
    width: 100%;
}


/* VerifyDocument */

.SurveyReport_vdtable2div__E-tZE {
    width: 73%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.SurveyReport_vdsearchBar__2VJiH {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.SurveyReport_vdsearchText__2OdNw {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SurveyReport_vddropDownDiv__1rp0h {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.SurveyReport_vdsearchBarDiv__Wt0hS {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.SurveyReport_vdsearchAndDrop__2QQKQ {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.SurveyReport_vdbuttonAndFilter__d9L5a {
    display: flex;
    width: 30%;
    flex-direction: row;
    justify-content: flex-end;
}

.SurveyReport_vdpaginationDiv__1RIXc {
    margin-top: 2%;
    /* display: flex; */
    align-items: flex-end;
    justify-content: flex-end;
}
.SurveyReport_modalContainer__3oBGS {
    max-height: 26vw;
    overflow-x: hidden;
}
.SurveyReport_selected__3eVUt{
    font-weight: bold !important;
}
.SurveyReport_paginationDiv__2RxZY {
    margin-top: 2%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* margin-right: 6%; */
}

.SurveyReport_vdinnerDiv__3JbR1 {
    margin: 2% 0;
    background-color: #F0F2F8;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.SurveyReport_vdmodalContainer__3T7Jz {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-height: 26vw;
    overflow-x: hidden;
}
.SurveyReport_profile__3_HUX{
    margin-left: 20px;
    margin-bottom: 0;
    position: relative;
    margin-bottom: 0;
    position: relative;
    background: black;
    /* padding: 8px 18px; */
    border-radius: 2px;
    width: 92px;
    height: 34px;
}
.SurveyReport_avatar__BiB6t{
    height: 100%;
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 0;
    border: none;
    transition: all 0.3s;
    box-shadow: none;
    padding: 0 4px;
    background-color: transparent;
}
Avatar{
    margin: auto;
}
.SurveyReport_avatarName__3r4iJ{
    color: #646777 !important;
    margin-left: 10px;
    display: block;
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    margin-top: auto;
    margin-bottom: auto;
}
.SurveyReport_avatar__BiB6t svg{
    margin: auto;
    height: 33px;
    width: 27px;
    fill: #b1c3c8;
    margin-left: 14px;
}
.SurveyReport_topBarBack__3xHse{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    background: transparent;
    border: none;
}
.SurveyReport_topBarMenuWrap__yfA6b{
    right: 0px;
    z-index: 101;
    position: absolute;
  width: 100%;
  width: 217px;
  box-shadow: 1px 1px 2px 0px black;
    /* min-width: 210px; */
}
.SurveyReport_topBarMenu__FKigg{
    left: 0px !important;
    background: white;
    width: 100%;
    border-radius: 0;
    border: none;
    padding: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
    padding: 8px 0;
}
.SurveyReport_hr__1AWAv{
    margin: auto;
    margin-left: 6px;
    border-right: 2px solid rgb(239 239 239);
    display: block;
    /* width: 100%; */
    height: 20px;
    margin-right: -2px;
}
.SurveyReport_tobBarLinik__2GDVv{
    color: #707070;
    display: flex;
    padding: 9px 4px;
    transition: all 0.3s;
    height: 32px;
    width: 100%;
    position: relative;
    cursor: pointer;
    /* text-decoration: none; */
}
.SurveyReport_tobBarLinik__2GDVv:hover{
    background-color: #fafbfe;
    color: #707070;
}
.SurveyReport_tobBarLinik__2GDVv:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 2px;
    background: #4ce1b6;
    opacity: 0;
    transition: all 0.3s;
}
.SurveyReport_noHover__2jZdq:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    width: 0px !important;
    background:#fafbfe !important;
    opacity: 0;
    transition: all 0.3s;
}
button:focus{
    outline: none !important;
}
.SurveyReport_tobBarLinik__2GDVv:hover:before {
    opacity: 1;
}
.SurveyReport_tobBarLinkTitle__1TV_A{
    color: #646777;
    margin: 0;
    font-size: 14px;
    line-height: 16px;
}
.SurveyReport_tobBarLinkIcon__2q2Dh{
    color: #dddddd;
    margin-right: 10px;
    font-size: 13px;
    line-height: 13px;
}
.SurveyReport_topBarCollapse__2CikC{
    position: relative;
}
.SurveyReport_topBarCollapseLng__3X1Z3{
    min-width: 70px;
    display: block;

}
.SurveyReport_topBarCollapseLng__3X1Z3{
   
    color: #646777;
}
.SurveyReport_tobBarButton__2ZzJ4{
    font-size: 18px;
    height: 100%;
    padding: 0 10px;
    cursor: pointer;
    position: relative;
    display: flex;
    border: none;
    background: transparent;
    transition: all 0.3s;
    padding: 0 4px;
    width: 100%;

}
.SurveyReport_tobBarButton__2ZzJ4{
    padding: 0 4px;
    width: 100%;
}
.SurveyReport_topBarLngBtnTitle__1QrPq:not(:last-child) {
    margin-right: 5px;
}
.SurveyReport_topBarLngBtnTitle__1QrPq:not(:last-child) {
    margin-right: 5px;
    color: #646777;
    display: flex;
    font-size: 11px;
    align-items: center;
    margin: auto 0;
}
.SurveyReport_topBarLngBtnTitle__1QrPq img {
    margin-right: 4px;
    height: 11px;
    width: 16px
}
.SurveyReport_tobBarButton__2ZzJ4 svg {
    margin: auto;
    height: 18px;
    width: 18px;
    fill: #b1c3c8;
    margin-left: 8px;
}

.SurveyReport_tobBarContentCollapse__2Omjc{
    background: white;
    right: 0px;
}
.SurveyReport_tobBarContentCollapse__2Omjc{
    /* width: 270px; */
    position: absolute;
    bottom: 20px;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);
    z-index: 101;
}
.SurveyReport_tobBarClsContentLgn__3IbF7{
    width: 72px;
    padding: 10px 0;
    bottom: 0;
    /* width: 100%; */
    -webkit-transform: translateY(100%);
            transform: translateY(100%);

}
.SurveyReport_topBarLngBtn__2vEvo{
    text-align: left;
    padding: 4px 15px;
    width: 100%;
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 13px;
    line-height: 16px;
    transition: 0.3s;
    font-family:  "Poppins", sans-serif !important;
    font-size: 100%;
}
.SurveyReport_link__2DEzV {
    text-decoration: none;
    color: #43425D;
}
@media (min-width: 1441px) and (max-width: 2560px) {
    .SurveyReport_vdsearchAndDrop__2QQKQ {
        width: 55%;
    }
    .SurveyReport_vddropDownDiv__1rp0h {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .SurveyReport_vdsearchAndDrop__2QQKQ {
        width: 100%;
    }
    .SurveyReport_vddropDownDiv__1rp0h {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .SurveyReport_vdsearchAndDrop__2QQKQ {
        width: 70%;
    }
    .SurveyReport_vddropDownDiv__1rp0h {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .SurveyReport_vdsearchAndDrop__2QQKQ {
        width: 70%;
    }
    .SurveyReport_vddropDownDiv__1rp0h {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .SurveyReport_vdsearchAndDrop__2QQKQ {
        width: 70%;
    }
    .SurveyReport_vddropDownDiv__1rp0h {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .SurveyReport_vdsearchAndDrop__2QQKQ {
        width: 70%;
    }
    .SurveyReport_vddropDownDiv__1rp0h {
        width: 80%;
    }
}


/* Security */

.SurveyReport_secMainDiv__2SMQP {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    margin-left: -1%;
    padding: 0.5%;
}

.SurveyReport_secHeader__28k6t {
    width: 100%;
    height: 10%;
    margin-bottom: 3%;
    margin-top: 2%;
}

.SurveyReport_secTitle__3U5GO {
    margin-left: 3%;
    color: #707070;
    font-size: 22px;
    font: Source Sans Pro;
}

.SurveyReport_secHeader2__rXQZt {
    background-color: #F5F6FA;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}

.SurveyReport_secTitle2__BZTGc {
    color: #43425D;
    font-size: 1.3rem;
    font-weight: 500;
    font: Semibold 22px/25px Source Sans Pro;
    font-weight: 500;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
}

.SurveyReport_cellDiv__nhWsS {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-right: 6%;
    border-bottom: 0.5px solid #BBBBBB;
}

.SurveyReport_innerCellDiv__Fgv5w {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0%;
    margin-left: 5%;
}

.Stepper_main__3Orpj {
    background-color: #F9F9F9;
    width: 100%;
    height: 90vh;
    padding: 2%;
  }
.Stepper_container__QFqWu{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-bottom: 2%;
}
.Stepper_stepperButtons__S0djD {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.Stepper_title__3c373 {
  padding-bottom:3% ;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: xx-large;
}
.Stepper_formDiv__17CUV {
  background-color: white;
  padding-bottom: 2%;
  height: 80%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 85%;
  margin: auto;
}
.Stepper_row1__1xqhC {
  display: flex;
  padding: 2% 0;
  flex-direction: row;
  justify-content: space-around;
}
.Stepper_review__3ovR8{
  display: flex;
  padding: 2% 0;
  flex-direction: row;
  letter-spacing: 0px;
color: #707070;
opacity: 1;
font-size:16px
  /* justify-content: space-around; */
}
.Stepper_lastFormElement__35aEd {
  font-size: xx-large;
  font-weight: bolder;
}
.Stepper_checked__2Wwsi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Stepper_ul__nCP-y{
  list-style: none;
  display: table;
}

.Stepper_li__2FrxO {
  display: table-row;
  line-height: 28px;
}

.Stepper_b__33fc1 {
  display: table-cell;
  padding-right: 3em;
}

@media (min-width: 320px) and (max-width: 375px) {
  .Stepper_label__Mp7_f {
    font-size: xx-small;
  }
  .Stepper_lastFormElement__35aEd {
    padding-left: 20%;
  }
}
@media (min-width: 376px) and (max-width: 768px) {
  .Stepper_label__Mp7_f {
    font-size: x-small;
  }
  .Stepper_lastFormElement__35aEd {
    padding-left: 10%;
  }
}

.OrganisationSamadhanID_main__3C5Do {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.OrganisationSamadhanID_title__MVShS {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 1%;
}

.OrganisationSamadhanID_tableDiv__18gUa {
  background-color: white;
  margin-top: 1.5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.OrganisationSamadhanID_searchBarDiv__2STQ7 {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.OrganisationSamadhanID_download1__Ixk8c {
  text-transform: none !important;
  background-color: rgb(59, 134, 255) !important;
  text-align: center !important;
  white-space: nowrap !important;
  outline: none !important;
  margin-left: 75% !important;
  font-size: smaller !important;
}

.OrganisationSamadhanID_download__30GLC {
  text-transform: none !important;
  background-color: rgb(59, 134, 255) !important;
  text-align: center !important;
  white-space: nowrap !important;
  outline: none !important;
  margin-left: 4% !important;
  font-size: smaller !important;
}

.OrganisationSamadhanID_searchText__2fGDD {
  display: flex;
  justify-content: center;
  align-items: center;
}

.OrganisationSamadhanID_searchAndDrop__Rgmtr {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.OrganisationSamadhanID_buttonDiv__1hxrW {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-evenly;
}

.OrganisationSamadhanID_paginationDiv__37nC4 {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 550px) {
  .OrganisationSamadhanID_main__3C5Do {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }
  .OrganisationSamadhanID_tableDiv__18gUa {
    margin-left: 0%;
    margin-top: 1.5%;
    padding: 2%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .OrganisationSamadhanID_searchBar__2ypSz {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .OrganisationSamadhanID_dropDownDiv__2wpMG {
    width: 25%;
    display: flex;
    margin-left: 20px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
  }
  .OrganisationSamadhanID_buttonAndFilter__3YW8y {
    display: flex;
    margin-right: 20px;
    width: 50%;
    flex-direction: row;
    justify-content: space-between;
  }
}

.UserList_main__1IWbW {
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
}

.UserList_title__3_pJk {
    margin-left: 6%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425D;
}

.UserList_grid__3VuBt {
    margin-left: 5%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1vw;
    grid-row-gap: 0vw;
    margin-right: 5%;
}

.UserList_titleAndSearch__1x3RP {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    padding-right: 5%;
}

.UserList_searchAndButton__1Dddj {
    display: flex;
    width: 50%;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
}

.UserList_searchBar__2OZmH {
    background-color: white;
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.UserList_noRecord__Vx7UI{
    height: 400px;
    width: 100%;
    /* background: green; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.UserList_noRecord__Vx7UI p{
    text-align: center;
    /* width: 150px; */
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* background: red; */
    font-size: xx-large;
    color: #43425d;
}

.UserList_paginationDiv__3EKU3 {
    margin-top: 2%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* margin-right: 6%; */
}
.UserList_card__JvniB{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.UserList_detailsDiv__3MTe8{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.UserList_switchDiv__18TBR{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
@media (min-width: 1025px) and (max-width: 1440px) {
    .UserList_searchAndDrop__lzavt {
        width: 50%;
    }
    .UserList_dropDownDiv__1Z0xN {
        width: 50%;
    }
    .UserList_paginationDiv__3EKU3 {
        margin-top: 2%;
        display: inline-block;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .UserList_grid__3VuBt {
        margin-right: 2%;
    }
}
.UserList_totalCount__1uLbl{
    margin: 6%;
    margin-top: 25px;
    /* width: 50%; */
    float: left;
    text-align: center;
    /* background: red; */
    text-align: right;
    font-weight: 500;
    text-transform: capitalize;
}

@media (min-width: 769px) and (max-width: 1024px) {
    .UserList_searchAndDrop__lzavt {
        width: 70%;
    }
    .UserList_dropDownDiv__1Z0xN {
        width: 60%;
    }
    .UserList_paginationDiv__3EKU3 {
        margin-top: 2%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .UserList_searchAndButton__1Dddj {
        width: 70%;
    }
    .UserList_grid__3VuBt {
        margin-right: 5%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .UserList_searchAndDrop__lzavt {
        width: 70%;
    }
    .UserList_dropDownDiv__1Z0xN {
        width: 60%;
    }
    .UserList_paginationDiv__3EKU3 {
        margin-top: 2%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .UserList_searchAndButton__1Dddj {
        width: 70%;
    }
    .UserList_grid__3VuBt {
        margin-right: 5%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .UserList_searchAndDrop__lzavt {
        width: 70%;
    }
    .UserList_dropDownDiv__1Z0xN {
        width: 80%;
    }
    .UserList_paginationDiv__3EKU3 {
        margin-top: 2%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .UserList_searchAndButton__1Dddj {
        width: 70%;
    }
    .UserList_grid__3VuBt {
        margin-right: 5%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .UserList_searchAndDrop__lzavt {
        width: 70%;
    }
    .UserList_dropDownDiv__1Z0xN {
        width: 80%;
    }
    .UserList_paginationDiv__3EKU3 {
        margin-top: 2%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        display:"inline-block"
    }
    .UserList_totalCount__1uLbl{
        margin: 6%;
        margin-top: 25px;
        /* width: 50%; */
        float: left;
        text-align: center;
        /* background: red; */
        text-align: right;
    }
    .UserList_searchAndButton__1Dddj {
        width: 70%;
    }
    .UserList_grid__3VuBt {
        margin-right: 5%;
    }
}

.UserList_modalContainer__2wMy9 {
    max-height: 20vw;
}
.JobCreation_main__3yVR3 {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.JobCreation_title__2PRK_ {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 1%;
}

.JobCreation_grid__2sFJj {
  margin-top: 2%;
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex: 1 1;
}

.Initialisation_main__3Zq3- {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  margin: 3%;
}

.Initialisation_title__35jSZ {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 1%;
}

.Initialisation_tableDiv__FZOWD {
  background-color: white;
  /* margin-left: 2%; */
  margin-top: 1.5%;
  padding: 1.5%;
  width: 100%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Initialisation_searchBarDiv__1KJCQ {
  /* padding: 1% 0; */
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.Initialisation_searchAndDrop__1XGSk {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
}

.Initialisation_searchBar__m3t8V {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 14px;
}

.Initialisation_download__1EpMN {
  text-transform: none !important;
  background-color: rgb(59, 134, 255) !important;
  text-align: center !important;
  white-space: nowrap !important;
  outline: none !important;
  margin-left: 4% !important;
  font-size: smaller !important;
}

.Initialisation_searchText__3uZus {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Initialisation_dropDownDiv__157sT {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.Initialisation_buttonAndFilter__13uY_ {
  padding: 1%;
  display: flex;
  width: 30%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.Initialisation_paginationDiv__2G-mw {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 550px) {
  .Initialisation_main__3Zq3- {
    background-color: #f9f9f9;
    width: 100vw;
    height: 100%;
    margin: 2%;
  }

  .Initialisation_tableDiv__FZOWD {
    background-color: white;
    /* margin-left: 0%; */
    margin-top: 1.5%;
    padding: 1%;
    width: 95%;
    /* height: 70%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .Initialisation_searchBarDiv__1KJCQ {
    /* padding: 1% 0; */
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .Initialisation_searchAndDrop__1XGSk {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
  }

  .Initialisation_searchBar__m3t8V {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
  }

  .Initialisation_dropDownDiv__157sT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .Initialisation_buttonAndFilter__13uY_ {
    padding: 1%;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .Initialisation_paginationDiv__2G-mw {
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Installation_main__D-9vk {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.Installation_title__2vErd {
  margin-left: 1%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: xx-large;
}

.Installation_tableDiv__1P__R {
  background-color: white;
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  /* height: 70%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Installation_searchBar__3QSNj {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.Installation_searchText__1FKna {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Installation_dropDownDiv__jXB94 {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.Installation_searchBarDiv__1mFOU {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.Installation_searchAndDrop__1k1Dr {
  display: flex;
  flex-direction: row;
  width: 60%;
}

.Installation_buttonAndFilter__3dYxa {
  display: flex;
  width: 22%;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 550px) {
  .Installation_main__D-9vk {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }
  .Installation_tableDiv__1P__R {
    background-color: white;
    margin-left: 0%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .Installation_table__vV7_x {
    height: 50%;
  }
  .Installation_searchBar__3QSNj {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .Installation_dropDownDiv__jXB94 {
    width: 25%;
    display: flex;
    margin-left: 20px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
  }
  .Installation_buttonAndFilter__3dYxa {
    display: flex;
    margin-right: 0px;
    width: 75%;
    flex-direction: row;
    justify-content: space-between;
  }
}

.QC_main__3SZK3 {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.QC_title__12mta {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: xx-large;
}

.QC_tableDiv__Qf7wH {
  background-color: white;
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.QC_searchBar__1LqnT {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.QC_searchText__2jn1V {
  display: flex;
  justify-content: center;
  align-items: center;
}

.QC_dropDownDiv__3iqkg {
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.QC_searchBarDiv__2LLSx {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.QC_searchAndDrop__2S5CL {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30%;
}

.QC_buttonAndFilter__2LgxB {
  display: flex;
  width: 22%;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 550px) {
  .QC_main__3SZK3 {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }
  .QC_tableDiv__Qf7wH {
    background-color: white;
    margin-left: 0%;
    /* margin-top: 1.5%; */
    /* padding: 0 1.5%; */
    /* height: 70%; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .QC_table__3u5Dk {
    height: 50%;
  }
  .QC_searchBar__1LqnT {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .QC_dropDownDiv__3iqkg {
    width: 25%;
    display: flex;
    margin-left: 20px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
  }
  .QC_buttonAndFilter__2LgxB {
    display: flex;
    margin-right: 0px;
    width: 75%;
    flex-direction: row;
    justify-content: space-between;
  }
}

.AddJob_main__37pFF {
    width: 100%;
    height: 100%;
    background-color: #f7f7f5;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.AddJob_title__s_5Bo {
    font-size: x-large;
}

.AddJob_formDiv__2zK2F {
    margin: 2% 0;
    padding: 1%;
    background-color: white;
    width: 50%;
    display: flex;
    flex-direction: column;
}

.AddJob_row1__5BKbk {
    display: flex;
    margin: 2% 0;
    padding: 1%;
    justify-content: space-around;
}

.AddJob_row2__3M7hS {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    margin: 3% 0;
}

.AddJob_buttonDiv__7naRU {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-right: -1%;
}

.AddJob_buttons__2sY9g {
    margin: 2%;
    padding: 3%;
}
.AllProperties_main__13OZN {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  margin: 2%;
}
.AllProperties_width__3sTx8 {
  width: 0px;
}

.AllProperties_title__37ZBW {
  margin-left: 1%;
  margin: 1%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #43425d;
  font-weight: normal;
}

.AllProperties_tableDiv__sCtWs {
  background-color: white;
  margin-top: 2%;
  padding: 2% 1.5% 5% 1.5%;
  width: 96%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.AllProperties_searchBar__JJps4 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.AllProperties_searchText__3ew5h {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AllProperties_dropDownDiv__2m6Td {
  /* width: 25%; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.AllProperties_searchBarDiv__TK65z {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.AllProperties_searchAndDrop__3xwPV {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40%;
}

.AllProperties_buttonContainer__6m7P2 {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.AllProperties_assetAddModal__WGgjc {
  margin-top: 30vh !important;
}

@media screen and (max-width: 550px) {
  .AllProperties_main__13OZN {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    margin: 1%;
  }
  .AllProperties_tableDiv__sCtWs {
    margin-left: 1%;
    margin-top: 1.5%;
    padding: 0 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .AllProperties_dropDownDiv__2m6Td {
    /* width: 25%; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .AllProperties_searchBarDiv__TK65z {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .AllProperties_searchAndDrop__3xwPV {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
  }

  .AllProperties_buttonContainer__6m7P2 {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.CategoryList_main__3J-kb {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.CategoryList_title__3aU42 {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #43425d;
}

.CategoryList_tableDiv__2DEXk {
  background-color: white;
  margin-top: 2%;
  padding: 0 1.5%;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.CategoryList_searchBar__1uWEQ {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.CategoryList_searchText__3Dyt9 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.CategoryList_dropDownDiv__xoGND {
  width: 35%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.CategoryList_searchBarDiv__2d0gd {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.CategoryList_searchAndDrop__2yiE2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 30%;
}

.CategoryList_buttonContainer__1jwVc {
  width: 20%;
  display: flex;
  flex-direction: row;
  /* align-items: flex-end; */
  justify-content: space-between;
}

@media screen and (max-width: 550px) {
  .CategoryList_tableDiv__2DEXk {
    margin-left: 0%;
    margin-top: 1.5%;
    padding: 0 0;
    width: 95vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .CategoryList_dropDownDiv__xoGND {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .CategoryList_searchBarDiv__2d0gd {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .CategoryList_searchAndDrop__2yiE2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    margin-bottom: 2%;
  }

  .CategoryList_buttonContainer__1jwVc {
    width: 50%;
    display: flex;
    flex-direction: row;
    /* align-items: flex-end; */
    justify-content: space-between;
  }
}

.SubCategory_main__2-FLM {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.SubCategory_title__3hxbd {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #43425d;
}

.SubCategory_tableDiv__2oWG5 {
  background-color: white;
  margin-top: 2%;
  padding: 0 1.5%;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.SubCategory_searchBar__1peOu {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.SubCategory_searchText__2nKLG {
  display: flex;
  justify-content: center;
  align-items: center;
}

.SubCategory_dropDownDiv__2q-Nj {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.SubCategory_searchBarDiv__2l_Ki {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.SubCategory_searchAndDrop__2M1Gw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 35%;
}

.SubCategory_buttonContainer__13G7o {
  width: 20%;
  display: flex;
  flex-direction: row;
  /* align-items: flex-end; */
  justify-content: space-between;
}

@media screen and (max-width: 550px) {
  .SubCategory_main__2-FLM {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }
  .SubCategory_tableDiv__2oWG5 {
    margin-left: 0%;
    margin-top: 1.5%;
    padding: 0 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .SubCategory_dropDownDiv__2q-Nj {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
  }

  .SubCategory_searchBarDiv__2l_Ki {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .SubCategory_searchAndDrop__2M1Gw {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2%;
  }

  .SubCategory_buttonContainer__13G7o {
    width: 60%;
    display: flex;
    flex-direction: row;
    /* align-items: flex-end; */
    justify-content: space-between;
  }
}

.AddSub_main__E83Yz {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.AddSub_title__3Wg3w {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
}

.AddSub_tableDiv__2V6Ml {
  background-color: white;
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.AddSub_searchBar__byvjT {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.AddSub_dropDownDiv__2W4wV {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-left: 5%;
}

.AddSub_searchBarDiv__1ny4o {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.AddSub_searchAndDrop__2nEl8 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.AddSub_buttonAndFilter__2WVOh {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.AddSub_paginationDiv__2VF4c {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.AddSub_buttonDiv__UsDyz {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 550px) {
  .AddSub_tableDiv__2V6Ml {
    margin-left: 0%;
    margin-top: 1.5%;
    padding: 0 0;
    width: 95vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .AddSub_searchBar__byvjT {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .AddSub_dropDownDiv__2W4wV {
    width: 25%;
    display: flex;
    margin-left: 20px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
  }
  .AddSub_buttonAndFilter__2WVOh {
    display: flex;
    margin-right: 20px;
    width: 50%;
    flex-direction: row;
    justify-content: space-between;
  }
}

.AddProperty_main__34u39 {
  width: 100%;
  height: 100%;
  background-color: #f7f7f5;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.AddProperty_title__FUTl5 {
  font-size: xx-large;
}

.AddProperty_formDiv__lROAO {
  margin: 2% 0;
  padding: 1%;
  background-color: white;
  width: 80%;
}

.AddProperty_row1__g5AdI {
  display: flex;
  margin: 2% 0;
  flex-direction: row;
  justify-content: space-around;
}

.AddProperty_row2__1xMdf {
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 5%;
  margin: 2% 0;
}

.AddProperty_buttonDiv__2XItf {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  margin-right: 1%;
}

.AddProperty_buttons__f52CE {
  margin: 2%;
}

@media screen and (max-width: 550px) {
  .AddProperty_formDiv__lROAO {
    margin: 2% 0;
    padding: 1%;
    background-color: white;
    width: 100%;
  }
}

.JobList_main__2HGdv {
    background-color: #f7f7f5;
    width: 100%;
    height: 100%;
    padding: 2%;
}

.JobList_title__9Nyx- {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425D;
}

.JobList_tableDiv__2b8LK {
    background-color: white;
    margin-left: 2%;
    margin-top: 1.5%;
    padding: 0 1.5%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.JobList_searchBar__1dP6U {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.JobList_searchText__21EDK {
    display: flex;
    justify-content: center;
    align-items: center;
}

.JobList_dropDownDiv__3k8ma {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.JobList_searchBarDiv__2Rq6u {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.JobList_searchAndDrop__2tXwd {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.JobList_buttonAndFilter__351za {
    display: flex;
    width: 30%;
    flex-direction: row;
    justify-content: flex-end;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .JobList_searchAndDrop__2tXwd {
        width: 40%;
    }
    .JobList_dropDownDiv__3k8ma {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .JobList_searchAndDrop__2tXwd {
        width: 50%;
    }
    .JobList_dropDownDiv__3k8ma {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .JobList_searchAndDrop__2tXwd {
        width: 70%;
    }
    .JobList_dropDownDiv__3k8ma {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .JobList_searchAndDrop__2tXwd {
        width: 70%;
    }
    .JobList_dropDownDiv__3k8ma {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .JobList_searchAndDrop__2tXwd {
        width: 70%;
    }
    .JobList_dropDownDiv__3k8ma {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .JobList_searchAndDrop__2tXwd {
        width: 70%;
    }
    .JobList_dropDownDiv__3k8ma {
        width: 80%;
    }
}
.VerifyDocument_main__3rp_0 {
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
    padding: 2%;
}

.VerifyDocument_title__3PMhE {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425D;
}

.VerifyDocument_innerDiv__2Q8Us {
    margin: 2% 0;
    background-color: #F0F2F8;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.VerifyDocument_modalContainer__2L8_G {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.VerifyDocument_tableDiv__3FUzS {
    background-color: white;
    margin-left: 2%;
    margin-top: 1.5%;
    padding: 0 1.5%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.VerifyDocument_searchBar__16J1Q {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.VerifyDocument_searchText__106V- {
    display: flex;
    justify-content: center;
    align-items: center;
}

.VerifyDocument_dropDownDiv__frkY- {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.VerifyDocument_searchBarDiv__2yEE7 {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.VerifyDocument_searchAndDrop__1Adk7 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
}

.VerifyDocument_buttonAndFilter__3jLjk {
    display: flex;
    width: 30%;
    flex-direction: row;
    justify-content: flex-end;
}

.VerifyDocument_paginationDiv__12lmq {
    margin-top: 2%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .VerifyDocument_searchAndDrop__1Adk7 {
        width: 40%;
    }
    .VerifyDocument_dropDownDiv__frkY- {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .VerifyDocument_searchAndDrop__1Adk7 {
        width: 50%;
    }
    .VerifyDocument_dropDownDiv__frkY- {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .VerifyDocument_searchAndDrop__1Adk7 {
        width: 70%;
    }
    .VerifyDocument_dropDownDiv__frkY- {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .VerifyDocument_searchAndDrop__1Adk7 {
        width: 70%;
    }
    .VerifyDocument_dropDownDiv__frkY- {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .VerifyDocument_searchAndDrop__1Adk7 {
        width: 70%;
    }
    .VerifyDocument_dropDownDiv__frkY- {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .VerifyDocument_searchAndDrop__1Adk7 {
        width: 70%;
    }
    .VerifyDocument_dropDownDiv__frkY- {
        width: 80%;
    }
}


/* 4ba52aeb595d20200816073015 */
.RequestAccess_main__cz9HG {
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
    padding: 2%;
}

.RequestAccess_title__PhU1R {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425D;
}

.RequestAccess_innerDiv__2CV6m {
    margin: 2% 0;
    background-color: #F0F2F8;
    width: 80%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.RequestAccess_modalContainer__1q9Pq {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.RequestAccess_tableDiv__3ZJPs {
    background-color: white;
    margin-left: 2%;
    margin-top: 1.5%;
    padding: 0 1.5%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.RequestAccess_searchBar__2JI6t {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.RequestAccess_searchText__KPlUo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.RequestAccess_dropDownDiv__1YcDo {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.RequestAccess_buttonDiv__k2X9T {
    margin-left: 2%;
}

.RequestAccess_paginationDiv__256mZ {
    margin-top: 2%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.RequestAccess_searchBarDiv__VJXtR {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
}

.RequestAccess_searchAndDrop__64QI0 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.RequestAccess_buttonAndFilter__3Sspy {
    display: flex;
    width: 30%;
    flex-direction: row;
    justify-content: flex-end;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .RequestAccess_searchAndDrop__64QI0 {
        width: 40%;
    }
    .RequestAccess_dropDownDiv__1YcDo {
        width: 40%;
        margin-left: -1%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .RequestAccess_searchAndDrop__64QI0 {
        width: 50%;
    }
    .RequestAccess_dropDownDiv__1YcDo {
        width: 45%;
    }
    .RequestAccess_paginationDiv__256mZ {
        margin-left: 70%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .RequestAccess_searchAndDrop__64QI0 {
        width: 70%;
    }
    .RequestAccess_dropDownDiv__1YcDo {
        width: 40%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .RequestAccess_searchAndDrop__64QI0 {
        width: 70%;
    }
    .RequestAccess_dropDownDiv__1YcDo {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .RequestAccess_searchAndDrop__64QI0 {
        width: 70%;
    }
    .RequestAccess_dropDownDiv__1YcDo {
        width: 60%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .RequestAccess_searchAndDrop__64QI0 {
        width: 70%;
    }
    .RequestAccess_dropDownDiv__1YcDo {
        width: 60%;
    }
}
.Inventory_main__8LUef {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.Inventory_title__1Em0n {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #43425d;
}

.Inventory_tableDiv__CKzKF {
  background-color: white;
  margin-top: 2%;
  padding: 0 1.5%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: auto;
}

.Inventory_searchBar__vVp53 {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.Inventory_searchText__VWakD {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Inventory_dropDownDiv__1Seo2 {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.Inventory_searchBarDiv__8Er9K {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.Inventory_searchAndDrop__B3_M7 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}

.Inventory_buttonDiv__X5s73 {
  width: 30%;
  display: flex;
  justify-content: space-evenly;
}

.Inventory_paginationDiv__2Qkgw {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.Inventory_seaarchSelect__1saAW {
  color: #616161;
  font-size: 13px;
  min-width: 8.5vw;
  background: white;
  padding-top: 0px;
  border-color: #d7dae2;
  border-style: solid;
  border-width: 2px;
  padding-left: 0px;
  border-radius: 4px;
  padding-bottom: 0px;
}
.Inventory_seaarchSelect__1saAW svg {
  color: #9e9e9e;
}
.Inventory_seaarchSelect__1saAW div {
  color: black;
  /* min-width: 51vw; */
  background: white;
  border: none !important;
  min-height: 16px;
}
.Inventory_seaarchSelect__1saAW span {
  background-color: white;
}

@media screen and (max-width: 550px) {
  .Inventory_main__8LUef {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }
  .Inventory_tableDiv__CKzKF {
    margin-left: 0%;
    margin-top: 1.5%;
    padding: 0 0;
    width: 100vw;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  /* .table {
    height: 50%;
  } */
  .Inventory_dropDownDiv__1Seo2 {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .Inventory_searchBarDiv__8Er9K {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .Inventory_searchAndDrop__B3_M7 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .Inventory_buttonDiv__X5s73 {
    margin-top: 2%;
    width: 60%;
    display: flex;
    justify-content: space-between;
  }
}

.AddInventory_main__3PxOq {
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  padding: 2%;
  display: flex;
  margin-left: 3%;
  flex-direction: column;
  justify-content: flex-start;
}

.AddInventory_title__1d48j {
  font-size: 1.3rem;
  font-weight: 500;
}

.AddInventory_formDiv__dpuqK {
  margin: 2% 0;
  padding: 1%;
  background-color: white;
  width: 60%;
  box-shadow: 0px 3px 6px #00000014;
}

.AddInventory_row1__Iqp3w {
  display: flex;
  margin: 2% 0;
  flex-direction: row;
  justify-content: space-around;
}

.AddInventory_row2__kBayb {
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 5%;
  margin: 2% 0;
}

.AddInventory_buttonDiv__2ENp4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 3%;
}

.AddInventory_buttons__3AQVX {
  margin: 2%;
}

@media screen and (max-width: 550px) {
  .AddInventory_main__3PxOq {
    width: 100vw;
    height: 100%;
    background-color: #f9f9f9;
    padding: 2%;
    display: flex;
    margin-left: 3%;
    flex-direction: column;
    justify-content: flex-start;
  }

  .AddInventory_title__1d48j {
    font-size: medium;
  }

  .AddInventory_formDiv__dpuqK {
    margin: 2% 0;
    padding: 1%;
    background-color: white;
    width: 100%;
    box-shadow: 0px 3px 6px #00000014;
  }
}

.PackageList_main__1imJF {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  margin: 1%;
}

.PackageList_title__2PF8X {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
}

.PackageList_tableDiv__17Gjk {
  background-color: white;
  margin-top: 2%;
  padding: 0 1.5%;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.PackageList_searchBar__30u3a {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.PackageList_searchText__E7Sj9 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PackageList_dropDownDiv__38ubk {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.PackageList_searchBarDiv__2oZzN {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.PackageList_searchAndDrop__2ItKy {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  width: 40%;
}

.PackageList_buttonDiv__2ZITc {
  width: 27%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.PackageList_paginationDiv__3e4bS {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 550px) {
  .PackageList_main__1imJF {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    margin: 1%;
  }
  .PackageList_tableDiv__17Gjk {
    margin-left: 0%;
    margin-top: 1.5%;
    padding: 0 0;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .PackageList_searchBarDiv__2oZzN {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
  .PackageList_searchAndDrop__2ItKy {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .PackageList_buttonDiv__2ZITc {
    margin-top: 2%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.AddPackage_main__2Y9vo {
  width: 100%;
  height: 100%;
  background-color: #f7f7f5;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.AddPackage_title__2HqA1 {
  font-size: xx-large;
}
.AddPackage_formDiv__1j4oB {
  margin: 2% 0;
  padding: 1%;
  background-color: white;
  width: 60%;
}
.AddPackage_row1__Dv1Fe {
  display: flex;
  margin: 2% 0;
  flex-direction: row;
  justify-content: space-around;
}
.AddPackage_row2__3lO3z {
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 5%;
  margin: 2% 0;
}
.AddPackage_buttonDiv__AHMcp {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.AddPackage_buttons__3HQTa {
  margin: 2%;
}

@media screen and (max-width: 550px) {
  .AddPackage_main__2Y9vo {
    width: 100vw;
    height: 100%;
    background-color: #f7f7f5;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .AddPackage_title__2HqA1 {
    font-size: medium;
  }
  .AddPackage_formDiv__1j4oB {
    margin: 2% 0;
    padding: 1%;
    background-color: white;
    width: 100%;
  }
  .AddPackage_row1__Dv1Fe {
    display: flex;
    margin: 2% 0;
    flex-direction: row;
    justify-content: space-around;
  }
  .AddPackage_row2__3lO3z {
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 5%;
    margin: 2% 0;
  }
  .AddPackage_buttonDiv__AHMcp {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .AddPackage_buttons__3HQTa {
    margin: 2%;
  }
}

.Attendance_main__136iX {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.Attendance_title__3jRd_ {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #43425d;
  font-size: 1.3rem;
  font-weight: 500;
  margin-left: 2%;
}

.Attendance_tableDiv__SlVO9 {
  background-color: white;
  margin-top: 2%;
  padding: 0 1.5%;
  margin-left: 2%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Attendance_searchBar__3fW6e {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.Attendance_dropDownDiv__35SiJ {
  width: 80%;
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  align-items: flex-start;
}

.Attendance_searchBarDiv__2GVfD {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.Attendance_searchAndDrop__D_HaS {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.Attendance_buttonAndFilter__22WP6 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Attendance_paginationDiv__3vSld {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.Attendance_seaarchSelect__2FCdn {
  color: #616161;
  font-size: 13px;
  min-width: 8.5vw;
  background: white;
  padding-top: 0px;
  border-color: #d7dae2;
  border-style: solid;
  border-width: 2px;
  padding-left: 0px;
  border-radius: 4px;
  padding-bottom: 0px;
}
.Attendance_seaarchSelect__2FCdn svg {
  color: #9e9e9e;
}
.Attendance_seaarchSelect__2FCdn div {
  color: black;
  /* min-width: 51vw; */
  background: white;
  border: none !important;
  min-height: 16px;
}
.Attendance_seaarchSelect__2FCdn span {
  background-color: white;
}

@media screen and (max-width: 550px) {
  .Attendance_main__136iX {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }
  .Attendance_tableDiv__SlVO9 {
    margin-left: 0%;
    margin-top: 1.5%;
    padding: 0 0;
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .Attendance_dropDownDiv__35SiJ {
    width: 80%;
    display: flex;
    flex-direction: row;
    margin-left: 5%;
    align-items: flex-start;
  }

  .Attendance_searchBarDiv__2GVfD {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .Attendance_searchAndDrop__D_HaS {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .Attendance_buttonAndFilter__22WP6 {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.UserHistory_main__ANN28 {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  /* padding: 2%; */
}

.UserHistory_title__3LKS6 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #43425d;
  font-size: 1.3rem;
  font-weight: 500;
  margin-left: 2%;
}

.UserHistory_tableDiv__1S13J {
  background-color: white;
  margin-top: 2%;
  /* padding: 0 1.5%;
    margin-left: 2%; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.UserHistory_searchBar__1tMzr {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.UserHistory_search_btn__1G_HA {
  background-color: #43425d;
  color: white;
  border-radius: 17px;
  text-transform: none;
  width: 109px;
  outline: none;
  margin-left: 5%;
}

.UserHistory_download_btn__1eWdj {
  text-transform: none;
  background-color: #3b86ff;
  text-align: center;
  white-space: nowrap;
  outline: none;
  margin-left: 2%;
  font-size: smaller;
}

.UserHistory_dropDownDiv__oET2g {
  width: 25%;
  display: flex;
  flex-direction: row;
  margin-left: -7%;
  align-items: flex-end;
}
.UserHistory_M_L__3ab-C {
  margin-left: -1%;
}

.UserHistory_searchBarDiv__tXHtO {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.UserHistory_searchBarDiv1__1SfIS {
  padding: 1% 0;
  /* display: flex; */
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.UserHistory_searchAndDrop__3xKpm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.UserHistory_buttonAndFilter__2Gg6j {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.UserHistory_paginationDiv__14zkT {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.UserHistory_seaarchSelect__3QiYR {
  color: #616161;
  font-size: 13px;
  min-width: 8.5vw;
  background: white;
  padding-top: 0px;
  border-color: #d7dae2;
  border-style: solid;
  border-width: 2px;
  padding-left: 0px;
  border-radius: 4px;
  padding-bottom: 0px;
}
.UserHistory_seaarchSelect__3QiYR svg {
  color: #9e9e9e;
}
.UserHistory_seaarchSelect__3QiYR div {
  color: black;
  background: white;
  border: none !important;
  min-height: 16px;
}
.UserHistory_seaarchSelect__3QiYR span {
  background-color: white;
}

@media screen and (max-width: 550px) {
  .UserHistory_tableDiv__1S13J {
    background-color: white;
    margin-top: 2%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .UserHistory_search_btn__1G_HA {
    background-color: #43425d;
    color: white;
    border-radius: 17px;
    text-transform: none;
    width: 109px;
    outline: none;
    margin-left: 5%;
  }

  .UserHistory_download_btn__1eWdj {
    text-transform: none;
    background-color: #3b86ff;
    text-align: center;
    white-space: nowrap;
    outline: none;
    margin-left: 2%;
    font-size: smaller;
  }

  .UserHistory_dropDownDiv__oET2g {
    width: 50%;
    display: flex;
    flex-direction: row;
    margin-left: 0;
    align-items: flex-start;
  }
  .UserHistory_M_L__3ab-C {
    margin-left: -1%;
  }

  .UserHistory_searchBarDiv__tXHtO {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .UserHistory_searchBarDiv1__1SfIS {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .UserHistory_searchAndDrop__3xKpm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2%;
  }

  .UserHistory_buttonAndFilter__2Gg6j {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .UserHistory_paginationDiv__14zkT {
    margin-top: 2%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .UserHistory_seaarchSelect__3QiYR {
    color: #616161;
    font-size: 13px;
    min-width: 40vw;
    background: white;
    padding-top: 0px;
    border-color: #d7dae2;
    border-style: solid;
    border-width: 2px;
    padding-left: 0px;
    border-radius: 4px;
    padding-bottom: 0px;
  }
}

.Histories_main__V7ktc {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
}

.Histories_title__2xvfR {
  display: flex;
  margin-bottom: 20px;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #43425d;
}

.Histories_tabsDiv__kLxxC {
  margin-left: 0.5%;
  width: 100%;
  margin-top: 2%;
}

@media screen and (max-width: 550px) {
  .Histories_main__V7ktc {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
  }
  .Histories_tabsDiv__kLxxC {
    margin-left: 0.5%;
    width: 100%;
    margin-top: 2%;
  }
}

.SamadhanIDViewAll_main__39dUP {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
}
.SamadhanIDViewAll_title__24eV1 {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
}
.SamadhanIDViewAll_tableDiv__2gGhI {
  background-color: white;
  margin-top: 5%;
  padding: 0 1.5%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.SamadhanIDViewAll_searchBar__2f5ve {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.SamadhanIDViewAll_searchText__eJZC0 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.SamadhanIDViewAll_dropDownDiv__UEhI3 {
  width: 63%;
  display: flex;
  flex-direction: row;
}
.SamadhanIDViewAll_searchBarDiv__2cQeS {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.ForgotPassword_background__3UA2v {
    background: linear-gradient(rgba(242, 19, 79, 0.17), rgba(242, 19, 79, 0.17)), url(/static/media/unnamed@2x.699bc651.png);
    position: relative;
}

.ForgotPassword_div1__2j5OW {
    position: fixed;
    top: 15%;
    left: 30%;
    width: 35%;
    height: 70%;
    background-color: white;
    padding: 2%;
}

.ForgotPassword_logoDiv__zWT09 {
    display: flex;
    padding: 5%;
    justify-content: center;
}

.ForgotPassword_div1text__2bp6Z {
    text-align: center;
    padding: 2%;
    font-size: x-large;
}

.ForgotPassword_description__q1Lfe {
    font-size: smaller;
    text-align: center;
    padding-bottom: 5%;
    color: #64686D;
}

.ForgotPassword_textField__3XBra {
    display: flex;
    justify-content: center;
    align-content: center;
}

.ForgotPassword_cancelIcon__cAy6h {
    position: absolute;
    top: -25px;
    left: 96%;
}


.UploadProperty_main__Gf-gO {
  width: 100%;
  height: 100%;
  background-color: #f7f7f5;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.UploadProperty_title__3m90c {
  font-size: xx-large;
}

.UploadProperty_formDiv__3soyo {
  margin: 2% 0;
  padding: 1%;
  background-color: white;
  width: 70%;
  display: flex;
  flex-direction: column;
}

.UploadProperty_innerDiv__3vL8j {
  margin: 2% 0;
  background-color: #f0f2f8;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 2% 0;
  padding: 2%;
  justify-content: space-around;
  margin-left: 7%;
}

.UploadProperty_row1__2cxso {
  display: flex;
  margin: 2% 0;
  padding: 1%;
  justify-content: space-around;
}

.UploadProperty_row2__10H6I {
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 5%;
  margin: 2% 0;
}

.UploadProperty_buttonDiv__3UtlK {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 15%;
}

.UploadProperty_buttons__1fsKg {
  margin: 2%;
  padding: 3%;
}

@media screen and (max-width: 550px) {
  .UploadProperty_main__Gf-gO {
    width: 100vw;
    height: 100%;
    background-color: #f7f7f5;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .UploadProperty_title__3m90c {
    font-size: medium;
  }
  .UploadProperty_formDiv__3soyo {
    margin: 0%;
    padding: 2%;
    background-color: white;
    width: 100%;
  }

  .UploadProperty_innerDiv__3vL8j {
    margin: 0%;
    background-color: #f0f2f8;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0%;
    padding: 2%;
    justify-content: space-around;
    margin-left: 0%;
    align-items: center;
  }
}

.UploadDoc_main__1NsGF {
  width: 100%;
  height: 100%;
  background-color: #f7f7f5;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.UploadDoc_title__2fYYS {
  font-size: xx-large;
}

.UploadDoc_formDiv__FxXcL {
  margin: 2% 0;
  padding: 1%;
  background-color: white;
  width: 70%;
  display: flex;
  flex-direction: column;
}

.UploadDoc_innerDiv__1FO7T {
  margin: 2% 0;
  background-color: #f0f2f8;
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 2% 0;
  padding: 2%;
  justify-content: space-around;
  margin-left: 7%;
  align-items: center;
}

.UploadDoc_row1__1qiA3 {
  display: flex;
  margin: 2% 0;
  padding: 1%;
  justify-content: space-around;
}

.UploadDoc_row2__1hZ5V {
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 5%;
  margin: 2% 0;
}

.UploadDoc_buttonDiv__gr0ba {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 15%;
}

.UploadDoc_buttons__YJz12 {
  margin: 2%;
  padding: 3%;
}

.OTP_background__2Se9e {
    background: linear-gradient(rgba(242, 19, 79, 0.17), rgba(242, 19, 79, 0.17)), url(/static/media/unnamed@2x.699bc651.png);
    position: relative;
}

.OTP_div1__Vf-su {
    position: fixed;
    top: 15%;
    left: 30%;
    width: 35%;
    height: 70%;
    background-color: white;
    padding: 2%;
}

.OTP_logoDiv__1zhwy {
    display: flex;
    padding: 5%;
    margin-top: 5%;
    justify-content: center;
}

.OTP_div1text__3JSLr {
    text-align: center;
    padding: 5%;
    font-size: x-large;
    color: "black";
}

.OTP_description__2Nqwi {
    font-size: smaller;
    text-align: center;
    padding-bottom: 5%;
    color: #64686D;
}

.OTP_textField__1Xwio {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin-bottom: 10%;
    margin-top: 5%;
}

.OTP_cancelIcon__kecp9 {
    position: absolute;
    top: -25px;
    left: 96%;
}
.OTP_resSend__3TCIz{
    display: flex;
    text-align: center;
  font-size: "Regular 14px/22px SF Pro Text";
  letter-spacing: 0px;
  color: #43425D;
  opacity: 1;
  }
  .OTP_sendDisable__36SMZ{
    font-size: 13px;
      color: #bfb4b4;
      font-weight: bold;
      margin-left: 3%;
      cursor: pointer;
  }
  .OTP_send__2KMRj{
    width: 100%;
    margin-right: 21%;
    font-size: 13px;
    color: red;
    font-weight: bold;
    margin-left: 3%;
    cursor: pointer;
    text-align: right
  }
  .OTP_ex__2nYmN{
    font-size: 13px;
      color: #43425D;
      margin-left: 21%;
  }
.Audit_main__IGI_P {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  margin: 1%;
}

.Audit_header__2k6qj {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.Audit_modalBody__3su15 {
  padding: 0px !important;
}
.Audit_cancel__36A-b {
  color: white;
  position: absolute;
  top: -9px;
  right: -9px;
  height: 7%;
  width: 10%;
  cursor: pointer;
  z-index: 90000;
}
.Audit_carouselControl__3Yary {
  position: absolute;
  top: 50%; /* pushes the icon in the middle of the height */
  z-index: 5;
  display: inline-block;
  cursor: pointer;
  background: transparent;
  box-shadow: none;
  border: none;
}
.Audit_disabled__1xVur {
  cursor: not-allowed !important;
}

.Audit_left__3Zi_G {
  left: 0px;
}

.Audit_right__1XtAU {
  right: 0px;
}

.Audit_title__2ZLLC {
  margin-bottom: 2%;
  display: flex;
  justify-content: flex-start;
  font-size: x-large;
  white-space: nowrap;
}
.Audit_arrow__3oOzw {
  color: white;
  font-size: 36px !important;
  cursor: pointer;
}

.Audit_tableDiv__2TMuS {
  margin-left: 1%;
  margin-top: 1.5%;
  margin: 2%;
  padding: 0 1%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.Audit_header2__jqXMd {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 60%;
  align-items: center;
}

@media screen and (max-width: 550px) {
  .Audit_main__IGI_P {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    margin: 0%;
  }
  .Audit_tableDiv__2TMuS {
    margin-left: 0;
    margin-top: 1.5%;
    margin: 0%;
    padding: 0 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .Audit_header2__jqXMd {
    display: flex;
    margin-top: 2%;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
  }
}

.JobReportingCard_card__3Vevr {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  box-shadow: 0px 2px 6px #0000000a;
  border-radius: 4px;
}

.JobReportingCard_detailsDiv__3bKLE {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  font-size: x-large;
  font-weight: bold;
  color: #4d4f5c;
  margin-left: 2%;
  font: Semibold 22px/28px Source Sans Pro;
  margin-top: 0px;
}

.Initialisation_main__15aPc {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: baseline; */
}

.Initialisation_header__2EYFa {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
}

.Initialisation_dropdownMain__1ZU0B {
  display: flex;
  margin-right: 2%;
  padding-bottom: 2%;
  align-items: flex-end;
  margin-top: 2%;
  justify-content: flex-end;
  width: 20%;
}

.Initialisation_title__3AM4c {
  margin-left: 3%;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
  margin-top: 2%;
}

.Initialisation_header2__z1WRh {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 5%;
  width: 98%;
  margin-left: 3%;
  justify-content: first baseline;
}

.Initialisation_subTitle__2-RHQ {
  font-size: 1.25rem;
  color: #43425d;
  font-weight: bold;
  border-bottom: 0.1rem solid #43425d;
  padding-bottom: 1%;
  margin-right: 2%;
}

.Initialisation_dropdown2__2rt-I {
  margin-left: 34%;
}

.Initialisation_date__1kwvX {
  margin-left: 2%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

.Initialisation_label__1tKXR {
  color: #43425d;
  font-size: medium;
  text-align: center;
}

.Initialisation_date_picker__14IuJ {
  border-color: #f5f6fa;
  border-radius: 4px;
  width: 31%;
  margin-left: 2%;
  margin-right: 2%;
}

.Initialisation_cards__1KaTz {
  width: 70%;
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 5%;
  margin-left: 3%;
}

.Initialisation_card__1CAq5 {
  max-width: 100%;
  min-width: 25%;
}

.Initialisation_final__2Qek- {
  margin-left: 85%;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 550px) {
  .Initialisation_main__15aPc {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .Initialisation_header__2EYFa {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .Initialisation_dropdownMain__1ZU0B {
    display: flex;
    margin-right: 3%;
    padding-bottom: 2%;
    align-items: flex-start;
    margin-top: 2%;
    justify-content: flex-end;
    width: 95%;
  }
  .Initialisation_title__3AM4c {
    margin-left: 3%;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: x-large;
    margin-top: 2%;
  }

  .Initialisation_header2__z1WRh {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3%;
    width: 100%;
    margin-left: 3%;
    justify-content: first baseline;
  }

  .Initialisation_subTitle__2-RHQ {
    font-size: 1.25rem;
    color: #43425d;
    font-weight: bold;
    border-bottom: 0.1rem solid #43425d;
    padding-bottom: 1%;
    margin-right: 2%;
  }

  .Initialisation_dropdown2__2rt-I {
    margin-left: 1%;
    margin-top: 5%;
  }

  .Initialisation_date__1kwvX {
    margin-left: 0;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-between;
  }

  .Initialisation_label__1tKXR {
    color: #43425d;
    font-size: smaller;
    text-align: center;
  }

  .Initialisation_date_picker__14IuJ {
    border-color: #f5f6fa;
    border-radius: 4px;
    width: 28%;
    margin-left: 0;
    margin-right: 0;
  }

  .Initialisation_cards__1KaTz {
    width: 100%;
    margin-top: 5%;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-left: 0;
  }

  .Initialisation_card__1CAq5 {
    max-width: 45%;
    min-width: 25%;
    margin-top: 3%;
  }

  .Initialisation_final__2Qek- {
    margin-left: 70%;
    margin-top: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
  }
}

.QC_main__1euef {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: baseline;
}

.QC_header__df_Ri {
  width: 99%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-items: flex-end;
}

.QC_dropdownMain__3I578 {
  display: flex;
  margin-right: -1%;
  margin-top: 2%;
  padding-bottom: 2%;
  align-items: flex-end;
  justify-content: flex-end;
}

.QC_title__2Wr4T {
  margin-left: 1.5%;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 2%;
}

.QC_header2__2AtAK {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 5%;
  width: 102%;
  justify-content: space-evenly;
  justify-items: flex-end;
}

.QC_subTitle__3KB_i {
  font-size: 22px;
  color: #43425d;
  font-weight: bold;
  border-bottom: 0.1rem solid #43425d;
  padding-bottom: 1%;
  white-space: nowrap;
  justify-content: flex-start;
}

.QC_dropdown2__39LSs {
  margin-left: 40%;
}

.QC_date__3AMPc {
  margin-left: 2%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

.QC_label__3g9Ie {
  color: #43425d;
  font-size: medium;
  text-align: center;
  white-space: nowrap;
}

.QC_date_picker__1m7LA {
  border-color: #f5f6fa;
  border-radius: 4px;
  width: 31%;
  margin-left: 2%;
  margin-right: 2%;
}

.QC_cards__ZCdUE {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  flex: 1 1;
  margin-top: 5%;
  margin-left: 2%;
}

.QC_card__3u2Y4 {
  max-width: 45%;
  min-width: 25%;
  /* margin-top: 3%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.QC_circle__3FxdD {
  position: relative;
  height: 90px;
  width: 90px;
  align-self: center;
  color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 25%; */
}

.QC_final__1B3Gw {
  margin-left: 85%;
  display: flex;
  flex-direction: row;
}

/* @media (min-width: 1025px) and (max-width: 1440px) {
  .cards {
    width: 100%;
  }
  .dropdown2 {
    margin-left: 33%;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .cards {
    width: 100%;
  }
  .dropdown2 {
    margin-left: 10%;
  }
}

@media (min-width: 426px) and (max-width: 768px) {
  .cards {
    width: 100%;
  }
  .dropdown2 {
    margin-left: 10%;
  }
  .circle {
    margin-left: -5%;
  }
}

@media (min-width: 376px) and (max-width: 425px) {
  .cards {
    width: 100%;
  }
  .dropdown2 {
    margin-left: 10%;
  }
  .circle {
    margin-left: -5%;
  }
  .header2 {
    margin-left: 4%;
  }
}

@media (min-width: 320px) and (max-width: 375px) {
  .cards {
    width: 100%;
  }
  .dropdown2 {
    margin-left: 5%;
  }
  .circle {
    margin-left: -5%;
  }
  .header2 {
    margin-left: 5%;
  }
} */

@media screen and (max-width: 550px) {
  .QC_main__1euef {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: baseline;
  }

  .QC_header__df_Ri {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    justify-items: flex-end;
  }

  .QC_dropdownMain__3I578 {
    display: flex;
    margin-right: -1%;
    margin-top: 2%;
    padding-bottom: 2%;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .QC_header2__2AtAK {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5%;
    margin: 2%;
    width: 100%;
    justify-content: space-evenly;
    justify-items: flex-start;
  }
  .QC_dropdown2__39LSs {
    margin-left: 1%;
    margin: 4%;
  }
  .QC_date__3AMPc {
    margin-left: 2%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
  }

  .QC_label__3g9Ie {
    color: #43425d;
    font-size: smaller;
    text-align: center;
    white-space: nowrap;
  }

  .QC_date_picker__1m7LA {
    border-color: #f5f6fa;
    border-radius: 4px;
    width: 26%;
    margin-left: 0;
    margin-right: 0;
  }

  .QC_cards__ZCdUE {
    width: 100%;
    margin-top: 5%;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-left: 0;
  }

  .QC_card__3u2Y4 {
    max-width: 45%;
    min-width: 25%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .QC_circle__3FxdD {
    position: relative;
    height: 90px;
    width: 90px;
    align-self: center;
    color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: 0%; */
  }

  .QC_final__1B3Gw {
    margin-left: 50%;
    margin-top: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
  }
}

.AuditJob_main__1jvk5 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: baseline;
}

.AuditJob_header__2FioN {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.AuditJob_dropdownMain__ft8XW {
  display: flex;
  margin-right: 2%;
  margin-top: 2%;
  padding-bottom: 2%;
  align-items: flex-end;
  justify-content: flex-end;
}

.AuditJob_title__2Oklo {
  margin-left: 2%;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
  margin-top: 2%;
}

.AuditJob_header2__2Zo9x {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 5%;
  width: 98%;
  margin-left: 3%;
  justify-content: first baseline;
}

.AuditJob_subTitle__lCxHM {
  font-size: 1.25rem;
  color: #43425d;
  font-weight: bold;
  border-bottom: 0.1rem solid #43425d;
  padding-bottom: 1%;
  margin-right: 2%;
  white-space: nowrap;
}

.AuditJob_dropdown2__3YbPZ {
  margin-left: 32%;
}

.AuditJob_date__1ZAiz {
  margin-left: 2%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

.AuditJob_label__1g5i0 {
  color: #43425d;
  font-size: medium;
  text-align: center;
  white-space: nowrap;
}

.AuditJob_date_picker__3JQT_ {
  border-color: #f5f6fa;
  border-radius: 4px;
  width: 31%;
  margin-left: 2%;
  margin-right: 2%;
}

.AuditJob_cards__11CbX {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  flex: 1 1;
  margin-top: 5%;
  margin-left: 2%;
}

.AuditJob_card__wOFoO {
  max-width: 45%;
  min-width: 25%;
  /* margin-top: 3%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AuditJob_final__-kIwu {
  margin-left: 85%;
  display: flex;
  flex-direction: row;
}

/* @media (min-width: 1025px) and (max-width: 1440px) {
    .cards {
        width: 45%;
    }
    .dropdown2 {
        margin-left: 33%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .cards {
        width: 100%;
    }
    .dropdown2 {
        margin-left: 10%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .cards {
        width: 100%;
    }
    .dropdown2 {
        margin-left: 10%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .cards {
        width: 100%;
    }
    .dropdown2 {
        margin-left: 10%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .cards {
        width: 100%;
    }
    .dropdown2 {
        margin-left: 5%;
    }
} */

@media screen and (max-width: 550px) {
  .AuditJob_main__1jvk5 {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: baseline;
  }

  .AuditJob_header__2FioN {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .AuditJob_dropdownMain__ft8XW {
    display: flex;
    margin-right: 2%;
    margin-top: 2%;
    padding-bottom: 2%;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .AuditJob_header2__2Zo9x {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5%;
    margin: 2%;
    width: 100%;
    justify-content: space-evenly;
    justify-items: flex-start;
  }
  .AuditJob_dropdown2__3YbPZ {
    margin-left: 1%;
    margin: 4%;
  }
  .AuditJob_date__1ZAiz {
    margin-left: 2%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
  }

  .AuditJob_label__1g5i0 {
    color: #43425d;
    font-size: smaller;
    text-align: center;
    white-space: nowrap;
  }

  .AuditJob_date_picker__3JQT_ {
    border-color: #f5f6fa;
    border-radius: 4px;
    width: 26%;
    margin-left: 0;
    margin-right: 0;
  }

  .AuditJob_cards__11CbX {
    width: 100%;
    margin-top: 5%;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-left: 0;
  }

  .AuditJob_card__wOFoO {
    max-width: 45%;
    min-width: 25%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .AuditJob_final__-kIwu {
    margin-left: 50%;
    margin-top: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
  }
}

.Installation_main__3lCdb {
  width: 100%;
  height: 100%;
  /* display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: baseline; */
  margin: 2%;
  overflow: hidden;
}

.Installation_header__1COPp {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.Installation_dropdownMain__3VLXU {
  display: flex;
  margin-right: 2%;
  margin-top: 2%;
  padding-bottom: 2%;
  align-items: flex-end;
  justify-content: flex-end;
  /* width: 20% */
}

.Installation_title__TzKzP {
  margin-left: 3%;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
  margin-top: 2%;
}

.Installation_header2__2kgUb {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 5%;
  width: 98%;
  margin-left: 3%;
  justify-content: first baseline;
}

.Installation_subTitle__2RX1r {
  font-size: 1.25rem;
  color: #43425d;
  font-weight: bold;
  border-bottom: 0.1rem solid #43425d;
  padding-bottom: 1%;
  margin-right: 2%;
}

.Installation_dropdown2__1T50S {
  margin-left: 34%;
}

.Installation_date__KFvUb {
  margin-left: 2%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

.Installation_label__3_L91 {
  color: #43425d;
  font: SF Pro Text;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
}

.Installation_date_picker__11ICf {
  border-color: #f5f6fa;
  border-radius: 4px;
  width: 31%;
  margin-left: 2%;
  margin-right: 2%;
}

.Installation_cards__AlxLh {
  min-width: 21%;
  max-width: 21%;
  margin: 2%;
  min-height: 20%;
  max-height: 20%;
}

.Installation_card__3doCB {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  box-shadow: 0px 2px 6px #0000000a;
  border-radius: 4px;
}

.Installation_header__1COPp {
  font-size: x-large;
  font-weight: bold;
}

.Installation_detailsDiv__2yEPX {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  /* font-size: x-large; */
  font-weight: bold;
  color: #4d4f5c;
  margin-left: 2%;
  font: Semibold 22px/28px Source Sans Pro;
  margin-top: 0px;
}

.Installation_raised__2xGjR {
  border: 1px solid #77aaff;
  box-shadow: -1px 1px #77aaff, -2px 2px #77aaff, -3px 3px #77aaff,
    -4px 4px #77aaff, -5px 5px #77aaff;
}

.Installation_triangle__X06_g {
  width: 0;
  height: 0;
  border-bottom: 100px solid red;
  border-left: 100px solid transparent;
}

.Installation_grid__2LvFW {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1 1;
}

.Installation_final__2-_j1 {
  margin-left: 85%;
  display: flex;
  flex-direction: row;
}

.Installation_finale__1RjqL {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-left: 3%;
  margin-top: 7%;
  margin-bottom: 2%;
}
.Installation_mt_2__2QKjS {
  margin-top: 2%;
}
.Installation_Dblock__2kiJ5 {
  display: none;
}

.Installation_finalDiv__yO4Cc {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 2%;
  padding: 0 1.5%;
}

.Installation_trackingDiv__S5xYu {
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  height: 10%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.Installation_initial_div__pkdOR {
  display: flex;
  flex-direction: row;
  padding: 2%;
}

.Installation_initial__27XWF {
  border-radius: 5px;
  width: 1.5vw;
  height: 1vw;
  margin-right: 1%;
}

.Installation_graphdiv__3X-MI {
  margin-top: 10%;
  width: 100%;
}

/* .chart {
  width: 1150px;
  width: 300px;
} */

.Installation_square__HHv6c {
  width: 50px;
  height: 30px;
  background: #ffcd54;
}

.Installation_triangle__X06_g {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: #ffcd54 transparent transparent transparent;
  margin-left: 15px;
}

@media screen and (max-width: 550px) {
  .Installation_main__3lCdb {
    width: 100vw;
    height: 100%;
    /* display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; */
    margin: 1%;
  }

  .Installation_header__1COPp {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .Installation_dropdownMain__3VLXU {
    display: flex;
    margin-right: 3%;
    padding-bottom: 2%;
    align-items: flex-start;
    margin-top: 2%;
    justify-content: flex-end;
    width: 95%;
  }
  .Installation_header2__2kgUb {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3%;
    width: 100%;
    margin-left: 3%;
    justify-content: first baseline;
  }
  .Installation_dropdown2__1T50S {
    margin-left: 1%;
    margin-top: 5%;
  }

  .Installation_date__KFvUb {
    margin-left: 0;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-between;
  }

  .Installation_label__3_L91 {
    color: #43425d;
    font-size: smaller;
    text-align: center;
  }

  .Installation_date_picker__11ICf {
    border-color: #f5f6fa;
    border-radius: 4px;
    width: 26%;
    margin-left: 0;
    margin-right: 0;
  }

  .Installation_cards__AlxLh {
    min-width: 40%;
    max-width: 40%;
    margin: 2%;
    min-height: 35%;
    max-height: 35%;
  }

  .Installation_card__3doCB {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
  }

  .Installation_header__1COPp {
    font-size: x-large;
    font-weight: bold;
  }

  .Installation_detailsDiv__2yEPX {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    /* font-size: x-large; */
    font-weight: bold;
    color: #4d4f5c;
    margin-left: 2%;
    font: Semibold 22px/28px Source Sans Pro;
    margin-top: 0px;
  }

  .Installation_final__2-_j1 {
    margin-left: 50%;
    display: flex;
    flex-direction: row;
  }

  .Installation_label__3_L91 {
    color: #43425d;
    font: SF Pro Text;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
  }

  .Installation_finale__1RjqL {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    margin-left: 2%;
    margin-top: 7%;
    margin-bottom: 2%;
  }

  .Installation_finalDiv__yO4Cc {
    background-color: white;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0;
    margin: 1%;
    padding: 0;
  }

  .Installation_trackingDiv__S5xYu {
    margin-left: 0;
    margin: 1%;
    width: 100vw;
    margin-top: 1.5%;
    padding: 0;
    height: 10%;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-template-columns: 0;
  }

  .Installation_initial_div__pkdOR {
    display: flex;
    flex-direction: column;
    padding: 1%;
  }

  .Installation_initial__27XWF {
    border-radius: 5px;
    width: 100%;
    height: 1vw;
    margin-right: 0;
  }

  .Installation_graphdiv__3X-MI {
    margin-top: 10%;
    width: 100%;
  }
}

.AuditTable_main__2s-xT {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
  margin-left: 4%;
}

.AuditTable_title__3_lM3 {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
}

.AuditTable_tableDiv__BkWIb {
  background-color: white;
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2%;
}

.AuditTable_searchBar__2v5bY {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.AuditTable_searchText__39HR5 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AuditTable_dropDownDiv__2Qi8A {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.AuditTable_searchBarDiv__2jQhu {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.AuditTable_searchAndDrop__3emZA {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.AuditTable_buttonAndFilter__57Um6 {
  display: flex;
  width: 60%;
  flex-direction: row;
  justify-content: space-evenly;
}

@media screen and (max-width: 550px) {
  .AuditTable_main__2s-xT {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
    margin-left: 0%;
  }

  .AuditTable_title__3_lM3 {
    margin-left: 2%;
    margin-top: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: medium;
  }

  .AuditTable_tableDiv__BkWIb {
    background-color: white;
    margin-left: 0;
    margin-top: 2%;
    padding: 2%;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2%;
  }

  .AuditTable_searchBar__2v5bY {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .AuditTable_dropDownDiv__2Qi8A {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .AuditTable_searchBarDiv__2jQhu {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .AuditTable_searchAndDrop__3emZA {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .AuditTable_buttonAndFilter__57Um6 {
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 0%;
  }

  .AuditTable_paginationDiv__1FyvP {
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.Stepper_main__3t0Lb {
    background-color: #f7f7f5;
    width: 100%;
    height: 100%;
    padding: 2%;
  }
.Stepper_avatarDiv__2CAFh{
    display: flex;
    width: 100%;
    background-color: white;
    margin: 1% 0;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
.Stepper_avatarContainer__1QNpl {
    max-width: 960px;
    margin: 30px auto;
    padding: 20px;
}
.Stepper_avatar__23uxZ{
    margin-right: 3%;
}
.Stepper_container__2vu7C{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-bottom: 2%;
}
.Stepper_stepperButtons__1gPwd {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.Stepper_title__2xz7m {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: xx-large;
}
.Stepper_formDiv__1_GpQ {
  background-color: white;
  padding-bottom: 2%;
  height: 80%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.Stepper_row1__33mSA {
  display: flex;
  padding: 2% 0;
  flex-direction: row;
  justify-content: space-evenly;
}

.Stepper_row2__2HIC6 {
  width: calc(51vw - 80px);
  display: flex;
  padding: 2% 0;
  flex-direction: row;
  justify-content: space-evenly;
}

.Stepper_lastFormElement__2vkev {
  font-size: 25px;
  font-weight: 400;
  color: #707070;
  margin-top: 5%;
  margin-bottom: 5%;
}

.Stepper_checked__HJzEr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Stepper_dot__2Xx8z {
  height: 25px;
  text-align: center;
  background-color: red;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 60%;
}
.Stepper_review__2E0dZ{
  display: flex;
  padding: 2% 0;
  flex-direction: row;
  letter-spacing: 0px;
color: #707070;
opacity: 1;
font-size:16px
  /* justify-content: space-around; */
}
.Stepper_ul__1C4kL{
  list-style: none;
  display: table;
}

.Stepper_li__3FbLV {
  display: table-row;
  line-height: 28px;
}

.Stepper_b__3HBwJ {
  display: table-cell;
  padding-right: 3em;
}





@media (min-width:320px) and (max-width:375px) {
  .Stepper_label__e1KzI {
      font-size: xx-small;
  }
  .Stepper_lastFormElement__2vkev {
      padding-left: 20%;
  }
}

@media (min-width:376px) and (max-width:768px) {
  .Stepper_label__e1KzI {
      font-size: x-small;
  }
  .Stepper_lastFormElement__2vkev {
      padding-left: 10%;
  }
}
.InitialisationTable_main__WaF8H {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.InitialisationTable_title__1u8IW {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
}

.InitialisationTable_tableDiv__2rc-i {
  background-color: white;
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2%;
}

.InitialisationTable_searchBar__3eGs9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.InitialisationTable_searchText__1i85c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.InitialisationTable_dropDownDiv__2M0Sd {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.InitialisationTable_searchBarDiv__3LCI_ {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.InitialisationTable_searchAndDrop__HRk-9 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.InitialisationTable_buttonAndFilter__IfXD- {
  display: flex;
  width: 68%;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: 2%;
}

.InitialisationTable_paginationDiv__2waSU {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 550px) {
  .InitialisationTable_main__WaF8H {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }

  .InitialisationTable_title__1u8IW {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: medium;
  }

  .InitialisationTable_tableDiv__2rc-i {
    background-color: white;
    margin-left: 1%;
    margin-top: 1.5%;
    padding: 1%;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2%;
  }

  .InitialisationTable_searchBar__3eGs9 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
}

.InstallationTable_main__3QO-k {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.InstallationTable_title__DjJzR {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
}

.InstallationTable_tableDiv__2ESdQ {
  background-color: white;
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2%;
}

.InstallationTable_searchBar__mlqsm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.InstallationTable_searchText__VJWSW {
  display: flex;
  justify-content: center;
  align-items: center;
}

.InstallationTable_dropDownDiv__3UXsI {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.InstallationTable_searchBarDiv__1CtIH {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.InstallationTable_searchAndDrop__22hUR {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.InstallationTable_buttonAndFilter__2G_Ip {
  display: flex;
  width: 68%;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: 2%;
}

.InstallationTable_paginationDiv__TLZdW {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 550px) {
  .InstallationTable_main__3QO-k {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }

  .InstallationTable_title__DjJzR {
    margin-left: 2%;
    margin-top: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: medium;
  }

  .InstallationTable_tableDiv__2ESdQ {
    background-color: white;
    margin-left: 0;
    margin-top: 2%;
    padding: 2%;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2%;
  }

  .InstallationTable_searchBar__mlqsm {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .InstallationTable_dropDownDiv__3UXsI {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .InstallationTable_searchBarDiv__1CtIH {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .InstallationTable_searchAndDrop__22hUR {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .InstallationTable_buttonAndFilter__2G_Ip {
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 0%;
  }

  .InstallationTable_paginationDiv__TLZdW {
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.QCTable_main__2h6Hp {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.QCTable_title__213hc {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
}

.QCTable_tableDiv__1-XRV {
  background-color: white;
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2%;
}

.QCTable_searchBar__3vLhr {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.QCTable_searchText__2UDLK {
  display: flex;
  justify-content: center;
  align-items: center;
}

.QCTable_dropDownDiv__1oglr {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.QCTable_searchBarDiv__S-oSj {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.QCTable_searchAndDrop__ayQJG {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.QCTable_buttonAndFilter__1yUMg {
  display: flex;
  width: 60%;
  flex-direction: row;
  justify-content: space-evenly;
}

@media screen and (max-width: 550px) {
  .QCTable_main__2h6Hp {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }

  .QCTable_title__213hc {
    margin-left: 2%;
    margin-top: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: medium;
  }

  .QCTable_tableDiv__1-XRV {
    background-color: white;
    margin-left: 0;
    margin-top: 2%;
    padding: 2%;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2%;
  }

  .QCTable_searchBar__3vLhr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .QCTable_dropDownDiv__1oglr {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .QCTable_searchBarDiv__S-oSj {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .QCTable_searchAndDrop__ayQJG {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .QCTable_buttonAndFilter__1yUMg {
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 0%;
  }

  .QCTable_paginationDiv__1rExM {
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/* .main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: baseline;
}

.header {
    width: 99%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    justify-items: flex-end;
}

.dropdownMain {
    display: flex;
    margin-right: 2%;
    margin-top: 2%;
    padding-bottom: 2%;
    align-items: flex-end;
    justify-content: flex-end;
}

.title {
    margin-left: 1.5%;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    margin-top: 2%;
}

.header2 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 5%;
    width: 100%;
    margin-right: 3%;
    justify-content: space-around;
    justify-items: flex-end;
}

.subTitle {
    font-size: 22px;
    color: #43425D;
    font-weight: bold;
    border-bottom: 0.1rem solid #43425D;
    padding-bottom: 1%;
    margin-left: 2%;
    white-space: nowrap;
    justify-content: flex-start;
}

.dropdown2 {
    margin-left: 25%;
}

.date {
    margin-left: 2%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
}

.label {
    color: #43425D;
    font-size: medium;
    text-align: center;
    white-space: nowrap;
}

.cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
    flex: 1;
    margin-top: 5%;
    margin-left: -3%;
}

.innerCard {
    background: linear-gradient(#FFC0CB 50%, #00FFFF 50%);
}

.cardTitle {
    font: Source Sans Pro;
    color: #BBBBBB;
    text-align: center;
    align-self: center;
    white-space: nowrap;
    font-size: large;
}

.final {
    margin-left: 85%;
    display: flex;
    flex-direction: row;
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .cards {
        width: 100%;
    }
    .dropdown2 {
        margin-left: 20%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .cards {
        width: 100%;
    }
    .dropdown2 {
        margin-left: 10%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .cards {
        width: 100%;
    }
    .dropdown2 {
        margin-left: 10%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .cards {
        width: 100%;
    }
    .dropdown2 {
        margin-left: 10%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .cards {
        width: 100%;
    }
    .dropdown2 {
        margin-left: 5%;
    }
} */

.UWH_main__3DaSC {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: baseline;
}

.UWH_header__1V3Nz {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.UWH_dropdownMain__2t5Fe {
  display: flex;
  margin-right: 2%;
  margin-top: 2%;
  padding-bottom: 2%;
  align-items: flex-end;
  justify-content: flex-end;
}

.UWH_title__2g4Bj {
  margin-left: 2%;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
  margin-top: 2%;
}

.UWH_header2__3tDf0 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 5%;
  width: 98%;
  margin-left: 3%;
  justify-content: first baseline;
}

.UWH_subTitle__1yGbx {
  font-size: 1.25rem;
  color: #43425d;
  font-weight: bold;
  border-bottom: 0.1rem solid #43425d;
  padding-bottom: 1%;
  margin-right: 2%;
  white-space: nowrap;
}

.UWH_dropdown2__GO_ws {
  margin-left: 32%;
}

.UWH_date__2BXU9 {
  margin-left: 2%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

.UWH_label__2Z5ZX {
  color: #43425d;
  font-size: medium;
  text-align: center;
  white-space: nowrap;
}

.UWH_date_picker__FKlXC {
  border-color: #f5f6fa;
  border-radius: 4px;
  width: 31%;
  margin-left: 2%;
  margin-right: 2%;
}

.UWH_cards__1ADs7 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  flex: 1 1;
  margin-top: 5%;
  margin-left: 2%;
}

.UWH_card__1CwbO {
  width: 25%;
  /* margin-top: 3%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.UWH_innerCard__1Vuu1 {
  background: linear-gradient(#ffc0cb 50%, #00ffff 50%);
}

.UWH_cardTitle__-QAe1 {
  font: Source Sans Pro;
  color: #bbbbbb;
  text-align: center;
  align-self: center;
  white-space: nowrap;
  font-size: large;
}

.UWH_final__RIWZg {
  margin-left: 85%;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 550px) {
  .UWH_main__3DaSC {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: baseline;
  }

  .UWH_header__1V3Nz {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .UWH_dropdownMain__2t5Fe {
    display: flex;
    margin-right: 2%;
    margin-top: 2%;
    padding-bottom: 2%;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .UWH_header2__3tDf0 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5%;
    margin: 2%;
    width: 100%;
    justify-content: space-evenly;
    justify-items: flex-start;
  }
  .UWH_dropdown2__GO_ws {
    margin-left: 1%;
    margin: 4%;
  }
  .UWH_date__2BXU9 {
    margin-left: 2%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
  }

  .UWH_label__2Z5ZX {
    color: #43425d;
    font-size: smaller;
    text-align: center;
    white-space: nowrap;
  }

  .UWH_date_picker__FKlXC {
    border-color: #f5f6fa;
    border-radius: 4px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .UWH_cards__1ADs7 {
    width: 100%;
    margin-top: 5%;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-left: 0;
  }

  .UWH_card__1CwbO {
    width: 80%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .UWH_cardTitle__-QAe1 {
    font: Source Sans Pro;
    color: #bbbbbb;
    text-align: center;
    align-self: center;
    white-space: nowrap;
    font-size: large;
  }

  .UWH_final__RIWZg {
    margin-left: 50%;
    margin-top: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
  }
}

.UWHT_main__1C0Q0 {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  margin: 2%;
  padding: 2%;
}

.UWHT_title__2i6UQ {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
}

.UWHT_tableDiv__3HJWB {
  background-color: white;
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2%;
}

.UWHT_searchBar__2qMgd {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-bottom: 2%;
  margin-top: 4%;
}

.UWHT_searchText__2YHex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.UWHT_dropDownDiv__1jwLm {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.UWHT_searchBarDiv__3gMJz {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.UWHT_searchAndDrop__1rILP {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.UWHT_buttonAndFilter__3i5z8 {
  display: flex;
  width: 60%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.UWHT_paginationDiv__1A1Dp {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media screen and (max-width: 550px) {
  .UWHT_main__1C0Q0 {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    margin: 1%;
    padding: 0%;
  }

  .UWHT_title__2i6UQ {
    margin-left: 1%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: small;
  }

  .UWHT_tableDiv__3HJWB {
    background-color: white;
    margin-left: 0;
    margin-top: 2%;
    padding: 1%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2%;
  }

  .UWHT_searchBar__2qMgd {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 2%;
    margin-top: 4%;
  }

  .UWHT_searchText__2YHex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .UWHT_dropDownDiv__1jwLm {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 3%;
  }

  .UWHT_searchBarDiv__3gMJz {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .UWHT_searchAndDrop__1rILP {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .UWHT_buttonAndFilter__3i5z8 {
    display: flex;
    width: 100%;
    margin-bottom: 3%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .UWHT_paginationDiv__1A1Dp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5%;
  }
}

.PropertyVersion_main__3W33S {
    background-color: #f7f7f5;
    width: 100%;
    height: 100%;
    padding: 2%;
}
.PropertyVersion_width__3dI2b{
    width: 0px;
}

.PropertyVersion_title__29pTh {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425D;
}

.PropertyVersion_tableDiv__32WEq {
    background-color: white;
    margin-top: 2%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.PropertyVersion_searchBar__2zplp {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.PropertyVersion_searchText__2jsmC {
    display: flex;
    justify-content: center;
    align-items: center;
}

.PropertyVersion_dropDownDiv__2KqG0 {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.PropertyVersion_searchBarDiv__2uBxd {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.PropertyVersion_searchAndDrop__KCixC {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.PropertyVersion_buttonContainer__2c9d8 {
    width: 26%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .PropertyVersion_searchAndDrop__KCixC {
        width: 40%;
    }
    .PropertyVersion_dropDownDiv__2KqG0 {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .PropertyVersion_searchAndDrop__KCixC {
        width: 50%;
    }
    .PropertyVersion_dropDownDiv__2KqG0 {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .PropertyVersion_searchAndDrop__KCixC {
        width: 70%;
    }
    .PropertyVersion_dropDownDiv__2KqG0 {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .PropertyVersion_searchAndDrop__KCixC {
        width: 70%;
    }
    .PropertyVersion_dropDownDiv__2KqG0 {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .PropertyVersion_searchAndDrop__KCixC {
        width: 70%;
    }
    .PropertyVersion_dropDownDiv__2KqG0 {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .PropertyVersion_searchAndDrop__KCixC {
        width: 70%;
    }
    .PropertyVersion_dropDownDiv__2KqG0 {
        width: 80%;
    }
}
.PropertyVersion_main__1I-4b {
    background-color: #f7f7f5;
    width: 100%;
    height: 100%;
    padding: 2%;
}
.PropertyVersion_width__22DtJ{
    width: 0px;
}

.PropertyVersion_title__RbQyg {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425D;
}

.PropertyVersion_tableDiv__2bbQL {
    background-color: white;
    margin-top: 2%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.PropertyVersion_searchBar__1KTjQ {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.PropertyVersion_searchText__3Rnvb {
    display: flex;
    justify-content: center;
    align-items: center;
}

.PropertyVersion_dropDownDiv__3lL9A {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.PropertyVersion_searchBarDiv__2ND4s {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.PropertyVersion_searchAndDrop__2Zdf6 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.PropertyVersion_buttonContainer__1etkQ {
    width: 26%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .PropertyVersion_searchAndDrop__2Zdf6 {
        width: 40%;
    }
    .PropertyVersion_dropDownDiv__3lL9A {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .PropertyVersion_searchAndDrop__2Zdf6 {
        width: 50%;
    }
    .PropertyVersion_dropDownDiv__3lL9A {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .PropertyVersion_searchAndDrop__2Zdf6 {
        width: 70%;
    }
    .PropertyVersion_dropDownDiv__3lL9A {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .PropertyVersion_searchAndDrop__2Zdf6 {
        width: 70%;
    }
    .PropertyVersion_dropDownDiv__3lL9A {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .PropertyVersion_searchAndDrop__2Zdf6 {
        width: 70%;
    }
    .PropertyVersion_dropDownDiv__3lL9A {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .PropertyVersion_searchAndDrop__2Zdf6 {
        width: 70%;
    }
    .PropertyVersion_dropDownDiv__3lL9A {
        width: 80%;
    }
}
.PropertyVersion_main__2V0V7 {
    background-color: #f7f7f5;
    width: 100%;
    height: 100%;
    padding: 2%;
}
.PropertyVersion_width__25G7c{
    width: 0px;
}

.PropertyVersion_title__1R9yT {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425D;
}

.PropertyVersion_tableDiv__3w1LH {
    background-color: white;
    margin-top: 2%;
    padding: 0 1.5%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.PropertyVersion_searchBar__2auI7 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.PropertyVersion_searchText__2eaGl {
    display: flex;
    justify-content: center;
    align-items: center;
}

.PropertyVersion_dropDownDiv__ah7ez {
    width: 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
}

.PropertyVersion_searchBarDiv__14tSK {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.PropertyVersion_searchAndDrop__1pvk0 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
}

.PropertyVersion_buttonContainer__xt1G4 {
    width: 26%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (min-width: 1441px) and (max-width: 2560px) {
    .PropertyVersion_searchAndDrop__1pvk0 {
        width: 40%;
    }
    .PropertyVersion_dropDownDiv__ah7ez {
        width: 60%;
    }
}

@media (min-width: 1025px) and (max-width: 1440px) {
    .PropertyVersion_searchAndDrop__1pvk0 {
        width: 50%;
    }
    .PropertyVersion_dropDownDiv__ah7ez {
        width: 50%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .PropertyVersion_searchAndDrop__1pvk0 {
        width: 70%;
    }
    .PropertyVersion_dropDownDiv__ah7ez {
        width: 60%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .PropertyVersion_searchAndDrop__1pvk0 {
        width: 70%;
    }
    .PropertyVersion_dropDownDiv__ah7ez {
        width: 60%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .PropertyVersion_searchAndDrop__1pvk0 {
        width: 70%;
    }
    .PropertyVersion_dropDownDiv__ah7ez {
        width: 80%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .PropertyVersion_searchAndDrop__1pvk0 {
        width: 70%;
    }
    .PropertyVersion_dropDownDiv__ah7ez {
        width: 80%;
    }
}
.QcEndDayReporting_main__u0ul3 {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.QcEndDayReporting_title__3tQWt {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
}

.QcEndDayReporting_tableDiv__1xcrr {
  background-color: white;
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2%;
}

.QcEndDayReporting_searchBar__3ZyRs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.QcEndDayReporting_searchText__3ghog {
  display: flex;
  justify-content: center;
  align-items: center;
}

.QcEndDayReporting_dropDownDiv__QoqYJ {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.QcEndDayReporting_searchBarDiv__3bamU {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.QcEndDayReporting_searchAndDrop__17hvl {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.QcEndDayReporting_buttonAndFilter__1OUHH {
  display: flex;
  width: 68%;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: 2%;
}

.QcEndDayReporting_paginationDiv__2fv1C {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 550px) {
  .QcEndDayReporting_main__u0ul3 {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }

  .QcEndDayReporting_title__3tQWt {
    margin-left: 2%;
    margin-top: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: medium;
  }

  .QcEndDayReporting_tableDiv__1xcrr {
    background-color: white;
    margin-left: 0;
    margin-top: 2%;
    padding: 2%;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2%;
  }

  .QcEndDayReporting_searchBar__3ZyRs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .QcEndDayReporting_dropDownDiv__QoqYJ {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .QcEndDayReporting_searchBarDiv__3bamU {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .QcEndDayReporting_searchAndDrop__17hvl {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .QcEndDayReporting_buttonAndFilter__1OUHH {
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 0%;
  }

  .QcEndDayReporting_paginationDiv__2fv1C {
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.InitEndDayReporting_main__3TMZr {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.InitEndDayReporting_title__2R6nA {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
}

.InitEndDayReporting_tableDiv__kxQ8j {
  background-color: white;
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2%;
}

.InitEndDayReporting_searchBar__3NKzZ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.InitEndDayReporting_searchText__3Idxc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.InitEndDayReporting_dropDownDiv__3K-au {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.InitEndDayReporting_searchBarDiv__3sbBl {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.InitEndDayReporting_searchAndDrop__1viez {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.InitEndDayReporting_buttonAndFilter__1_6MV {
  display: flex;
  width: 68%;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: 2%;
}

.InitEndDayReporting_paginationDiv__3qFJL {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 550px) {
  .InitEndDayReporting_main__3TMZr {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }

  .InitEndDayReporting_title__2R6nA {
    margin-left: 2%;
    margin-top: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: medium;
  }

  .InitEndDayReporting_tableDiv__kxQ8j {
    background-color: white;
    margin-left: 0;
    margin-top: 2%;
    padding: 2%;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2%;
  }

  .InitEndDayReporting_searchBar__3NKzZ {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .InitEndDayReporting_dropDownDiv__3K-au {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .InitEndDayReporting_searchBarDiv__3sbBl {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .InitEndDayReporting_searchAndDrop__1viez {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .InitEndDayReporting_buttonAndFilter__1_6MV {
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 0%;
  }

  .InitEndDayReporting_paginationDiv__3qFJL {
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.PayHistory_main__2FymP {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
}
.PayHistory_width__eKLUO {
  width: 0px;
}

.PayHistory_title__2SSQR {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #43425d;
  font-weight: normal;
}

input.PayHistory_searchbox__2JA1M {
  width: 100%;
  border-radius: 2px;
  border: 1px solid gray;
}

.PayHistory_tableDiv__QpGx0 {
  background-color: white;
  margin-top: 2%;
  padding: 2% 1.5% 5% 1.5%;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.PayHistory_searchBar__31ksH {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.PayHistory_searchText__2VnJl {
  display: flex;
  justify-content: center;
  align-items: center;
}

.PayHistory_dropDownDiv__1mz6Y {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.PayHistory_searchBarDiv__bRLeb {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.PayHistory_searchAndDrop__4fsUG {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.PayHistory_assetAddModal__Q2CLy {
  margin-top: 30vh !important;
}

@media screen and (max-width: 550px) {
  .PayHistory_main__2FymP {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }
  .PayHistory_tableDiv__QpGx0 {
    margin-left: 0%;
    margin-top: 1.5%;
    padding: 2%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .PayHistory_dropDownDiv__1mz6Y {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .PayHistory_searchBarDiv__bRLeb {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .PayHistory_searchAndDrop__4fsUG {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
}

.EndOfDay_main__3Ekra {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.EndOfDay_title__3tUyo {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font: Source Sans Pro;
  font-size: 1.3rem;
  font-weight: 500;
}

.EndOfDay_tableDiv__3DacK {
  background-color: white;
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  width: 100%;
  /* height: 70%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.EndOfDay_searchBar__13ZBF {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.EndOfDay_searchText__2fS9p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.EndOfDay_dropDownDiv__e2b1Z {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.EndOfDay_searchBarDiv__11geg {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.EndOfDay_table__3MgA0 {
  height: 80%;
}

.EndOfDay_searchAndDrop__1XHsw {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  width: 40%;
}

.EndOfDay_buttonAndFilter__vMMNF {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.EndOfDay_paginationDiv__3CMef {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 550px) {
  .EndOfDay_main__3Ekra {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }
  .EndOfDay_tableDiv__3DacK {
    margin-left: 0%;
    margin-top: 1.5%;
    padding: 0 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .EndOfDay_dropDownDiv__e2b1Z {
    margin-top: 2%;
    width: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .EndOfDay_searchBarDiv__11geg {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .EndOfDay_searchBar__13ZBF {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .EndOfDay_searchAndDrop__1XHsw {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    margin-bottom: 2%;
  }

  .EndOfDay_buttonAndFilter__vMMNF {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.TaxHistory_main__1zIoI {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.TaxHistory_width__1qM7J {
  width: 0px;
}

input.TaxHistory_searchbox__12dZy {
  width: 100%;
  border-radius: 2px;
  border: 1px solid gray;
}

.TaxHistory_title__36TFD {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #43425d;
}

.TaxHistory_tableDiv__2KfHK {
  background-color: white;
  margin-top: 2%;
  padding: 2% 1.5% 5% 1.5%;
  height: 80%;
  width: 100%;
  /* right: 20%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.TaxHistory_searchBar__1ARiX {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.TaxHistory_searchText__21o4i {
  display: flex;
  justify-content: center;
  align-items: center;
}

.TaxHistory_dropDownDiv__nMzKO {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.TaxHistory_searchBarDiv__1ekJK {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
}

.TaxHistory_searchAndDrop__y75p7 {
  display: flex;
  flex-direction: row;
  width: 60%;
}

.TaxHistory_assetAddModal__zpu7w {
  margin-top: 30vh !important;
}

@media screen and (max-width: 550px) {
  .TaxHistory_main__1zIoI {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }
  .TaxHistory_tableDiv__2KfHK {
    margin-left: 0%;
    margin-top: 1.5%;
    padding: 0 0;
    width: 95vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .TaxHistory_searchBar__1ARiX {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .TaxHistory_dropDownDiv__nMzKO {
    width: 25%;
    display: flex;
    margin-left: 20px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
  }
}

.TaxCalculator_main__3thxU {
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.TaxCalculator_title__1aTd7 {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425d;
}


.success_main__1MhTk {
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.success_title__1PSYu {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425d;
}

.success_success__3u-hJ{
    margin: 2rem;
}
.privacyPolicy_main__2ptGZ {
    background-color: #F4FFE4;
    width: 100%;
    height: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-bottom: 2rem;
}

.privacyPolicy_title__WY7l6 {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2rem;
    font-weight: 500;
    font-weight: 600;
    color: #43425d;
}

.privacyPolicy_privacy__3CVOj{
    margin-top: 2rem;
}

.privacyPolicy_policycard__3ewfI{
    margin: 5px;
    background-color: #ffffff;
    border: 2px solid black;
    border-radius: 7px;
    padding: 1rem 2rem;
    box-shadow: 0 6px 6px 3px black;
}

.links:hover {
    color: rgb(5, 5, 5);
  }
.links{
    font-size: large;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .brandLogo{
    height:100%;
    width:100%;
  }  
}


/* SideBar CSS */

/* The sidebar menu */
.sidebar {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0;
  right: 0;
  background-color: #eee; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
}

/* The sidebar links */
.links, .closebtn
{ padding: 8px 8px 8px 32px;
text-decoration: none;
font-size: 25px;
color: #818181;
display: block;
transition: 0.3s;
}

.closebtn{
  cursor: pointer;
}


/* When you mouse over the navigation links, change their color */
.links:hover {
  color: #111;
}


/* Position and style the close button (top right corner) */
.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

/* The button used to open the sidebar */
.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #f50056a6;
  color: white;
  padding: 10px 15px;
  border: none;
}

.openbtn:hover {
  background-color: #444;
}

.samadhanNav{
  box-shadow: 0 0px 6px 2px rgba(0, 0, 0, 0.466);
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s; /* If you want a transition effect */
  padding: 20px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidebar {padding-top: 15px;}
  .sidebar a {font-size: 18px;}
}
.footer {
    display: flex;
    flex-direction: row;
    padding: 5px 30px;
    color: #2f2f2f;
    background-color: #eee;
    border-top: 1px solid #e5e5e5;
  }
  
  .footer > * {
    flex:  1 1 100%;
  }
  
  .footer__addr {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 1.25em;
    margin-bottom: 2em;
  }
  
  
  .footer__addr h2 {
    margin-top: 1.3em;
    font-size: 15px;
    font-weight: 400;
  }
  
  .nav__title {
    font-weight: 400;
    font-size: 15px;
  }
  
  .footer address {
    display: flex;
    flex-direction: column;
    font-style: normal;
    color: #999;
    text-align: right;
    align-items: flex-end;
  }
  
  .footer__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    max-width: -webkit-max-content;
    max-width: max-content;
    background-color: rgb(33, 33, 33, 0.07);
    border-radius: 100px;
    color: #2f2f2f;
    line-height: 0;
    margin: 0.6em 0;
    font-size: 1rem;
    padding: 0 1.3em;
  }
  
  .footer ul {
    list-style: none;
    padding-left: 0;
  }
  
  .footer li {
    line-height: 2em;
  }
  
  .footer a {
    text-decoration: none;
  }
  
  .footer__nav {
    display: flex;
      flex-flow: row wrap;
  }
  
  .footer__nav > * {
    flex: 1 1 50%;
    margin-right: 1.25em;
  }
  
  .nav__ul a {
    color: #999;
  }
  
  .nav__ul--extra {
    -webkit-column-count: 2;
            column-count: 2;
    grid-column-gap: 1.25em;
    -webkit-column-gap: 1.25em;
            column-gap: 1.25em;
  }
  
  .legal {
    display: flex;
    flex-wrap: wrap;
    color: #999;
  }
    
  .legal__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .heart {
    color: #2f2f2f;
  }

  
  
  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .content-left{
        display: none;
    }
  }
  @media screen and (min-width: 24.375em) {
    .legal .legal__links {
      margin-left: auto;
    }
  }
  
  @media screen and (min-width: 40.375em) {
    .footer__nav > * {
      flex: 1 1;
    }
    
    .nav__item--extra {
      flex-grow: 2;
    }
    
    .footer__addr {
      flex: 1 1;
    }
    
    .footer__nav {
      flex: 2 1;
    }
  }
.makePayments_main__6ARTW {
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.makePayments_title__2rFQO {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425d;
}


.moreDetails_main__n_cRM {
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.moreDetails_title__-aKDp {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425d;
}


.moreDetails_main__1Zya9 {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.moreDetails_title__3ehoJ {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #43425d;
}

