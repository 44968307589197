.main {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.title {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
}

.tableDiv {
  background-color: white;
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.searchBar {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.dropDownDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  margin-left: 5%;
}

.searchBarDiv {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.searchAndDrop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.buttonAndFilter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.paginationDiv {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.buttonDiv {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 550px) {
  .tableDiv {
    margin-left: 0%;
    margin-top: 1.5%;
    padding: 0 0;
    width: 95vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .searchBar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .dropDownDiv {
    width: 25%;
    display: flex;
    margin-left: 20px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
  }
  .buttonAndFilter {
    display: flex;
    margin-right: 20px;
    width: 50%;
    flex-direction: row;
    justify-content: space-between;
  }
}
