.main {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.width {
  width: 0px;
}

input.searchbox {
  width: 100%;
  border-radius: 2px;
  border: 1px solid gray;
}

.title {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #43425d;
}

.tableDiv {
  background-color: white;
  margin-top: 2%;
  padding: 2% 1.5% 5% 1.5%;
  height: 80%;
  width: 100%;
  /* right: 20%; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.searchBar {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.searchText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropDownDiv {
  width: 25%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.searchBarDiv {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
}

.searchAndDrop {
  display: flex;
  flex-direction: row;
  width: 60%;
}

.assetAddModal {
  margin-top: 30vh !important;
}

@media screen and (max-width: 550px) {
  .main {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }
  .tableDiv {
    margin-left: 0%;
    margin-top: 1.5%;
    padding: 0 0;
    width: 95vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .searchBar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .dropDownDiv {
    width: 25%;
    display: flex;
    margin-left: 20px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
  }
}
