.main {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
}
.title {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
}
.tableDiv {
  background-color: white;
  margin-top: 5%;
  padding: 0 1.5%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.searchBar {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.searchText {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropDownDiv {
  width: 63%;
  display: flex;
  flex-direction: row;
}
.searchBarDiv {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
