.main {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.title {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  color: #43425d;
}

.tableDiv {
  background-color: white;
  margin-top: 2%;
  padding: 0 1.5%;
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.searchBar {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.searchText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropDownDiv {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.searchBarDiv {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.searchAndDrop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 35%;
}

.buttonContainer {
  width: 20%;
  display: flex;
  flex-direction: row;
  /* align-items: flex-end; */
  justify-content: space-between;
}

@media screen and (max-width: 550px) {
  .main {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }
  .tableDiv {
    margin-left: 0%;
    margin-top: 1.5%;
    padding: 0 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .dropDownDiv {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
  }

  .searchBarDiv {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .searchAndDrop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2%;
  }

  .buttonContainer {
    width: 60%;
    display: flex;
    flex-direction: row;
    /* align-items: flex-end; */
    justify-content: space-between;
  }
}
