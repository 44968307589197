.main {
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2%;
}

.title {
    margin-left: 6%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425D;
}

.grid {
    margin-left: 5%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1vw;
    grid-row-gap: 0vw;
    margin-right: 5%;
}

.titleAndSearch {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    padding-right: 5%;
}

.searchAndButton {
    display: flex;
    width: 50%;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
}

.searchBar {
    background-color: white;
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.noRecord{
    height: 400px;
    width: 100%;
    /* background: green; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.noRecord p{
    text-align: center;
    /* width: 150px; */
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* background: red; */
    font-size: xx-large;
    color: #43425d;
}

.paginationDiv {
    margin-top: 2%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* margin-right: 6%; */
}
.card{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.detailsDiv{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.switchDiv{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
@media (min-width: 1025px) and (max-width: 1440px) {
    .searchAndDrop {
        width: 50%;
    }
    .dropDownDiv {
        width: 50%;
    }
    .paginationDiv {
        margin-top: 2%;
        display: inline-block;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .grid {
        margin-right: 2%;
    }
}
.totalCount{
    margin: 6%;
    margin-top: 25px;
    /* width: 50%; */
    float: left;
    text-align: center;
    /* background: red; */
    text-align: right;
    font-weight: 500;
    text-transform: capitalize;
}

@media (min-width: 769px) and (max-width: 1024px) {
    .searchAndDrop {
        width: 70%;
    }
    .dropDownDiv {
        width: 60%;
    }
    .paginationDiv {
        margin-top: 2%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .searchAndButton {
        width: 70%;
    }
    .grid {
        margin-right: 5%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .searchAndDrop {
        width: 70%;
    }
    .dropDownDiv {
        width: 60%;
    }
    .paginationDiv {
        margin-top: 2%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .searchAndButton {
        width: 70%;
    }
    .grid {
        margin-right: 5%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .searchAndDrop {
        width: 70%;
    }
    .dropDownDiv {
        width: 80%;
    }
    .paginationDiv {
        margin-top: 2%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .searchAndButton {
        width: 70%;
    }
    .grid {
        margin-right: 5%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .searchAndDrop {
        width: 70%;
    }
    .dropDownDiv {
        width: 80%;
    }
    .paginationDiv {
        margin-top: 2%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        display:"inline-block"
    }
    .totalCount{
        margin: 6%;
        margin-top: 25px;
        /* width: 50%; */
        float: left;
        text-align: center;
        /* background: red; */
        text-align: right;
    }
    .searchAndButton {
        width: 70%;
    }
    .grid {
        margin-right: 5%;
    }
}

.modalContainer {
    max-height: 20vw;
}