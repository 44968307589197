.background {
    background: linear-gradient(rgba(242, 19, 79, 0.17), rgba(242, 19, 79, 0.17)), url(./images/unnamed@2x.png);
    position: relative;
}

.div1 {
    position: fixed;
    top: 15%;
    left: 30%;
    width: 35%;
    height: 70%;
    background-color: white;
    padding: 2%;
}

.logoDiv {
    display: flex;
    padding: 5%;
    justify-content: center;
}

.div1text {
    text-align: center;
    padding: 2%;
    font-size: x-large;
}

.description {
    font-size: smaller;
    text-align: center;
    padding-bottom: 5%;
    color: #64686D;
}

.textField {
    display: flex;
    justify-content: center;
    align-content: center;
}

.cancelIcon {
    position: absolute;
    top: -25px;
    left: 96%;
}

