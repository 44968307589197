.main {
  width: 100%;
  height: 100%;
  background-color: #f7f7f5;
  padding: 2%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.title {
  font-size: xx-large;
}
.formDiv {
  margin: 2% 0;
  padding: 1%;
  background-color: white;
  width: 60%;
}
.row1 {
  display: flex;
  margin: 2% 0;
  flex-direction: row;
  justify-content: space-around;
}
.row2 {
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 5%;
  margin: 2% 0;
}
.buttonDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.buttons {
  margin: 2%;
}

@media screen and (max-width: 550px) {
  .main {
    width: 100vw;
    height: 100%;
    background-color: #f7f7f5;
    padding: 2%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .title {
    font-size: medium;
  }
  .formDiv {
    margin: 2% 0;
    padding: 1%;
    background-color: white;
    width: 100%;
  }
  .row1 {
    display: flex;
    margin: 2% 0;
    flex-direction: row;
    justify-content: space-around;
  }
  .row2 {
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 5%;
    margin: 2% 0;
  }
  .buttonDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .buttons {
    margin: 2%;
  }
}
