.main {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 1%;
}

.grid {
  margin-top: 2%;
  width: 95%;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}
