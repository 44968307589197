.main {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 1%;
}
.title {
  margin-top: 3%;
  margin-left: 5%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  font: Regular Source Sans Pro;
  color: #43425d;
}

.grid {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  margin-left: 3%;
}

@media screen and (max-width: 550px) {
  .main {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 0;
  }
  .title {
    margin-top: 1%;
    margin-left: 1%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    font: Regular Source Sans Pro;
    color: #43425d;
  }

  .grid {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    margin-left: 0;
  }
}
