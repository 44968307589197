.main {
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
    padding: 2%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title {
    margin-left: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 500;
    color: #43425d;
}

