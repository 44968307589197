.main {
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
  padding: 2%;
  display: flex;
  margin-left: 3%;
  flex-direction: column;
  justify-content: flex-start;
}

.title {
  font-size: 1.3rem;
  font-weight: 500;
}

.formDiv {
  margin: 2% 0;
  padding: 1%;
  background-color: white;
  width: 60%;
  box-shadow: 0px 3px 6px #00000014;
}

.row1 {
  display: flex;
  margin: 2% 0;
  flex-direction: row;
  justify-content: space-around;
}

.row2 {
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 5%;
  margin: 2% 0;
}

.buttonDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 3%;
}

.buttons {
  margin: 2%;
}

@media screen and (max-width: 550px) {
  .main {
    width: 100vw;
    height: 100%;
    background-color: #f9f9f9;
    padding: 2%;
    display: flex;
    margin-left: 3%;
    flex-direction: column;
    justify-content: flex-start;
  }

  .title {
    font-size: medium;
  }

  .formDiv {
    margin: 2% 0;
    padding: 1%;
    background-color: white;
    width: 100%;
    box-shadow: 0px 3px 6px #00000014;
  }
}
