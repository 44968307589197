.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: baseline;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.dropdownMain {
  display: flex;
  margin-right: 2%;
  margin-top: 2%;
  padding-bottom: 2%;
  align-items: flex-end;
  justify-content: flex-end;
}

.title {
  margin-left: 2%;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
  margin-top: 2%;
}

.header2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 5%;
  width: 98%;
  margin-left: 3%;
  justify-content: first baseline;
}

.subTitle {
  font-size: 1.25rem;
  color: #43425d;
  font-weight: bold;
  border-bottom: 0.1rem solid #43425d;
  padding-bottom: 1%;
  margin-right: 2%;
  white-space: nowrap;
}

.dropdown2 {
  margin-left: 32%;
}

.date {
  margin-left: 2%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

.label {
  color: #43425d;
  font-size: medium;
  text-align: center;
  white-space: nowrap;
}

.date_picker {
  border-color: #f5f6fa;
  border-radius: 4px;
  width: 31%;
  margin-left: 2%;
  margin-right: 2%;
}

.cards {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  flex: 1;
  margin-top: 5%;
  margin-left: 2%;
}

.card {
  max-width: 45%;
  min-width: 25%;
  /* margin-top: 3%; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.final {
  margin-left: 85%;
  display: flex;
  flex-direction: row;
}

/* @media (min-width: 1025px) and (max-width: 1440px) {
    .cards {
        width: 45%;
    }
    .dropdown2 {
        margin-left: 33%;
    }
}

@media (min-width: 769px) and (max-width: 1024px) {
    .cards {
        width: 100%;
    }
    .dropdown2 {
        margin-left: 10%;
    }
}

@media (min-width: 426px) and (max-width: 768px) {
    .cards {
        width: 100%;
    }
    .dropdown2 {
        margin-left: 10%;
    }
}

@media (min-width: 376px) and (max-width: 425px) {
    .cards {
        width: 100%;
    }
    .dropdown2 {
        margin-left: 10%;
    }
}

@media (min-width: 320px) and (max-width: 375px) {
    .cards {
        width: 100%;
    }
    .dropdown2 {
        margin-left: 5%;
    }
} */

@media screen and (max-width: 550px) {
  .main {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: baseline;
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .dropdownMain {
    display: flex;
    margin-right: 2%;
    margin-top: 2%;
    padding-bottom: 2%;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .header2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5%;
    margin: 2%;
    width: 100%;
    justify-content: space-evenly;
    justify-items: flex-start;
  }
  .dropdown2 {
    margin-left: 1%;
    margin: 4%;
  }
  .date {
    margin-left: 2%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
  }

  .label {
    color: #43425d;
    font-size: smaller;
    text-align: center;
    white-space: nowrap;
  }

  .date_picker {
    border-color: #f5f6fa;
    border-radius: 4px;
    width: 26%;
    margin-left: 0;
    margin-right: 0;
  }

  .cards {
    width: 100%;
    margin-top: 5%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
    margin-left: 0;
  }

  .card {
    max-width: 45%;
    min-width: 25%;
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .final {
    margin-left: 50%;
    margin-top: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
  }
}
