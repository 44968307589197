.left {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button {
  width: 320px;
  height: 50px;
}

.forgotPassword {
  color: #f2134f;
}

.logo {
  display: flex;
  padding: 5%;
  align-items: center;
}

.login_text {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 5%;
  font-size: xx-large;
}

.login_desc {
  text-align: center;
  padding-bottom: 5%;
  font-size: medium;
  color: #64686d;
}

.parent {
  width: 100vw;
  height: 100vh;
}

.image1 {
  position: relative;
  padding-left: 5%;
  top: 0;
  left: 0;
  height: 100%;
  width: 102.5%;
  margin: 0;
  object-fit: fill;
  overflow: hidden;
}

.image2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: fill;
  overflow-x: hidden;
}

.links {
  position: absolute;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  padding-left: 1em;
  padding-right: 1em;
}

.innerLinks {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.textDanger {
  color: red;
  float: left;
  width: 91%;
}
.showBlock {
  display: block;
}
.hideBlock {
  display: none;
}
@media only screen and (min-width: 2560px) {
  .image1 {
    width: 102.5%;
    height: 101%;
  }
  .image2 {
    width: 101%;
    height: 101%;
  }
  .links {
    position: absolute;
    height: 100vh;
    width: 12%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    top: 0;
    left: 0;
    padding-left: 3em;
    padding-right: 3em;
  }
  .innerLinks {
    height: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 320px) {
  .left_main {
    padding-left: 80%;
  }
  .button {
    width: 240px;
    height: 50px;
  }
  .parent {
    visibility: hidden;
  }
  .login_text {
    width: 320px;
    font-size: medium;
  }
  .signUpText {
    font-size: small;
  }
  .forgotPassword {
    font-size: small;
    font-weight: 500;
  }
  .login_desc {
    font-size: small;
  }
  .usernamePassowrdLabel {
    font-size: smaller;
  }
  .checkBoxLabel {
    font-size: smaller;
    font-weight: 500;
  }
  .checkBox {
    display: flex;
  }
  .forgotPwdDiv {
    display: flex;
    width: 100%;
    padding: 0 5%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .button {
    width: 320px;
    height: 50px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  .button {
    width: 288px;
    height: 50px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 767px) {
  .left_main {
    padding-left: 75%;
  }
  .parent {
    visibility: hidden;
  }
  .forgotPassword {
    font-size: small;
    font-weight: 500;
  }
  .checkBoxLabel {
    font-size: smaller;
    font-weight: 500;
  }
  .forgotPwdDiv {
    display: flex;
    width: 100%;
    padding: 0 5%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .login_text {
    width: 320px;
    font-size: large;
  }
  .signUpText {
    font-size: small;
  }
  .forgotPassword {
    font-size: medium;
  }
  .login_desc {
    font-size: small;
  }
  .usernamePassowrdLabel {
    font-size: smaller;
  }
  .checkBoxLabel {
    font-size: small;
  }
  .forgotPwdDiv {
    display: flex;
    width: 100%;
    padding: 0 5%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .checkBox {
    display: flex;
  }
}
avatarName {
  color: #646777 !important;
  margin-left: 10px;
  display: block;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  margin-top: auto;
  margin-bottom: auto;
}
.avatar svg {
  margin: auto;
  height: 18px;
  width: 18px;
  fill: #b1c3c8;
}
.topBarBack {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;
}
.topBarMenuWrap {
  right: 0px;
  z-index: 101;
  position: absolute;
  width: 100%;
  /* min-width: 210px; */
}
.topBarMenu {
  left: 0px !important;
  background: white;
  width: 100%;
  border-radius: 0;
  border: none;
  padding: 15px 0;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  margin-top: 0;
}
.hr {
  margin: auto;
  margin-left: 6px;
  border-right: 2px solid rgb(239 239 239);
  display: block;
  /* width: 100%; */
  height: 20px;
  margin-right: -2px;
}
.tobBarLinik {
  color: #707070;
  display: flex;
  padding: 9px 4px;
  transition: all 0.3s;
  height: 32px;
  width: 100%;
  position: relative;
  cursor: pointer;
  /* text-decoration: none; */
}
.tobBarLinik:hover {
  background-color: #fafbfe;
  color: #707070;
}
.tobBarLinik:before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  background: #4ce1b6;
  opacity: 0;
  transition: all 0.3s;
}
.noHover:before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 0px !important;
  background: #fafbfe !important;
  opacity: 0;
  transition: all 0.3s;
}
button:focus {
  outline: none !important;
}
.tobBarLinik:hover:before {
  opacity: 1;
}
.tobBarLinkTitle {
  color: #646777;
  margin: 0;
  font-size: 14px;
  line-height: 16px;
}
.tobBarLinkIcon {
  color: #dddddd;
  margin-right: 10px;
  font-size: 13px;
  line-height: 13px;
}
.topBarCollapse {
  position: relative;
}
.topBarCollapseLng {
  min-width: 70px;
  display: block;
}
.topBarCollapseLng {
  color: #646777;
}
.tobBarButton {
  font-size: 18px;
  height: 100%;
  padding: 0 10px;
  cursor: pointer;
  position: relative;
  display: flex;
  border: none;
  background: transparent;
  transition: all 0.3s;
  padding: 0 4px;
  width: 100%;
}
.tobBarButton {
  padding: 0 4px;
  width: 100%;
}
.topBarLngBtnTitle:not(:last-child) {
  margin-right: 5px;
}
.topBarLngBtnTitle:not(:last-child) {
  margin-right: 5px;
  color: #646777;
  display: flex;
  font-size: 11px;
  align-items: center;
  margin: auto 0;
}
.topBarLngBtnTitle img {
  margin-right: 4px;
  height: 11px;
  width: 16px;
}
.tobBarButton svg {
  margin: auto;
  height: 18px;
  width: 18px;
  fill: #b1c3c8;
  margin-left: 8px;
}

.tobBarContentCollapse {
  background: white;
  right: 0px;
}
.tobBarContentCollapse {
  /* width: 270px; */
  position: absolute;
  bottom: 20px;
  transform: translateY(100%);
  box-shadow: 0 10px 25px 0 rgba(33, 36, 50, 0.13);
  z-index: 101;
}
.tobBarClsContentLgn {
  width: 72px;
  padding: 10px 0;
  bottom: 0;
  /* width: 100%; */
  transform: translateY(100%);
}
.topBarLngBtn {
  text-align: left;
  padding: 4px 15px;
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  transition: 0.3s;
  font-family: sans-serif;
  font-size: 100%;
}

@media only screen and (min-width: 768px) {
  .image1 {
    width: 105.5%;
    height: 100vh;
  }
  .image2 {
    height: 100vh;
  }
  .forgotPwdDiv {
    display: flex;
    width: 100%;
    padding: 0 5%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (min-width: 1024px) {
  .image1 {
    width: 104%;
    height: 100vh;
  }
  .image2 {
    height: 100vh;
  }
  .forgotPwdDiv {
    display: flex;
    width: 100%;
    padding: 0 5%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (min-width: 1440px) {
  .image1 {
    width: 103%;
    height: 100vh;
  }
  .image2 {
    height: 100vh;
  }
  .forgotPwdDiv {
    display: flex;
    width: 100%;
    padding: 0 5%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .linksDiv1 {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
  }
  .linksDiv2 {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
  }
  .instaImage {
    width: 60px;
    height: 60px;
  }
  .links {
    padding-left: 0.6em;
  }
  .innerLinks {
    padding-left: 0em;
    justify-content: space-evenly;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .linksDiv1 {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
  }
  .linksDiv2 {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
  }
  .instaImage {
    width: 66px;
    height: 66px;
  }
  .links {
    padding-left: 0.3em;
  }
  .innerLinks {
    padding-left: 0.5em;
    justify-content: space-evenly;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 2559px) {
  .linksDiv1 {
    width: 58px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
  }
  .linksDiv2 {
    width: 58px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: white;
    padding-top: 5%;
  }
  .instaImage {
    width: 72px;
    height: 72px;
  }
  .links {
    padding-left: 0.6em;
  }
  .innerLinks {
    padding-left: 0.5em;
    justify-content: space-evenly;
  }
}
