.main {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.title {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
}

.tableDiv {
  background-color: white;
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2%;
}

.searchBar {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.searchText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropDownDiv {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
}

.searchBarDiv {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.searchAndDrop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.buttonAndFilter {
  display: flex;
  width: 60%;
  flex-direction: row;
  justify-content: space-evenly;
}

@media screen and (max-width: 550px) {
  .main {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }

  .title {
    margin-left: 2%;
    margin-top: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: medium;
  }

  .tableDiv {
    background-color: white;
    margin-left: 0;
    margin-top: 2%;
    padding: 2%;
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2%;
  }

  .searchBar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .dropDownDiv {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .searchBarDiv {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .searchAndDrop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .buttonAndFilter {
    margin-top: 2%;
    margin-bottom: 2%;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 0%;
  }

  .paginationDiv {
    margin-top: 2%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
