.main {
  background-color: #f7f7f5;
  width: 100%;
  height: 100%;
  margin: 2%;
  padding: 2%;
}

.title {
  margin-left: 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
}

.tableDiv {
  background-color: white;
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 2%;
}

.searchBar {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-bottom: 2%;
  margin-top: 4%;
}

.searchText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropDownDiv {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.searchBarDiv {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.searchAndDrop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.buttonAndFilter {
  display: flex;
  width: 60%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.paginationDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media screen and (max-width: 550px) {
  .main {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    margin: 1%;
    padding: 0%;
  }

  .title {
    margin-left: 1%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: small;
  }

  .tableDiv {
    background-color: white;
    margin-left: 0;
    margin-top: 2%;
    padding: 1%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 2%;
  }

  .searchBar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 2%;
    margin-top: 4%;
  }

  .searchText {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dropDownDiv {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 3%;
  }

  .searchBarDiv {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .searchAndDrop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .buttonAndFilter {
    display: flex;
    width: 100%;
    margin-bottom: 3%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .paginationDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5%;
  }
}
