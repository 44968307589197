.main {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  /* padding: 2%; */
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #43425d;
  font-size: 1.3rem;
  font-weight: 500;
  margin-left: 2%;
}

.tableDiv {
  background-color: white;
  margin-top: 2%;
  /* padding: 0 1.5%;
    margin-left: 2%; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.searchBar {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.search_btn {
  background-color: #43425d;
  color: white;
  border-radius: 17px;
  text-transform: none;
  width: 109px;
  outline: none;
  margin-left: 5%;
}

.download_btn {
  text-transform: none;
  background-color: #3b86ff;
  text-align: center;
  white-space: nowrap;
  outline: none;
  margin-left: 2%;
  font-size: smaller;
}

.dropDownDiv {
  width: 25%;
  display: flex;
  flex-direction: row;
  margin-left: -7%;
  align-items: flex-end;
}
.M_L {
  margin-left: -1%;
}

.searchBarDiv {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.searchBarDiv1 {
  padding: 1% 0;
  /* display: flex; */
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.searchAndDrop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.buttonAndFilter {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.paginationDiv {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.seaarchSelect {
  color: #616161;
  font-size: 13px;
  min-width: 8.5vw;
  background: white;
  padding-top: 0px;
  border-color: #d7dae2;
  border-style: solid;
  border-width: 2px;
  padding-left: 0px;
  border-radius: 4px;
  padding-bottom: 0px;
}
.seaarchSelect svg {
  color: #9e9e9e;
}
.seaarchSelect div {
  color: black;
  background: white;
  border: none !important;
  min-height: 16px;
}
.seaarchSelect span {
  background-color: white;
}

@media screen and (max-width: 550px) {
  .tableDiv {
    background-color: white;
    margin-top: 2%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .search_btn {
    background-color: #43425d;
    color: white;
    border-radius: 17px;
    text-transform: none;
    width: 109px;
    outline: none;
    margin-left: 5%;
  }

  .download_btn {
    text-transform: none;
    background-color: #3b86ff;
    text-align: center;
    white-space: nowrap;
    outline: none;
    margin-left: 2%;
    font-size: smaller;
  }

  .dropDownDiv {
    width: 50%;
    display: flex;
    flex-direction: row;
    margin-left: 0;
    align-items: flex-start;
  }
  .M_L {
    margin-left: -1%;
  }

  .searchBarDiv {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .searchBarDiv1 {
    padding: 1% 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .searchAndDrop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2%;
  }

  .buttonAndFilter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .paginationDiv {
    margin-top: 2%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .seaarchSelect {
    color: #616161;
    font-size: 13px;
    min-width: 40vw;
    background: white;
    padding-top: 0px;
    border-color: #d7dae2;
    border-style: solid;
    border-width: 2px;
    padding-left: 0px;
    border-radius: 4px;
    padding-bottom: 0px;
  }
}
