.main {
  background-color: #f9f9f9;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.title {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  font-size: 1.3rem;
  font-weight: 500;
  margin-top: 1%;
}

.tableDiv {
  background-color: white;
  margin-top: 1.5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.searchBarDiv {
  padding: 1% 0;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.download1 {
  text-transform: none !important;
  background-color: rgb(59, 134, 255) !important;
  text-align: center !important;
  white-space: nowrap !important;
  outline: none !important;
  margin-left: 75% !important;
  font-size: smaller !important;
}

.download {
  text-transform: none !important;
  background-color: rgb(59, 134, 255) !important;
  text-align: center !important;
  white-space: nowrap !important;
  outline: none !important;
  margin-left: 4% !important;
  font-size: smaller !important;
}

.searchText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchAndDrop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
}

.buttonDiv {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: space-evenly;
}

.paginationDiv {
  margin-top: 2%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 550px) {
  .main {
    background-color: #f7f7f5;
    width: 100vw;
    height: 100%;
    padding: 1%;
  }
  .tableDiv {
    margin-left: 0%;
    margin-top: 1.5%;
    padding: 2%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .searchBar {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .dropDownDiv {
    width: 25%;
    display: flex;
    margin-left: 20px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-end;
  }
  .buttonAndFilter {
    display: flex;
    margin-right: 20px;
    width: 50%;
    flex-direction: row;
    justify-content: space-between;
  }
}
