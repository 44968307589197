.main {
  width: 100%;
  height: 100%;
  /* display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: baseline; */
  margin: 2%;
  overflow: hidden;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.dropdownMain {
  display: flex;
  margin-right: 2%;
  margin-top: 2%;
  padding-bottom: 2%;
  align-items: flex-end;
  justify-content: flex-end;
  /* width: 20% */
}

.title {
  margin-left: 3%;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: x-large;
  margin-top: 2%;
}

.header2 {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 5%;
  width: 98%;
  margin-left: 3%;
  justify-content: first baseline;
}

.subTitle {
  font-size: 1.25rem;
  color: #43425d;
  font-weight: bold;
  border-bottom: 0.1rem solid #43425d;
  padding-bottom: 1%;
  margin-right: 2%;
}

.dropdown2 {
  margin-left: 34%;
}

.date {
  margin-left: 2%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

.label {
  color: #43425d;
  font: SF Pro Text;
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
}

.date_picker {
  border-color: #f5f6fa;
  border-radius: 4px;
  width: 31%;
  margin-left: 2%;
  margin-right: 2%;
}

.cards {
  min-width: 21%;
  max-width: 21%;
  margin: 2%;
  min-height: 20%;
  max-height: 20%;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  box-shadow: 0px 2px 6px #0000000a;
  border-radius: 4px;
}

.header {
  font-size: x-large;
  font-weight: bold;
}

.detailsDiv {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  /* font-size: x-large; */
  font-weight: bold;
  color: #4d4f5c;
  margin-left: 2%;
  font: Semibold 22px/28px Source Sans Pro;
  margin-top: 0px;
}

.raised {
  border: 1px solid #77aaff;
  box-shadow: -1px 1px #77aaff, -2px 2px #77aaff, -3px 3px #77aaff,
    -4px 4px #77aaff, -5px 5px #77aaff;
}

.triangle {
  width: 0;
  height: 0;
  border-bottom: 100px solid red;
  border-left: 100px solid transparent;
}

.grid {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1;
}

.final {
  margin-left: 85%;
  display: flex;
  flex-direction: row;
}

.finale {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-left: 3%;
  margin-top: 7%;
  margin-bottom: 2%;
}
.mt_2 {
  margin-top: 2%;
}
.Dblock {
  display: none;
}

.finalDiv {
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 2%;
  padding: 0 1.5%;
}

.trackingDiv {
  margin-left: 2%;
  margin-top: 1.5%;
  padding: 0 1.5%;
  height: 10%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.initial_div {
  display: flex;
  flex-direction: row;
  padding: 2%;
}

.initial {
  border-radius: 5px;
  width: 1.5vw;
  height: 1vw;
  margin-right: 1%;
}

.graphdiv {
  margin-top: 10%;
  width: 100%;
}

/* .chart {
  width: 1150px;
  width: 300px;
} */

.square {
  width: 50px;
  height: 30px;
  background: #ffcd54;
}

.triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 0;
  border-color: #ffcd54 transparent transparent transparent;
  margin-left: 15px;
}

@media screen and (max-width: 550px) {
  .main {
    width: 100vw;
    height: 100%;
    /* display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; */
    margin: 1%;
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .dropdownMain {
    display: flex;
    margin-right: 3%;
    padding-bottom: 2%;
    align-items: flex-start;
    margin-top: 2%;
    justify-content: flex-end;
    width: 95%;
  }
  .header2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 3%;
    width: 100%;
    margin-left: 3%;
    justify-content: first baseline;
  }
  .dropdown2 {
    margin-left: 1%;
    margin-top: 5%;
  }

  .date {
    margin-left: 0;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-between;
  }

  .label {
    color: #43425d;
    font-size: smaller;
    text-align: center;
  }

  .date_picker {
    border-color: #f5f6fa;
    border-radius: 4px;
    width: 26%;
    margin-left: 0;
    margin-right: 0;
  }

  .cards {
    min-width: 40%;
    max-width: 40%;
    margin: 2%;
    min-height: 35%;
    max-height: 35%;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 4px;
  }

  .header {
    font-size: x-large;
    font-weight: bold;
  }

  .detailsDiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    /* font-size: x-large; */
    font-weight: bold;
    color: #4d4f5c;
    margin-left: 2%;
    font: Semibold 22px/28px Source Sans Pro;
    margin-top: 0px;
  }

  .final {
    margin-left: 50%;
    display: flex;
    flex-direction: row;
  }

  .label {
    color: #43425d;
    font: SF Pro Text;
    font-size: 10px;
    text-align: center;
    white-space: nowrap;
  }

  .finale {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    margin-left: 2%;
    margin-top: 7%;
    margin-bottom: 2%;
  }

  .finalDiv {
    background-color: white;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0;
    margin: 1%;
    padding: 0;
  }

  .trackingDiv {
    margin-left: 0;
    margin: 1%;
    width: 100vw;
    margin-top: 1.5%;
    padding: 0;
    height: 10%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-template-columns: 0;
  }

  .initial_div {
    display: flex;
    flex-direction: column;
    padding: 1%;
  }

  .initial {
    border-radius: 5px;
    width: 100%;
    height: 1vw;
    margin-right: 0;
  }

  .graphdiv {
    margin-top: 10%;
    width: 100%;
  }
}
