.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.title {
  font-weight: 100;
  color: #9695b5;
  /* opacity: 50%; */
}

.details {
  height: 0.5;
  display: flex;
  padding-top: 10%;
  flex-direction: row;
  justify-content: space-between;
}

.stats {
  font-size: x-large;
  font-weight: bolder;
  color: #4d4f5c;
  opacity: 1;
  font: Bold Source Sans Pro;
}

.image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}
